import TabUtils from '~/utils/TabUtils'

// Set this to true to enable useful debugging info logs
const DEBUG_ON = false

function logDebug() {
  // eslint-disable-next-line no-console
  if (DEBUG_ON) console.debug.call(this, ...arguments)
}

export default function ({ $auth, store, app }) {
  /**
   * This plugin helps us tackle things like auto-refreshing the user's token for them.
   * We have to do this because the auth package that we use triggers a call
   * to refresh the token twice on its own, causing the token to be blacklisted and the user to be logged out.
   * :(
   */

  const keepAliveInterval = 3000
  const storageKey = 'atlas_lead_tab'

  const isThisTabTheLead = () => {
    const leadIndicator = localStorage.getItem(storageKey)

    if (leadIndicator) {
      const data = JSON.parse(leadIndicator)

      if (data.value === TabUtils.myTabId) {
        return true
      }
    }

    return false
  }

  const setLeadIndicator = (refreshing) => {
    if (refreshing) {
      logDebug('👑 big lead here - still alive & reporting back as healthy')
    } else {
      logDebug("👑 I'm the lead tab!")
    }

    const data = {
      value: TabUtils.myTabId,
      expiration: new Date(),
    }

    localStorage.setItem(storageKey, JSON.stringify(data))
  }

  /**
   * When an admin starts impersonating a user, that tab should now become the lead.
   * This avoids issues with admin tokens getting used in the wrong context, etc.
   */
  store.watch(
    () => store.getters['authHelpers/impersonating'],
    (isNowImpersonating) => {
      if (isNowImpersonating) {
        // This should be the lead tab now.
        setLeadIndicator()
      }
    }
  )

  /**
   * Using local storage, we want to keep track of which tab is the lead.
   * As long as you're not impersonating (see above), this operates as follows:
   *
   * - The first tab to run this function becomes the lead:
   *   it sets a localstorage value with its unqiue Tab ID, and an expiration a couple seconds in the future
   * - Every interval, the lead tab reports back with an updated timestamp
   * - If it does not, the next tab to hit this function takes over.
   *
   * The idea is that we always want to have 1 and only 1 lead tab. This tab will run important functions like refreshing the auth token.
   * If multiple tabs were to attempt a refresh there is a race condition that results in a user storing a used token for all tabs.
   *
   * This is because the auth plugin has to store 1 token for all the tabs (localstorage is shared for a single domain)
   */
  const manageLeadTab = () => {
    const leadIndicator = localStorage.getItem(storageKey)

    if (leadIndicator) {
      const data = JSON.parse(leadIndicator)

      /**
       * This is the lead tab, we want to refresh the expiration time
       */
      if (data.value === TabUtils.myTabId) {
        setLeadIndicator(true)
        return
      }

      const expirationDate = new Date(data.expiration)
      const now = new Date()

      /**
       * The lead tab has not reported back for 2 cycles.
       * Something bad happened and we need a new lead tab.
       */
      if (now - expirationDate > keepAliveInterval * 2) {
        logDebug('🦴 the lead is dead. taking over!')
        // Become the ~lead~
        setLeadIndicator()
        return
      }

      /**
       * The current tab doesn't match the lead tab. Log and move on
       */
      if (data.value !== TabUtils.myTabId) {
        logDebug('😓 not the lead. just a plebe. for now...')
        return
      }
    }

    // Become the ~lead~ - nobody is yet!
    setLeadIndicator()
  }

  // This process will manage keeping track of which tab is the lead tab
  setInterval(manageLeadTab, keepAliveInterval + 1000)

  const refreshFunction = async () => {
    if ($auth.loggedIn) {
      if (DEBUG_ON) {
        const token = $auth.strategy.token.get()
        const tokenShort = token.substring(token.length - 4, token.length)
        logDebug('-------')
        logDebug('My Token')
        logDebug('token', tokenShort)

        logDebug('-------')
        logDebug('STATUS')
        logDebug(
          'refresh token expiration',
          new Date(
            $auth.strategy.token.$storage._state[
              '_refresh_token_expiration.local'
            ]
          )
        )
        logDebug(
          'token expiration',
          new Date(
            $auth.strategy.token.$storage._state['_token_expiration.local']
          )
        )
        logDebug('-------')
      }

      if (isThisTabTheLead()) {
        logDebug('-------')
        logDebug('🔄 REFRESHING TOKEN')
        logDebug('-------')
        try {
          await $auth.refreshTokens()
        } catch (e) {
          logDebug('-------')
          logDebug('STATUS')
          logDebug('💀 refreshTokens() failed:', e)
          logDebug('-------')

          // stop the timer
          clearInterval(refreshInterval)

          // report to bugsnag
          return app.$bugsnag.notify(`refreshTokens() failed: ${e}`)
        }
      }
    }
  }

  // This process will refresh the auth token
  const refreshInterval = setInterval(refreshFunction, 1000 * 60 * 5) // Refresh every 5 mins
}

import Vue from 'vue'

const state = () => ({
  affiliations: {
    items: [],
    pagination: [],
  },
  affiliation: null,
  tags: [],
  uploadStatus: '',
  affiliation_projects: {
    items: [],
    pagination: [],
  },
  team: [],
  roles: {
    items: [],
    pagination: [],
  },
})

const getters = {
  affiliations: ({ affiliations }) => affiliations,
  affiliation: ({ affiliation }) => affiliation,
  tags: ({ tags }) => tags,
  uploadStatus: ({ uploadStatus }) => uploadStatus,
  projects: ({ affiliation_projects }) => affiliation_projects,
  team: ({ team }) => team,
  roles: ({ roles }) => roles,
}

const mutations = {
  setPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination
    state[name].items = data.value[data.type][data.name]
  },
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
  setValue(state, data) {
    if (data.path) {
      let target = state[data.name]

      Vue.set(state[data.name], data.path[data.path.length - 1], data.value)

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadAffiliations({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.filters ? data.filters : {},
      path: '/groups/filter' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'affiliations',
            value: res,
            reset: data.reset ? true : false,
            type: 'affiliations_paginated',
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAffiliation({ commit }, data) {
    let requestData = {
      method: 'get',
      data: data.filters ? data.filters : {},
      path: '/group/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'affiliation',
            value: res.affiliation,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateAffiliation({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/group/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'affiliation',
            value: res.affiliation,
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadTags({ commit }) {
    let tags = []

    let response1 = await this.$axios.get('/tag-group/' + 1)
    tags.push(response1.data.tag_group)

    let response2 = await this.$axios.get('/tag-group/' + 5)
    tags.push(response2.data.tag_group)

    commit('setValue', {
      name: 'tags',
      value: tags,
    })
  },
  async loadProjects({ commit }, data) {
    let requestData = {
      method: 'get',
      data: data.data,
      path:
        '/group/' +
        data.id +
        '/projects' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          // commit('setPaginated', {
          //   name: 'affiliation_projects',
          //   value: res,
          //   reset: data.reset ? true : false,
          //   type: 'affiliation_projects_paginated'
          // })

          commit('appendPaginated', {
            name: 'affiliation_projects',
            value: res,
            reset: data.reset ? true : false,
            type: 'affiliation_projects_paginated',
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteProject({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/project/' + data.id,
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async orderProjects({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/projects/reorder',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'projects',
            value: res.projects_ordered,
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadTeam({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/group/' + data.id + '/users/belonging',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'team',
            value: res.users_belonging,
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadRoles({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/group-roles/search',
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'affiliation_roles',
            value: res,
            type: 'affiliation_roles_paginated',
            handle: 'roles',
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateRole({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/group/' + data.id + '/change-membership',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAdmin({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.filters ? data.filters : {},
      path: '/groups/filter' + (data.page ? '?page=' + data.page : ''),
      headers: {
        Format: 'admin',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'affiliations',
            value: res,
            reset: data.reset ? true : false,
            type: 'affiliations_paginated',
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addAttachment({ commit }, data) {
    // console.log("ADD ATTACHMENT")
    // console.log(data)
    commit('setValue', {
      name: 'uploadStatus',
      value: 'Loading...',
    })

    let requestData = {
      method: 'post',
      data: data.data,
      path: '/attachment',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'uploadStatus',
            value: 'Completed!',
          })

          // console.log("SUCCESS")
          // console.log(res)

          commit('setValue', {
            name: 'affiliation',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        } else {
          // console.log("ATTACHMENT ERROR")
          // console.log(res)
          commit('setValue', {
            name: 'uploadStatus',
            value:
              res.errors && res.errors.file && res.errors.file.length
                ? res.errors.file[0]
                : res.message,
          })

          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

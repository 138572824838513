<template>
  <div :style="parentStyles()">
    {{ title }}{{ punctuation }}

    <nuxt-link
      v-if="showAffiliationLink"
      :to="'/company-partners/' + user.default_affiliation.slug"
      ><span data-test="user-affiliation-name">{{
        affiliationName
      }}</span></nuxt-link
    >

    <span v-else data-test="user-affiliation-name">{{ affiliationName }}</span>
  </div>
</template>

<script>
import { get } from 'lodash-es'

export default {
  props: {
    user: Object,
    disableAffiliationLink: {
      type: Boolean,
      default: false,
    },
    truncateWidth: {
      type: String,
      default: null,
    },
  },
  computed: {
    title() {
      const { job_title } = this.user

      if (job_title) return job_title

      return ''
    },
    affiliationName() {
      if (this.user.is_internal) return 'The Atlas'

      if (this.user.default_affiliation_name)
        return this.user.default_affiliation_name

      return get(this.user, 'default_affiliation.name')
    },
    punctuation() {
      if (this.affiliationName && this.title) return ', '

      return ''
    },
    showAffiliationLink() {
      // Overridden via prop
      if (this.disableAffiliationLink) return false

      const isPartner =
        get(this.user, 'default_affiliation.affiliation_type_value') ===
        'partner-focused'

      return isPartner
    },
  },
  methods: {
    parentStyles() {
      if (this.truncateWidth) {
        return {
          'text-overflow': 'ellipsis',
          width: this.truncateWidth,
          overflow: 'hidden',
          'white-space': 'nowrap',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
  color: inherit;
}
</style>

<template>
  <div aria-live="polite" class="error-alert" data-test="error-alert">
    <b class="error-alert__title">{{ title || 'Sorry, an error occurred.' }}</b>
    <div class="error-alert__body">
      <slot />
    </div>
  </div>
</template>

<script>
// TODO: Add a type="error" option to the Alert.vue file and move this there
export default {
  props: ['title'],
}
</script>

<style lang="scss" scoped>
.error-alert {
  margin: 0 0 20px;
  padding: 10px;
  background: #ffded2;

  /deep/ a {
    text-decoration: underline;
    white-space: nowrap;
  }

  /deep/ ul,
  /deep/ ol {
    padding-left: 22px;
  }
}

.error-alert__title {
  display: block;
  margin-bottom: 5px;
}
</style>

import { values } from 'lodash-es'
import { ProjectExtended } from '~/types/Project'
import { ProjectAffiliation } from '~/types/Projects'

export const MODAL_NAMES = {
  PROBLEM_ADDRESSED: 'problemAddressed',
  PROJECT_IMAGE: 'projectImageUpload',
  LOCAL_GOV_LOGO: 'localGovLogoUpload',
  OUTCOMES: 'projectOutcomes',
  LESSONS: 'projectLessons',
  RESOURCES: 'projectResources',
  TAGS_KEYWORDS: 'projectTagsKeywords',
  CHALLENGE_CATEGORIES: 'projectChallengeCategories',
  CASE_STUDY_DETAILS: 'projectCaseStudyDetails',
  TITLE_OVERVIEW: 'projectOverview',
  RFP: 'projectRfpUpload',
  UNSAVED_CHANGES: 'projectUnsavedChanges',
  REQUIRED_FIELDS: 'projectRequiredFields',
  SEO_SETTINGS: 'pageSeo',
  PUBLISHED: 'projectPublished',
  DELETE: 'projectDelete',
  AUTHOR: 'projectAuthor',
  SOLUTIONS_USED: 'solutionsUsed',
  LOCAL_GOV_TEAM: 'localGovTeam',
  OTHER_RECOGNITION: 'otherRecognition',
} as const

type ValueOf<T> = T[keyof T]

export type ModalName = ValueOf<typeof MODAL_NAMES>

function getIssuesCount(fields: boolean[]): number {
  // Get only the ones that are true, and return the count
  return fields.filter((x) => x).length
}

export function publishStatus(project: ProjectExtended) {
  if (project.approved && project.published) return 'Published'

  if (project.published) return 'Submitted'

  return 'Draft'
}

export function getRequiredFields(project: ProjectExtended) {
  const titleOverviewIssuesCount = (): number => {
    // Title & Overview
    // - Missing Title
    const missingTitle = !project.page_title_h1

    // - Missing Local Gov Logo
    const missingLocalGovLogo = !project.default_local_gov_logo_url

    // - Missing Local Gov Name
    const missingLocalGovName = !project.local_gov_name

    // - Missing Location
    const missingLocation = !project.location_address

    // - Missing At a Glance
    const missingAtAGlance = !project.description

    return getIssuesCount([
      missingTitle,
      missingLocalGovLogo,
      missingLocalGovName,
      missingLocation,
      missingAtAGlance,
    ])
  }

  const caseStudyDetailsIssuesCount = (): number => {
    // Case Study Details
    // - Missing Cost

    const missingCost = (() => {
      const cost = project.cost

      if (project.hide_cost) return false

      if (!cost.currency) return true

      if (!cost.zero_upfront_cost) {
        if (cost.initial.amount === 0) {
          return true
        }
        if (!cost.initial.multiplier_label || !cost.initial.multiplier_value) {
          return true
        }
      }

      return false
    })()

    // - Missing Status
    const missingStatus = !(project.status && project.since_year)

    return getIssuesCount([missingCost, missingStatus])
  }

  const projectPhotosIssuesCount = (): number => {
    // Project Photos
    // - Missing photo
    const missingPhoto =
      (project.attachments_sorted?.project_images?.length || 0) < 1

    return getIssuesCount([missingPhoto])
  }

  const outcomesIssuesCount = (): number => {
    // Outcomes
    // - Missing outcome
    const outcome = Number(project.outcomes?.length) < 1

    return getIssuesCount([outcome])
  }

  const tagsKeywordsIssuesCount = (): number => {
    // Tags
    // - Missing challenge categories tag
    const missingChallengeTag =
      (project.tags_categorized[1]?.tags?.length || 0) < 1
    // - Missing funding/financing tag
    const missingFundingTag =
      (project.tags_categorized[3]?.tags?.length || 0) < 1
    // - Missing motivation tag
    const missingMotivationTag =
      (project.tags_categorized[2]?.tags?.length || 0) < 1
    // - Missing project type tag
    const missingTypeTag = (project.tags_categorized[8]?.tags?.length || 0) < 1

    return getIssuesCount([
      missingChallengeTag,
      missingFundingTag,
      missingMotivationTag,
      missingTypeTag,
    ])
  }

  const result = {
    title_overview: {
      modalName: MODAL_NAMES.TITLE_OVERVIEW,
      title: 'Overview',
      count: titleOverviewIssuesCount(),
    },
    case_study_details: {
      modalName: MODAL_NAMES.CASE_STUDY_DETAILS,
      title: 'Case Study Details',
      count: caseStudyDetailsIssuesCount(),
    },
    project_photos: {
      modalName: MODAL_NAMES.PROJECT_IMAGE,
      title: 'Project Images',
      count: projectPhotosIssuesCount(),
    },
    outcomes: {
      modalName: MODAL_NAMES.OUTCOMES,
      title: 'Outcomes',
      count: outcomesIssuesCount(),
    },
    tags_keywords: {
      modalName: MODAL_NAMES.TAGS_KEYWORDS,
      title: 'Tags & Keywords',
      count: tagsKeywordsIssuesCount(),
    },
  }

  return result
}

export function getTotalRequiredFields(requiredFields: RequiredFields): number {
  const total = values(requiredFields).reduce((totalCount, sectionInfo) => {
    return totalCount + sectionInfo.count
  }, 0)

  return total
}

export function getProjectUrl(project: ProjectExtended) {
  const id = project.slug ? project.slug : project.id

  return process.env.BASE_PATH + '/projects/' + id
}

export function getProjectImageUrl(project: ProjectExtended) {
  return project.default_project_image_url
    ? project.default_project_image_url
    : ''
}

export function getProjectTitle(project: ProjectExtended) {
  return project.page_title_seo ? project.page_title_seo : project.page_title_h1
}

export function getProjectDescription(project: ProjectExtended) {
  return project.description_seo ? project.description_seo : project.description
}

export function getAffiliationUrl(affiliation: ProjectExtended['affiliation']) {
  if (!affiliation || affiliation.affiliation_type_value !== 'partner-focused')
    return ''

  return process.env.BASE_PATH + '/company-partners/' + affiliation.slug
}

// Reference: https://developers.google.com/search/docs/advanced/structured-data/article#guidelines
export function getJsonSchemaData(project: ProjectExtended) {
  const projectUrl = getProjectUrl(project)
  const projectTitle = getProjectTitle(project)
  const projectImageUrl = getProjectImageUrl(project)
  const affiliationName = project.affiliation?.name
  const affiliationUrl = getAffiliationUrl(project.affiliation)
  const publishedDate = project.published_or_created
  const updatedDate = project.autosaved_at

  const author: any = {
    '@type': 'Organization',
    name: affiliationName,
  }

  // We won't have a URL for non-partner affiliations
  if (affiliationUrl) {
    author.url = affiliationUrl
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': projectUrl,
    },
    headline: projectTitle,
    image: projectImageUrl,
    author: author,
    publisher: {
      '@type': 'Organization',
      name: 'The Atlas',
      logo: {
        '@type': 'ImageObject',
        url: 'https://the-atlas.com/logos/logo.svg',
      },
      sameAs: [
        'https://www.facebook.com/theatlasforcities/',
        'https://twitter.com/TheAtlas4Cities',
        'https://www.linkedin.com/company/the-atlas-marketplace-co./',
      ],
    },
    datePublished: publishedDate,
    dateModified: updatedDate,
  }
}

export type RequiredFieldSection = {
  modalName: ModalName
  title: string
  count: number
}

export type RequiredFields = ReturnType<typeof getRequiredFields>

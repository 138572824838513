<template>
  <modal
    class="edit scrollable"
    name="profileEdit"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__title">Edit Profile</div>

      <div class="modal__content">
        <TextInput
          id="name"
          v-model="values.name"
          v-validate="'max:175'"
          name="name"
          data-vv-scope="edit"
          :error="errors.first('edit.name')"
          :label="{
            text: 'Name',
          }"
        />

        <TextInput
          id="job_title"
          v-model="values.job_title"
          v-validate="'max:175'"
          name="job_title"
          data-vv-scope="edit"
          :error="errors.first('edit.job_title')"
          :label="{
            text: 'Title',
          }"
        />

        <div class="label">Location</div>

        <Location v-model="values.location" />

        <h3>Social</h3>

        <TextInput
          v-model="values.links.facebook.url"
          v-validate="{
            regex: /https:\/\/[www.]*facebook\.com/,
            url: true,
          }"
          name="facebook"
          placeholder="https://www.facebook.com/your-profile"
          data-vv-scope="edit"
          :error="errors.first('edit.facebook')"
        />

        <TextInput
          v-model="values.links.linkedin.url"
          v-validate="{
            regex: /https:\/\/[www.]*linkedin\.com/,
            url: true,
          }"
          name="linkedin"
          placeholder="https://www.linkedin.com/your-profile"
          data-vv-scope="edit"
          :error="errors.first('edit.linkedin')"
        />

        <TextInput
          v-model="values.links.twitter.url"
          v-validate="{
            regex: /https:\/\/[www.]*twitter\.com/,
            url: true,
          }"
          name="twitter"
          placeholder="https://www.twitter.com/your-profile"
          data-vv-scope="edit"
          :error="errors.first('edit.twitter')"
        />

        <div v-if="error" class="edit__error">
          {{ error }}
        </div>
      </div>

      <div class="modal__buttons">
        <div class="btn btn--clear" @click="$modal.hide('profileEdit')">
          Cancel
        </div>

        <Submit text="Save" :loading="loading" />
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import TextInput from '~/components/form/Input'
import Location from '~/components/form/Location'
import Submit from '~/components/form/Submit'

export default {
  components: {
    TextInput,
    Location,
    Submit,
  },
  inject: ['$validator'],
  data() {
    return {
      values: {
        name: '',
        job_title: '',
        location: {
          source_id: '',
          address: '',
        },
        links: {
          facebook: {
            display: 'Facebook',
            url: '',
            link_role: 'facebook',
          },
          linkedin: {
            display: 'LinkedIn',
            url: '',
            link_role: 'linkedin',
          },
          twitter: {
            display: 'Twitter',
            url: '',
            link_role: 'twitter',
          },
        },
      },
      error: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileEdit')
    },
  },
  mounted() {
    // Set listener for locationChange event from Location
    this.$root.$on('locationChange', this.locationChange)

    if (this.$track) this.$track.page()
  },
  beforeDestroy() {
    // Remove listener for locationChange event from Location
    this.$root.$off('locationChange', this.locationChange)
  },
  methods: {
    beforeOpen(event) {
      this.values.name = this.user.name
      this.values.job_title = this.user.job_title
      this.values.location.source_id = this.user.location
        ? this.user.location.id
        : ''
      this.values.location.address = this.user.location
        ? this.user.location.address
        : ''
      this.values.links.facebook.url =
        this.user.links_sorted.facebook &&
        this.user.links_sorted.facebook.length
          ? this.user.links_sorted.facebook[0].url
          : ''
      this.values.links.linkedin.url =
        this.user.links_sorted.linkedin &&
        this.user.links_sorted.linkedin.length
          ? this.user.links_sorted.linkedin[0].url
          : ''
      this.values.links.twitter.url =
        this.user.links_sorted.twitter && this.user.links_sorted.twitter.length
          ? this.user.links_sorted.twitter[0].url
          : ''
      this.error = ''
    },
    validateBeforeSubmit() {
      let app = this
      let submitData = {}

      this.error = ''

      this.$validator.validateAll('edit').then((x) => {
        if (x) {
          let updateData = {
            id: app.user.id,
            data: {
              name: app.values.name,
              job_title: app.values.job_title,
              location: app.values.location,
              links: app.buildLinks(),
            },
            cb: function () {
              app.$modal.hide('profileEdit')
              app.loading = false
            },
          }

          app.loading = true

          app.$store.dispatch('users/updateUser', updateData)
        }
      })
    },
    locationChange(location) {
      this.values.location.source_id = location.id
      this.values.location.address = location.address

      // this.updateUser({
      //   location: {
      //     source_id: location.id,
      //     address: location.address,
      //   }
      // })
    },
    buildLinks() {
      let app = this
      let items = []

      Object.keys(app.values.links).forEach(function (index) {
        let item = app.values.links[index]

        if (item.url && item.url.length) {
          items.push({
            display: item.display,
            url: item.url,
            link_role: item.link_role,
          })
        }
      })

      if (app.user.links_sorted.self_articles.length) {
        app.user.links_sorted.self_articles.forEach(function (item) {
          items.push({
            display: item.display,
            url: item.url,
            link_role: item.link_role,
          })
        })
      }

      if (app.user.links_sorted.self_presentations.length) {
        app.user.links_sorted.self_presentations.forEach(function (item) {
          items.push({
            display: item.display,
            url: item.url,
            link_role: item.link_role,
          })
        })
      }

      return items
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

.input,
.textarea {
  margin-bottom: 20px;
}

.edit {
  h3 {
    margin-top: 50px;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.78px;
    text-transform: uppercase;
  }

  .label,
  /deep/ .label {
    color: #000000;
  }
}
</style>

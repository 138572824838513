<template>
  <modal
    name="deleteConfirm"
    :clickToClose="false"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__title">Delete {{ type }}</div>

      <div class="modal__content">
        <p>
          This is permanent. Please confirm by typing "DELETE" in the input
          below
        </p>

        <DeleteInput
          id="delete_confirm"
          v-model="deleteConfirm"
          v-validate="{ required: true, regex: /\bDELETE\b/ }"
          name="delete_confirm"
          data-vv-as="delete"
          placeholder="Type DELETE"
          :error="errors.first('delete_confirm')"
        />
      </div>

      <div class="modal__buttons">
        <div class="btn btn--clear" @click="$modal.hide('deleteConfirm')">
          Cancel
        </div>

        <button class="btn btn--alert" type="submit">Delete</button>
      </div>
    </form>
  </modal>
</template>

<script>
import DeleteInput from '~/components/form/Input'

export default {
  components: {
    DeleteInput,
  },
  inject: ['$validator'],
  data() {
    return {
      id: null,
      type: null,
      cb: null,
      deleteConfirm: '',
    }
  },
  methods: {
    beforeOpen(event) {
      // console.log(event.params.id);
      this.id = event.params.id
      this.type = event.params.type
      this.cb = event.params.cb
    },
    beforeClose(event) {
      this.deleteConfirm = ''
    },
    validateBeforeSubmit(e) {
      e.preventDefault()
      // console.log("VALIDATE")
      // console.log(this.$validator)
      let app = this

      this.$validator
        .validate('delete_confirm')
        .then((x) => {
          // console.log(x)
          if (x) {
            // console.log("VALID")
            // console.log(app.id)

            if (app.type) {
              switch (app.type) {
                case 'project':
                  this.$store.dispatch('affiliations/deleteProject', {
                    id: app.id,
                  })
                  break
                case 'comment':
                  this.$store.dispatch('posts/deleteComment', {
                    id: app.id,
                  })
                  break
              }
            }
            this.deleteConfirm = ''
            this.$modal.hide('deleteConfirm')
            if (app.cb) app.cb()
          }
        })
        .catch((e) => {
          // console.log(e)
        })
    },
  },
}
</script>

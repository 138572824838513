
import ProjectEditModalGeneric from '~/components/project/modals/ProjectEditModalGeneric'
import projectEditModalMixin, {
  MODAL_NAMES,
} from '~/mixins/projectEditModalMixin'

export default projectEditModalMixin.extend({
  components: {
    ProjectEditModalGeneric,
  },
  data() {
    return {
      modalName: MODAL_NAMES.PUBLISHED,
    }
  },
  computed: {
    modalProps(): object {
      return {
        title: 'Congratulations!',
        subtitle: 'Your project has been submitted for review.',
        modalName: this.modalName,
        hideSubmitButton: true,
        showApiError: false,
        closeButtonText: 'OK',
      }
    },
  },
})


import Vue from 'vue'
import Submit from '~/components/form/Submit'
import ExitSvg from '~/static/icons/round-close-42px.svg'

import InfoSvg from '~/static/icons/info-24px.svg'

import { BREAKPOINTS } from '~/constants.js'

export default Vue.extend({
  components: {
    Submit,
    ExitSvg,
    InfoSvg,
  },
  props: {
    subtitle: String,
    beforeOpen: {
      type: Function,
      required: false,
    },
    beforeClose: {
      type: Function,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
    closeButtonText: {
      type: String,
      required: false,
    },
    modalDimensions: {
      type: Object,
      required: false,
    },
    submitButtonText: {
      type: String,
      required: false,
    },
    showApiError: {
      type: Boolean,
      required: false,
    },
    apiErrorCode: {
      type: Number,
      required: false,
    },
    hideSubmitButton: {
      type: Boolean,
      required: false,
    },
    stacked: {
      type: Boolean,
      required: false,
    },
    // eslint-disable-next-line vue/no-unused-properties
    requiredFieldsCount: {
      type: Number,
      required: false,
    },
    handleSubmit: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      viewportWidth: 0,
    }
  },
  computed: {
    setModalDimensions(): object {
      if (this.viewportWidth < BREAKPOINTS.sm) {
        return { maxWidth: 350, height: 'auto', adaptive: true }
      }

      if (this.modalDimensions) {
        return this.modalDimensions
      }

      return { minWidth: 650, height: 'auto', adaptive: true }
    },
    errorStyles(): object {
      if (this.showApiError) {
        return { 'justify-content': 'space-between' }
      }
      return { 'justify-content': 'flex-end' }
    },
    apiErrorMessage() {
      if (this.showApiError) {
        if (this.apiErrorCode === 401) {
          return '<strong>Your login token has expired.</strong><br>Please login in another tab to avoid losing your changes and try again.'
        }

        return `<strong>We are experiencing an issue.</strong> Contact<a href="mailto:support@the-atlas.com">support@the-atlas.com</a>`
      }
      return ''
    },
    width(): number {
      if (this.stacked) return 600
      return 650
    },
    triggerBeforeOpen(): string | null {
      return this.beforeOpen ? 'before-open' : null
    },
    triggerBeforeClose(): string | null {
      return this.beforeClose ? 'before-close' : null
    },
  },
  mounted() {
    if (this.$parent) this.$parent.$on('setLoading', this.setLoading)
    window.addEventListener('resize', this.setViewportSize)
    this.setViewportSize()
  },
  beforeDestroy() {
    if (this.$parent) this.$parent.$off('setLoading', this.setLoading)
    window.removeEventListener('resize', this.setViewportSize)
  },
  methods: {
    setLoading(loadingState) {
      this.loading = loadingState
    },
    hideModal() {
      this.$modal.hide(this.modalName)
    },
    setViewportSize() {
      if (window) {
        this.viewportWidth = window.innerWidth
      }
    },
  },
})

<template>
  <footer class="footer" :class="buildModifier(modifier)">
    <div class="footer__container content">
      <img
        class="footer__brand"
        src="/logos/logo-dark-mode.svg"
        alt="The Atlas logo"
      />

      <div>
        <nav class="footer__nav">
          <ul>
            <li>
              <a class="footer__nav-menu-block-link" href="/about"> About </a>
            </li>

            <li>
              <a class="footer__nav-menu-block-link" href="/contact-us">
                Contact Us
              </a>
            </li>

            <li>
              <a class="footer__nav-menu-block-link" href="/faqs"> FAQs </a>
            </li>

            <li>
              <a class="footer__nav-menu-block-link" href="/media"> Media </a>
            </li>

            <li>
              <a class="footer__nav-menu-block-link" href="/library">
                Library
              </a>
            </li>

            <li>
              <nuxt-link
                class="footer__nav-menu-block-link"
                to="/company-partners"
                @click.native="reloadPage('/company-partners')"
              >
                Find Partners
              </nuxt-link>
            </li>
          </ul>
        </nav>

        <div class="footer__social">
          <a
            href="https://twitter.com/theatlas4cities"
            class="footer__social-icon"
            target="_blank"
            aria-label="The Atlas on Twitter"
          >
            <svgTwitter />
          </a>

          <a
            href="https://www.linkedin.com/company/the-atlas-marketplace-co./"
            class="footer__social-icon"
            target="_blank"
            aria-label="The Atlas on LinkedIn"
          >
            <svgLinkedin />
          </a>

          <a
            href="https://www.facebook.com/theatlasforcities/"
            class="footer__social-icon"
            target="_blank"
            aria-label="The Atlas on Facebook"
          >
            <svgFacebookRound />
          </a>
        </div>
      </div>
    </div>

    <div class="footer__meta content">
      <nav class="footer__meta-nav">
        <ul>
          <li>
            <a class="footer__nav-menu-block-link" href="/terms-of-use">
              Terms of Use
            </a>
          </li>
          <li>
            <a class="footer__nav-menu-block-link" href="/privacy-policy">
              Privacy Policy
            </a>
          </li>
        </ul>
      </nav>

      <div class="footer__copyright">
        &copy; {{ date }} GovExec. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import svgTwitter from '~/static/icons/social/twitter-icon.svg'
import svgLinkedin from '~/static/icons/social/linkedin-icon.svg'
import svgFacebookRound from '~/static/icons/social/facebook-icon-round.svg'

export default {
  components: {
    svgTwitter,
    svgLinkedin,
    svgFacebookRound,
  },
  props: ['modifier'],
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  methods: {
    buildModifier(modifier) {
      if (modifier) {
        modifier = 'footer--' + modifier

        return modifier
      }
    },
    reloadPage(path) {
      if (window.location.pathname === path) {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background: $midnight;
  padding: 48px 10px;
  position: relative;

  @include bp('sm') {
    padding: 56px 20px 24px;
  }
}

.footer__container {
  padding: 0;
}

.footer__container,
.footer__meta {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include bp('sm') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__brand {
  height: 21px;
  width: 106px;
  margin-bottom: 20px;

  @include bp('sm') {
    height: 26px;
    width: 132px;
    margin-bottom: 0;
  }
}

.footer__nav,
.footer__meta-nav {
  ul {
    display: flex;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0 15px;

    @include bp('sm') {
      margin-right: 30px;
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    font-size: 11px;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    @include bp('sm') {
      font-size: 14px;
    }
  }
}

.footer__nav {
  a {
    color: #ffffff;
  }

  ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer__meta {
  border-top: 1px solid #5d6877;
  align-items: center;
  margin-top: 42px;
  padding-top: 18px;
  color: #b2becd;
  font-size: 11px;

  @include bp('sm') {
    margin-top: 64px;
    padding-top: 28px;
    font-size: 14px;
  }

  @include bp('md') {
    padding: 28px 0 0 0;
  }
}

.footer__meta-nav {
  a {
    color: #b2becd;
  }
}

.footer__social {
  display: flex;
  margin: 24px 0 10px;
  justify-content: center;

  @include bp('sm') {
    justify-content: flex-start;
    margin: 24px 0 0;
  }
}

.footer__social-icon {
  fill: #ffffff;
  width: 20px;
  height: 20px;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}
</style>

<template>
  <button type="button" class="action-button" @click="onClick()">
    <slot v-if="$slots.icon" name="icon"></slot>
    <span class="action-button__text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: ['text', 'onClick'],
}
</script>

<style lang="scss" scoped>
.action-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

.action-button__text {
  text-decoration: underline;
  line-height: 0;
}
</style>

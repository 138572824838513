<template>
  <modal
    name="uploadImage"
    class="uploadImage"
    height="auto"
    :width="550"
    @before-open="beforeOpen"
  >
    <div id="image_upload">
      <FileUpload
        id="image_upload"
        v-model="image_upload"
        v-validate="'ext:png,jpg,jpeg|size:10240'"
        name="image_upload"
        placeholder="file.jpg"
        :error="errors.first('image_upload')"
        data-vv-as="image"
        :label="{
          text: 'Image Upload',
        }"
        @change="updateAttachments"
      />
    </div>
  </modal>
</template>

<script>
import FileUpload from '~/components/form/FileUpload'

export default {
  components: {
    FileUpload,
  },
  inject: ['$validator'],
  data() {
    return {
      image_upload: '',
      attachmentable_id: null,
      attachmentable_type: null,
      attachment_role: null,
    }
  },
  methods: {
    beforeOpen(event) {
      this.attachmentable_id = event.params.attachmentable_id
      this.attachmentable_type = event.params.attachmentable_type
      this.attachment_role = event.params.attachment_role
    },
    updateAttachments(data) {
      // console.log("UPDATE ATTACHMENTS ON INPUT")
      // console.log(data)
      let app = this

      if (!this.image_upload) return

      // console.log("HAS FILE")

      this.$validator.validate('image_upload').then((x) => {
        if (x) {
          let dataSubmit = new FormData()
          dataSubmit.append('attachmentable_id', Number(app.attachmentable_id))
          dataSubmit.append('attachmentable_type', app.attachmentable_type)
          dataSubmit.append('attachment_role', app.attachment_role)
          dataSubmit.append('file', this.image_upload[0])

          // console.log("DATA SUBMIT")
          // console.log(dataSubmit)

          let updateData = {
            data: dataSubmit,
            type: app.attachment_role,
          }

          // console.log(updateData)
          switch (app.attachmentable_type) {
            case 'projects':
              this.$store.dispatch('projects/addAttachment', updateData)
              break
            case 'affiliations':
              this.$store.dispatch('affiliations/addAttachment', updateData)
              break
            case 'users':
              this.$store.dispatch('users/addAttachment', updateData)
              break
          }

          this.$modal.hide('uploadImage')
          this.image_upload = ''
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#image_upload /deep/ .form--error .input__elem {
  margin-bottom: 0;
}

#image_upload /deep/ .form__error {
  margin-top: 5px;
}
</style>

const utils = {
  toggleClass(elem, target, className) {
    let type = target.substring(0, 1)
    let targetElem = null
    let activeClass = elem.id
      ? elem.id + '--active'
      : elem.classList.item(0) + '--active'

    // console.log("TOGGLE CLASS")
    // console.log(elem)
    // console.log(target)
    // console.log(className)
    // console.log(type)

    switch (type) {
      case '#':
        targetElem = document.getElementById(target.substring(1))
        break
      case '.':
        targetElem = elem.querySelector(target)
        break
      case 'n':
        targetElem = elem.nextElementSibling
    }

    // console.log(targetElem)

    if (elem) elem.classList.toggle(activeClass)
    if (targetElem) targetElem.classList.toggle(className)
  },
  slugName(name) {
    return name.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
  },
  charCount($event, maxlength) {
    let elem = $event.target
    let curCount = elem.value.length
    let countArea = elem.nextSibling

    if (countArea)
      countArea.innerHTML =
        '<span class="count-value">' + curCount + '</span>/' + maxlength

    if (curCount > maxlength) {
      // console.log("ADD")
      if (countArea && countArea.classList)
        countArea.classList.add(countArea.classList.item(0) + '--error')
      elem.classList.add(elem.classList.item(0) + '--error')
    } else {
      // console.log("REMOVE")
      if (countArea && countArea.classList)
        countArea.classList.remove(countArea.classList.item(0) + '--error')
      elem.classList.remove(elem.classList.item(0) + '--error')
    }
  },
}

export default utils

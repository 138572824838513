<template>
  <modal
    class="following scrollable"
    name="profileFollowing"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__content modal__content--scroll">
        <div class="modal__title">Following</div>

        <div
          class="favorite__title"
          @click="
            () => {
              showPeople = showPeople ? false : true
            }
          "
        >
          People
          <div class="favorite__caret" />
        </div>

        <p>
          Grow your local government network.
          <a class="favorite__link" href="/users/community"
            >Follow members now</a
          >.
        </p>

        <div v-if="showPeople" class="favorite__content">
          <ul
            v-if="users && users.items.length"
            class="favorite__list favorite__list--users"
          >
            <li
              v-for="user in users.items"
              :key="user.id"
              class="user"
              data-test="following-modal-user"
            >
              <div class="user__data">
                <nuxt-link :to="'/users/' + user.slug">
                  <Avatar
                    :imgSrc="user.default_profile_pic_url"
                    :userName="user.name"
                    :style="{ 'margin-right': '10px' }"
                  />
                </nuxt-link>

                <nuxt-link :to="'/users/' + user.slug" class="user__info">
                  <div v-if="user.name" class="user__name">
                    {{ user.name }}
                  </div>

                  <div class="user__meta">
                    <UserTitle :user="user" disableAffiliationLink />
                  </div>
                </nuxt-link>
              </div>

              <FavoriteInline
                :active="true"
                :data="{
                  data: {
                    favoriteable_id: user.id,
                    favoriteable_type: 'users',
                  },
                }"
              />
            </li>
          </ul>

          <div
            v-if="users.pagination.current_page !== users.pagination.last_page"
            class="favorites__show-more"
            @click="loadUsers"
          >
            Show More
          </div>

          <p v-if="!users || !users.items.length">
            You’re not following anyone yet.
            <br />
            Head to the
            <nuxt-link class="modal__link" to="/users/community"
              >Browse Users</nuxt-link
            >
            page to find colleagues to follow.
          </p>
        </div>

        <div
          class="favorite__title"
          @click="
            () => {
              showAffiliations = showAffiliations ? false : true
            }
          "
        >
          Companies
          <div class="favorite__caret" />
        </div>

        <p>
          Keep tabs on local government's proven private sector partners.
          <a class="favorite__link" href="/company-partners"
            >Follow companies now</a
          >.
        </p>

        <div v-if="showAffiliations" class="favorite__content">
          <ul
            v-if="affiliations && affiliations.items.length"
            class="favorite__list favorite__list--affiliations"
          >
            <li
              v-for="affiliation in affiliations.items"
              :key="`following-affiliation-${affiliation.id}`"
              class="affiliation"
            >
              <div class="affiliation__data">
                <nuxt-link
                  :to="'/company-partners/' + affiliation.slug"
                  class="affiliation__image"
                  :class="{
                    'affiliation__image--empty': !affiliation.default_logo_url,
                  }"
                  :style="[
                    affiliation.default_logo_url
                      ? {
                          'background-image':
                            'url(\'' + affiliation.default_logo_url + '\')',
                        }
                      : '',
                  ]"
                />

                <nuxt-link
                  :to="'/company-partners/' + affiliation.slug"
                  class="affiliation__info"
                >
                  <div v-if="affiliation.name" class="affiliation__name">
                    {{ affiliation.name }}
                  </div>
                </nuxt-link>
              </div>

              <FavoriteInline
                :active="true"
                :data="{
                  data: {
                    favoriteable_id: affiliation.id,
                    favoriteable_type: 'affiliations',
                  },
                }"
              />
            </li>
          </ul>

          <div
            v-if="
              affiliations.pagination.current_page !=
              affiliations.pagination.last_page
            "
            class="favorites__show-more"
            @click="loadAffiliations"
          >
            Show More
          </div>

          <p v-if="!affiliations || !affiliations.items.length">
            You’re not following any companies yet.
            <br />
            You can head to the
            <nuxt-link class="modal__link" to="/company-partners"
              >Browse Partners</nuxt-link
            >
            page to find some to follow.
          </p>
        </div>

        <div class="modal__buttons">
          <div class="btn btn--clear" @click="$modal.hide('profileFollowing')">
            Cancel
          </div>

          <Submit text="Save" :loading="loading" />
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Submit from '~/components/form/Submit'
import FavoriteInline from '~/components/FavoriteInline'
import UserTitle from '~/components/UserTitle'
import Avatar from '~/components/user/Avatar'

export default {
  components: {
    Submit,
    FavoriteInline,
    Avatar,
    UserTitle,
  },
  inject: ['$validator'],
  data() {
    return {
      showKeywords: true,
      curKeywords: [],
      showPeople: true,
      showAffiliations: true,
      error: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      keywords: 'keywords/keywords',
      users: 'favorites/users',
      affiliations: 'favorites/affiliations',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileFollowing')
    },
  },
  methods: {
    async beforeOpen(event) {
      let app = this

      this.$store.dispatch('keywords/get', {
        cb: function (keywords) {
          let allKeywords = []

          keywords.forEach(function (keyword) {
            allKeywords.push(keyword)
          })

          app.curKeywords = allKeywords
        },
      })

      this.error = ''
    },
    beforeClose(event) {
      this.$store.dispatch('favorites/loadUsers', { reset: true })
      this.$store.dispatch('favorites/loadAffiliations', { reset: true })
    },
    validateBeforeSubmit() {
      let app = this
      let submitData = {}

      this.error = ''

      this.$validator.validateAll('following').then((x) => {
        if (x) {
          let updateData = {
            id: app.user.id,
            data: {},
            cb: function () {
              app.$modal.hide('profileFollowing')
              app.loading = false
            },
          }

          app.loading = true

          app.$store.dispatch('users/updateUser', updateData)
        }
      })
    },
    updateKeywords(tags) {
      let app = this
      let allTags = []
      let allNames = []

      // Set tags to current values
      if (tags) this.curKeywords = tags.tags

      this.curKeywords.forEach(function (tag) {
        allTags.push(tag.id)
        allNames.push(tag.text)
      })

      this.$store.dispatch('keywords/set', {
        data: {
          favoriteable_type: 'keywords',
          favoriteable_ids: allTags,
        },
      })

      if (!app.$track) return

      app.$track.event({
        segment: {
          action: 'Keywords Updated',
          data: {
            user_id: app.user.id,
            keywords: allNames,
          },
        },
      })
    },
    searchKeywords(value) {
      this.curTerm = value

      this.startTimer()
    },
    addKeyword(item) {
      this.curKeywords.push(item)

      this.updateKeywords()
    },
    startTimer() {
      let time = 300
      let app = this

      if (app.timer) clearInterval(app.timer)

      app.timer = setInterval(function () {
        app.stopTimer()

        if (app.curTerm && app.curTerm.length > 2) {
          app.$store.dispatch('keywords/search', {
            data: {
              suggested_for: 'projects',
              term: app.curTerm,
            },
          })

          return
        }
      }, time)
    },
    stopTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    clearKeywords() {
      this.$store.dispatch('keywords/setValue', {
        name: 'keywords',
        value: [],
      })
    },
    loadUsers() {
      this.$store.dispatch('favorites/loadUsers', {
        page: this.users.pagination.current_page + 1,
      })
    },
    loadAffiliations() {
      this.$store.dispatch('favorites/loadAffiliations', {
        page: this.affiliations.pagination.current_page + 1,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

.modal__content {
  margin: 0;
}

.modal__title {
  color: #000000;
}

.input,
.textarea {
  margin-bottom: 20px;
}

.favorite__title {
  display: flex;
  margin: 0 0 15px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #50505d;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.favorite__caret {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000000;
  border-radius: 2px;
}

.favorite__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.user,
.affiliation {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

.user__data,
.affiliation__data {
  display: flex;
}

.affiliation__data {
  align-items: center;
}

.affiliation__image {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 10px 0 0;
  border: 1px solid #ababab;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  background-image: url('/img/users/missing-logo-user.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @include retina() {
    background-image: url('/img/users/missing-logo-user2x.png');
  }
}

.affiliation__image {
  background-image: url('/icons/round-business_center-grey.svg');
}

.affiliation__image--empty {
  background-size: 60%;
}

.user__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user__name,
.affiliation__name {
  font-size: 18px;
}

.favorite__toggle {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bold;
  text-decoration: underline;
}

.favorite__follow {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  fill: #000000;
}

// .favorite__follow--unfollow {
//   fill: #e39600;
// }

.favorites__show-more {
  margin-bottom: 20px;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
}

.modal__link {
  text-decoration: underline;
}

.favorite__link {
  text-decoration: underline;
}
</style>

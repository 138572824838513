const state = () => ({
  cohorts: null,
})

const getters = {
  cohorts: ({ cohorts }) => cohorts,
}

const mutations = {
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  async loadCohorts({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/user/' + data.id + '/cohort-form',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'cohorts',
            value: res.cohort_form,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
  async updateCohort({ commit }, data) {
    let app = this
    let token = app.getters['auth/token']

    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/user/' + data.id + '/cohort-form',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'cohorts',
            value: res.cohort,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

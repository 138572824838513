var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"badges scrollable",attrs:{"name":"profileBadges","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('div',{staticClass:"modal__title"},[_vm._v("Achievements")]),_vm._v(" "),_c('div',{staticClass:"modal__content"},[(
          _vm.$route.path === '/users/profile' ||
          _vm.$route.path === '/users/profile/'
        )?_c('p',{staticClass:"modal__intro"},[_vm._v("\n        Standout to your colleagues by earning badges for your profile. Want\n        to unlock more? Engage more!\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.$route.path !== '/users/profile' &&
          _vm.$route.path !== '/users/profile/'
        )?_c('p',{staticClass:"modal__intro"},[_vm._v("\n        All members of The Atlas can earn the achievement badges shown below.\n        Want to see your own?\n        "),_c('a',{staticClass:"modal__link",attrs:{"href":"/users/profile"}},[_vm._v("Go to your member profile")]),_vm._v(".\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.user &&
          _vm.user.profile_badges &&
          _vm.user.profile_badges.earned_unearned_limited
        )?_c('div',{staticClass:"badges"},_vm._l((_vm.user.profile_badges.earned_unearned_limited),function(badge){return _c('div',{key:`badge-${badge.id}`,staticClass:"badges__badge"},[_c('div',{staticClass:"badges__image",class:{
              'badges__image--inactive':
                !badge.earned && _vm.user.profile_badges.earned_count,
            },style:({
              'background-image': 'url(\'' + badge.image_url + '\')',
            })}),_vm._v(" "),_c('div',{staticClass:"badges__info"},[_c('div',{staticClass:"badges__title"},[_vm._v("\n              "+_vm._s(badge.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"badges__text",domProps:{"innerHTML":_vm._s(badge.description_html)}})])])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.$modal.hide('profileBadges')}}},[_vm._v("Done")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'

const state = () => ({
  notifications: {
    items: [],
    pagination: [],
  },
})

const getters = {
  notifications: ({ notifications }) => notifications,
}

const mutations = {
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
}

const actions = {
  async loadNotifications({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path:
        '/user/' +
        data.id +
        '/notifications' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'notifications',
            value: res,
            reset: data.reset ? true : false,
            type: 'notifications_paginated',
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async readNotifications({ commit }, data) {
    let app = this
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/user/' + data.id + '/notifications-read',
      cb: async function (res) {
        if (res.success) {
          app.dispatch('users/getNotices', {
            id: data.id,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

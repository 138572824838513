var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ama"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"ama__mobile"},[_c('div',{staticClass:"ama__icon"},[_c('svgQa')],1),_vm._v(" "),(
          _vm.user &&
          _vm.user.current_event_participant &&
          _vm.$route.params.id !== _vm.user.current_event_participant &&
          _vm.banner &&
          _vm.banner.event_banner_text_global
        )?_c('div',{staticClass:"ama__text",domProps:{"innerHTML":_vm._s(_vm.banner.event_banner_text_global)}}):_vm._e(),_vm._v(" "),(
          _vm.user &&
          _vm.user.current_event_participant &&
          _vm.banner &&
          _vm.banner.event_banner_text &&
          _vm.$route.params.id === _vm.user.current_event_participant
        )?_c('div',{staticClass:"ama__text",domProps:{"innerHTML":_vm._s(_vm.banner.event_banner_text)}}):_vm._e()]),_vm._v(" "),(
        _vm.user &&
        _vm.user.current_event_participant &&
        _vm.$route.params.id !== _vm.user.current_event_participant
      )?_c('a',{staticClass:"btn btn--orange ama__button",attrs:{"href":'/posts/' + _vm.user.current_event_participant}},[_vm._v("\n      Go There Now\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
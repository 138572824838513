var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer",class:_vm.buildModifier(_vm.modifier)},[_c('div',{staticClass:"footer__container content"},[_c('img',{staticClass:"footer__brand",attrs:{"src":"/logos/logo-dark-mode.svg","alt":"The Atlas logo"}}),_vm._v(" "),_c('div',[_c('nav',{staticClass:"footer__nav"},[_c('ul',[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('li',[_c('nuxt-link',{staticClass:"footer__nav-menu-block-link",attrs:{"to":"/company-partners"},nativeOn:{"click":function($event){return _vm.reloadPage('/company-partners')}}},[_vm._v("\n              Find Partners\n            ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"footer__social"},[_c('a',{staticClass:"footer__social-icon",attrs:{"href":"https://twitter.com/theatlas4cities","target":"_blank","aria-label":"The Atlas on Twitter"}},[_c('svgTwitter')],1),_vm._v(" "),_c('a',{staticClass:"footer__social-icon",attrs:{"href":"https://www.linkedin.com/company/the-atlas-marketplace-co./","target":"_blank","aria-label":"The Atlas on LinkedIn"}},[_c('svgLinkedin')],1),_vm._v(" "),_c('a',{staticClass:"footer__social-icon",attrs:{"href":"https://www.facebook.com/theatlasforcities/","target":"_blank","aria-label":"The Atlas on Facebook"}},[_c('svgFacebookRound')],1)])])]),_vm._v(" "),_c('div',{staticClass:"footer__meta content"},[_vm._m(5),_vm._v(" "),_c('div',{staticClass:"footer__copyright"},[_vm._v("\n      © "+_vm._s(_vm.date)+" GovExec. All rights reserved.\n    ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/about"}},[_vm._v(" About ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/contact-us"}},[_vm._v("\n              Contact Us\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/faqs"}},[_vm._v(" FAQs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/media"}},[_vm._v(" Media ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/library"}},[_vm._v("\n              Library\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"footer__meta-nav"},[_c('ul',[_c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/terms-of-use"}},[_vm._v("\n            Terms of Use\n          ")])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"footer__nav-menu-block-link",attrs:{"href":"/privacy-policy"}},[_vm._v("\n            Privacy Policy\n          ")])])])])
}]

export { render, staticRenderFns }
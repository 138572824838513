<template>
  <modal
    class="about scrollable"
    name="profileAbout"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__title">About Me</div>

      <div class="modal__content">
        <TextArea
          id="about_me"
          v-model="values.about_me"
          v-validate="'max:1000'"
          name="about_me"
          placeholder="About me..."
          data-vv-as="about me"
          data-vv-scope="about"
          :maxlength="1000"
          :error="errors.first('about.about_me')"
        />

        <TextInput
          id="last_book"
          v-model="values.last_book"
          v-validate="'max:175'"
          name="last_book"
          data-vv-scope="about"
          :error="errors.first('about.last_book')"
          :label="{
            text: 'Last Book',
          }"
        />

        <TextInput
          id="last_podcast"
          v-model="values.last_podcast"
          v-validate="'max:175'"
          name="last_podcast"
          data-vv-scope="about"
          :error="errors.first('about.last_podcast')"
          :label="{
            text: 'Last Podcast',
          }"
        />

        <div v-if="error" class="about__error">
          {{ error }}
        </div>
      </div>

      <div class="modal__buttons">
        <div class="btn btn--clear" @click="$modal.hide('profileAbout')">
          Cancel
        </div>

        <Submit text="Save" :loading="loading" />
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import TextInput from '~/components/form/Input'
import TextArea from '~/components/form/TextArea'
import Submit from '~/components/form/Submit'

export default {
  components: {
    TextInput,
    TextArea,
    Submit,
  },
  inject: ['$validator'],
  data() {
    return {
      values: {
        about_me: '',
        last_book: '',
        last_podcast: '',
      },
      error: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileAbout')
    },
  },
  methods: {
    beforeOpen(event) {
      this.values.about_me = this.user.about_me
      this.values.last_book = this.user.last_book
      this.values.last_podcast = this.user.last_podcast
      this.error = ''
    },
    validateBeforeSubmit() {
      let app = this
      let submitData = {}

      this.error = ''

      this.$validator.validateAll('about').then((x) => {
        if (x) {
          let updateData = {
            id: app.user.id,
            data: {
              about_me: app.values.about_me,
              last_book: app.values.last_book,
              last_podcast: app.values.last_podcast,
            },
            cb: function () {
              app.$modal.hide('profileAbout')
              app.loading = false
            },
          }

          app.loading = true

          app.$store.dispatch('users/updateUser', updateData)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

.input,
.textarea {
  margin-bottom: 20px;
}
</style>

<template>
  <div
    class="favorite"
    :class="curActive ? 'favorite--active' : ''"
    @click="toggleFavorite"
  >
    <svgAddPerson v-if="!curActive" class="favorite__follow" />

    <svgAddedPerson v-if="curActive" class="favorite__follow" />

    {{ curActive ? 'Following' : 'Follow' }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import svgAddPerson from '~/static/icons/round-person_add-24px.svg'
import svgAddedPerson from '~/static/icons/round-how_to_reg-24px.svg'

export default {
  components: {
    svgAddPerson,
    svgAddedPerson,
  },
  props: ['active', 'data'],
  data() {
    return {
      curActive: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  created() {
    this.curActive = this.active
  },
  methods: {
    toggleFavorite() {
      if (this.$auth.loggedIn) {
        let data = {
          id: this.data.data.favoriteable_id,
        }

        if (this.data.data.favoriteable_type === 'users')
          data.role_id = this.user.role_id

        if (!this.curActive) {
          if (this.$track) {
            this.$track.event({
              segment: {
                action:
                  this.data.data.favoriteable_type.charAt(0).toUpperCase() +
                  this.data.data.favoriteable_type.slice(
                    1,
                    this.data.data.favoriteable_type.length - 1
                  ) +
                  ' Favorited',
                data: data,
              },
            })
          }

          this.$store.dispatch('favorites/addFavorite', this.data)

          this.curActive = true
        } else {
          if (this.$track) {
            this.$track.event({
              segment: {
                action:
                  this.data.data.favoriteable_type.charAt(0).toUpperCase() +
                  this.data.data.favoriteable_type.slice(
                    1,
                    this.data.data.favoriteable_type.length - 1
                  ) +
                  ' Unfavorited',
                data: data,
              },
            })
          }

          this.$store.dispatch('favorites/deleteFavorite', this.data)

          this.curActive = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.favorite {
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.favorite__follow {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  fill: #000000;
}

.favorite__follow--unfollow {
  display: none;
}
</style>

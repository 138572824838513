const state = () => ({
  contactables: [],
  conversations: [],
  conversations_top: [],
  new_conversation: null,
  conversation: null,
  users: [],
  messages: [],
  messages_total: 0,
})

const getters = {
  contactables: ({ contactables }) => contactables,
  conversations: ({ conversations }) => conversations,
  conversations_top: ({ conversations_top }) => conversations_top,
  new_conversation: ({ new_conversation }) => new_conversation,
  conversation: ({ conversation }) => conversation,
  users: ({ users }) => users,
  messages: ({ messages }) => messages,
}

const mutations = {
  setConversations(state, conversations) {
    let allConversations = conversations.chats

    conversations.chats.forEach(function (chat) {
      if (state.conversations_top.includes(chat.id))
        conversations.chats.pop(chat)
    })

    if (state.conversations && !conversations.reset) {
      allConversations = state.conversations.concat(conversations.chats)
    }

    if (conversations.reset) state.conversations_top = []

    state.conversations = allConversations
  },
  appendConversation(state, conversation) {
    let conversations = state.conversations

    if (conversation.chat_with_same_users_existed) {
      state.conversations.forEach(function (curConvo) {
        if (conversation.chat.id == curConvo.id)
          state.conversations.pop(curConvo)
      })
    }

    state.conversations.unshift(conversation.chat)
    state.conversations_top.push(conversation.chat.id)
    state.messages = []
  },
  // appendArray(state, data) {
  //   let name = data.handle ? data.handle : data.name
  //   let allItems = data.value[data.name]

  //   if (state[name] && state[name].length && !data.reset) {
  //     allItems = state[name].concat(Array.isArray(data.value[data.name]) ? data.value[data.name] : [data.value[data.name]])
  //   }

  //   state[name] = Object.assign([], data.reset ? [] : state[name], allItems)
  // },
  appendArray(state, data) {
    const name = data.handle ? data.handle : data.name
    let allItems = Array.isArray(data.value[data.name])
      ? data.value[data.name]
      : [data.value[data.name]]

    if (state[name] && state[name].length && !data.reset) {
      allItems = state[name].concat(allItems)
    }

    state[name] = allItems
  },
  deleteArray(state, data) {
    state[name] = state[name].filter((item) => item.id !== data.id)
  },
  setValue(state, data) {
    if (data.path && !data.id) {
      let target = state[data.name]

      data.path.forEach(function (step) {
        target = target[step]
      })

      target = data.value

      return
    }

    // Set keyed value
    if (data.id) {
      if (data.path) {
        Vue.set(state[data.name][data.path], data.id, data.value)
        return
      }

      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadContactables({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/chats/contactable-users/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'contactables',
            value: res.users,
          })

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getConversations({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/chats/by-user/' + data.id,
      cb: async function (res) {
        if (res.success) {
          if (data && data.reset) res.reset = true

          commit('setConversations', res)

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getOldConversations({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chats/by-user/' + data.id + '/old',
      cb: async function (res) {
        if (res.success) {
          commit('setConversations', res)

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createConversation({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chat',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'conversation',
            value: res.chat,
          })

          commit('appendConversation', res)

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getConversation({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/chat/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'conversation',
            value: res.chat,
          })

          commit('appendConversation', res)

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getUsers({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/chat/' + data.id + '/users',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'users',
            value: res.chat_users,
          })

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addUsers({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chat/' + data.id + '/add-users',
      cb: async function (res) {
        if (res.success) {
          commit('appendConversation', res)

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getMessages({ commit }, data) {
    const conversations = this.getters['chats/conversations']
    let requestData = {
      method: 'get',
      data: {},
      path: '/chat/' + data.id + '/messages',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'messages_total',
            value: res.total_messages_for_user,
          })

          commit('appendArray', {
            name: 'messages',
            value: res,
            reset: data && data.reset ? data.reset : false,
          })

          if (
            data.selectedIndex &&
            conversations[data.selectedIndex].unread_messages_count
          ) {
            commit('setValue', {
              name: 'conversations',
              value: 0,
              id: data.selectedIndex,
              path: 'unread_messages_count',
            })
          }

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getOldMessages({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chat/' + data.id + '/old-messages',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'messages_total',
            value: res.total_messages_for_user,
          })

          commit('appendArray', {
            name: 'messages',
            value: res,
            reset: data && data.reset ? data.reset : false,
          })

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getNewMessages({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chat/' + data.id + '/new-messages',
      cb: async function (res) {
        if (res.success) {
          if (res.messages.length) {
            commit('appendArray', {
              name: 'messages',
              value: res,
              reset: data && data.reset ? data.reset : false,
            })
          }

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createMessage({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/chat/' + data.id + '/message',
      cb: async function (res) {
        if (res.success) {
          commit('appendArray', {
            name: 'message_created',
            value: res,
            handle: 'messages',
            reset: data && data.reset ? data.reset : false,
          })

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async editMessage({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/chat/' + data.chat + '/message/' + data.id,
      cb: async function (res) {
        if (res.success) {
          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteMessage({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/chat/' + data.chat + '/message/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('deleteArray', {
            name: 'messages',
            value: data.id,
          })

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

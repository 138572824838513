<template>
  <div v-if="user" class="user-sidebar">
    <button
      class="show-menu btn--unstyled"
      @click="
        () => {
          showMenu = showMenu ? false : true
        }
      "
    >
      {{ showMenu ? 'Hide' : 'Show' }} Menu
    </button>

    <div
      class="user-sidebar__card"
      :class="{ 'user-sidebar__card--hide': !showMenu }"
    >
      <div class="user-sidebar__block">
        <nuxt-link class="user-sidebar__link" to="/users/profile">
          Your Profile
        </nuxt-link>

        <div class="user-sidebar__sub">
          <a href="/users/profile#about" type="#about" @click="handleClick">
            About Me
          </a>

          <a
            href="/users/profile#interests"
            type="#interests"
            @click="handleClick"
          >
            Following
          </a>

          <a
            href="/users/profile#favorites"
            type="#favorites"
            @click="handleClick"
          >
            Favorites
          </a>

          <a
            href="/users/profile#contributions"
            type="#contributions"
            @click="handleClick"
          >
            Community Contributions
          </a>

          <a href="/users/profile#thought" type="#thought" @click="handleClick">
            Thought Leadership
          </a>
        </div>

        <!-- <nuxt-link
          v-if="!impersonating"
          class="user-sidebar__link"
          to="/users/conversations"
        >
          Conversations
        </nuxt-link> -->

        <nuxt-link
          v-if="!impersonating"
          class="user-sidebar__link"
          to="/users/notifications"
        >
          Notifications
        </nuxt-link>

        <nuxt-link
          class="user-sidebar__link"
          to="/users/settings"
          data-test="sidebar-links-settings"
        >
          Settings
        </nuxt-link>

        <button class="user-sidebar__link btn--unstyled" @click="logout">
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      user: this.$auth.user,
      navType: 'users',
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
      affiliation: 'affiliations/affiliation',
    }),
    curId() {
      return this.navType == 'users'
        ? this.user.id || this.user.default_affiliation_id
        : this.$route.params.slug
    },
    navLinks() {
      return {
        users: [
          {
            url: '/users/profile',
            name: 'Profile',
          },
          {
            url: '/users/conversations',
            name: 'Conversations',
            platform_permission: 'Chat::Filter',
            impersonating: true,
          },
          {
            url: '/users/notifications',
            name: 'Notifications',
            platform_permission: 'Notification::Filter',
            impersonating: true,
          },
          {
            url: '/users/settings',
            name: 'Settings',
            testId: 'sidebar-links-settings',
          },
          {
            url: '/users/settings',
            name: 'Settings',
            testId: 'sidebar-links-settings',
          },
        ],
      }
    },
  },
  watch: {
    $route: function () {
      this.navType = this.$route.path.split('/')[1]
    },
  },
  async created() {
    this.navType = this.$route.path.split('/')[1]
  },
  methods: {
    logout() {
      const app = this

      app.$auth.logout().then(() => {
        if (app.impersonating)
          app.$store.dispatch('authHelpers/setImpersonating', false)
      })
    },
    handleClick(e) {
      e.preventDefault()

      this.$router.push(e.target.getAttribute('type'))
    },
  },
}
</script>

<style lang="scss" scoped>
.user-sidebar {
  width: 100%;
  margin-bottom: 20px;

  @include bp('md') {
    max-width: 350px;
    width: 31%;
    margin-right: 30px;
  }

  h2 {
    max-width: 350px;
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include bp('md') {
      font-size: 30px;
    }
  }
}

.user-sidebar__card {
  border: solid 1px #979797;
  border-radius: 3px;

  a,
  .user-sidebar__link {
    background: transparent;
    display: block;
    padding: 15px 25px;
    cursor: pointer;
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include bp('md') {
      max-width: 350px;
    }

    &:focus,
    &:hover {
      text-decoration: none;
      background: #f2f9fd;
    }
  }
}

.user-sidebar__card--hide {
  display: none;

  @include bp('md') {
    display: block;
  }
}

.user-sidebar__sub {
  display: none;
}

.user-sidebar__link {
  color: #2d6079;
  font-weight: bold;

  &.nuxt-link-active {
    background: #e0f5ff;
    font-weight: bold;

    & + .user-sidebar__sub {
      display: block;
    }

    &:focus,
    &:hover {
      background: #e0f5ff;
    }
  }
}

.user-sidebar__card .user-sidebar__sub {
  a {
    font-size: 20px;
    padding: 10px 0 10px 40px;
    color: #4d4d4d;
  }
}

.show-menu {
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  text-transform: none;
  height: 45px;
  width: auto;

  @include bp('md') {
    display: none;
  }
}
</style>

<template>
  <header class="header" :class="buildModifier(modifier)">
    <div class="container">
      <nuxt-link class="header__logo-link" to="/">
        <img class="header__logo" src="/logos/logo.svg" alt="The Atlas logo" />
      </nuxt-link>

      <ButtonV2
        v-show="!menuIsVisible"
        :onClick="toggleMenuIsVisible"
        variant="solid"
        class="nav-btn__open"
        aria-label="Open navigation"
      >
        Menu
      </ButtonV2>

      <button
        v-show="menuIsVisible"
        type="button"
        class="nav-btn__close"
        aria-label="Close navigation"
        @click="toggleMenuIsVisible"
      >
        <span class="nav-btn__line" />
        <span class="nav-btn__line" />
      </button>

      <nav class="nav" :class="{ 'nav--show': menuIsVisible }">
        <client-only>
          <div v-if="!$auth.loggedIn" class="auth" @click="toggleMenuIsVisible">
            <ButtonV2 to="/users/login" class="auth__login" variant="outline">
              Log In
            </ButtonV2>
            <!-- <ButtonV2
              href="/join"
              class="auth__join"
              :onClick="trackJoin"
              variant="solid"
            >
              Join
            </ButtonV2> -->
          </div>
        </client-only>

        <client-only>
          <div class="links">
            <div @click="toggleMenuIsVisible">
              <nuxt-link
                class="nav__item"
                :class="{ 'nav__item--noborder': !$auth.loggedIn }"
                to="/projects"
                data-test="nav-projects-link"
                @click.native="reloadPage('/projects')"
              >
                Case Studies
              </nuxt-link>

              <a v-if="!$auth.loggedIn" class="nav__item" href="/navigators">
                Community
              </a>

              <a v-if="!$auth.loggedIn" class="nav__item" href="/about">
                About Us
              </a>

              <nuxt-link
                v-if="$auth.loggedIn"
                class="nav__item"
                to="/users/community"
                @click.native="reloadPage('/users/community')"
              >
                Community
              </nuxt-link>
            </div>

            <div class="nav__item nav__item--sub">
              <a href="/resources" class="nav__item nav__item--title">
                Resources
              </a>
            </div>
          </div>
        </client-only>

        <client-only>
          <div
            v-if="$auth.loggedIn"
            class="user-links"
            @click="toggleMenuIsVisible"
          >
            <nuxt-link
              class="user-links__dashboard"
              to="/users/dashboard"
              title="dashboard"
            >
              <svgHome />
              Home
            </nuxt-link>

            <a
              class="user-links__favorites"
              href="/users/profile#following"
              title="favorites"
            >
              <svgStar />

              Favorites
            </a>

            <nuxt-link
              v-if="
                (checkPermission(
                  'platform_permissions',
                  'Notification::Filter',
                  $auth.user
                ) &&
                  !impersonating) ||
                $auth.user.is_internal
              "
              class="user-links__notifications"
              to="/users/notifications"
              title="notifications"
            >
              <svgNotifications />

              <div v-if="notifications" class="user-links__notice">
                <svgNotice />
              </div>

              Notifications
            </nuxt-link>

            <!-- <nuxt-link
              v-if="
                (checkPermission(
                  'platform_permissions',
                  'Chat::Filter',
                  $auth.user
                ) &&
                  !impersonating) ||
                $auth.user.is_internal
              "
              class="user-links__messages"
              to="/users/conversations"
              title="conversations"
            >
              <svgMessages />

              <div v-if="conversations" class="user-links__notice">
                <svgNotice />
              </div>

              Messages
            </nuxt-link> -->

            <nuxt-link
              v-if="
                checkPermission(
                  'platform_permissions',
                  'User::Update',
                  $auth.user
                ) || $auth.user.is_internal
              "
              class="user-links__account"
              :to="'/users/profile'"
              title="account"
              data-test="header-links-account"
            >
              <Avatar
                :imgSrc="$auth.user.default_profile_pic_url"
                :variant="'round'"
              />
            </nuxt-link>

            <div
              v-if="$auth.user.is_internal"
              class="user-links__exit"
              title="logout"
              @click="logout"
            >
              <svgExit />

              Logout
            </div>

            <div class="user-links__spacer" />

            <ButtonV2
              v-if="
                $auth.user.current_affiliation &&
                $auth.user.current_affiliation.name &&
                !$auth.user.current_affiliation.requesting
              "
              variant="outline"
              class="user-links__affiliation"
              :to="
                $auth.user.current_affiliation.affiliation_type !=
                'partner-focused'
                  ? '/affiliations/' +
                    $auth.user.current_affiliation.id +
                    '/projects'
                  : '/affiliations/' +
                    $auth.user.current_affiliation.id +
                    '/overview'
              "
            >
              {{ $auth.user.current_affiliation.name }}
            </ButtonV2>

            <div
              v-if="
                $auth.user.current_affiliation &&
                $auth.user.current_affiliation.name &&
                $auth.user.current_affiliation.requesting
              "
              class="user-links__affiliation user-links__affiliation--disabled"
            >
              {{ $auth.user.current_affiliation.name }}
            </div>

            <nuxt-link
              v-if="$auth.user.is_internal"
              class="user-links__affiliation"
              to="/admin/users"
            >
              Admin
            </nuxt-link>
          </div>
        </client-only>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { get } from 'lodash-es'

import Avatar from '~/components/user/Avatar'
import ButtonV2 from '~/components/ButtonV2'
import svgNotifications from '~/static/icons/notifications-24px.svg'
import svgMessages from '~/static/icons/email-24px.svg'
import svgExit from '~/static/icons/exit_to_app-24px.svg'
import svgNotice from '~/static/icons/oval.svg'
import svgHome from '~/static/icons/home-24px.svg'
import svgStar from '~/static/icons/icons-star-filled.svg'

export default {
  components: {
    svgNotifications,
    svgMessages,
    svgExit,
    svgNotice,
    svgHome,
    svgStar,
    Avatar,
    ButtonV2,
  },
  props: ['modifier'],
  data() {
    return {
      user: this.$auth.user,
      menuIsVisible: false,
      subNavIsVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
      notifications: 'users/notifications',
      conversations: 'users/conversations',
    }),
  },
  watch: {
    $route: function (to, from) {
      // We only want to trigger this on a true page change, not just for query params, etc
      if (to.path === from.path) return

      if (
        this.$auth.loggedIn &&
        this.$auth.user &&
        this.$route.path !== '/users/login'
      ) {
        this.getNotices()
      }
    },
    '$auth.loggedIn': function () {
      // console.log("AUTH LOGGEDIN: " + this.$auth.loggedIn)

      if (!this.$auth.loggedIn) {
        this.stopTimer()
        return
      }

      if (!this.$options.interval) this.startTimer()
    },
  },
  mounted() {
    // Get notifications once when the page loads
    this.getNotices()

    // Setup timer for global tick
    if (this.$auth.loggedIn) this.startTimer()
  },
  beforeDestroy() {
    if (this.$options.interval) this.stopTimer()
  },
  methods: {
    logout() {
      let app = this

      this.$auth.logout().then(() => {
        if (app.impersonating)
          app.$store.dispatch('authHelpers/setImpersonating', false)
      })
    },
    toggleMenuIsVisible() {
      this.menuIsVisible = !this.menuIsVisible
    },
    toggleSubNavIsVisible() {
      this.subNavIsVisible = !this.subNavIsVisible
    },
    buildModifier(modifier) {
      let className = ''
      const route = get(this, '$route.name')

      if (route !== 'media' && route !== 'index') {
        className = 'header--shadow'
      }

      if (modifier) {
        className = `${className} header--${modifier}`
      }

      return className
    },
    getNotices() {
      if (this.$auth.user && this.$auth.loggedIn) {
        this.$store.dispatch('users/getNotices', {
          id: this.$auth.user.id,
        })
      }
    },
    trackJoin() {
      if (!this.$track) return

      this.$track.event({
        segment: {
          action: 'Join Clicked',
          data: {
            from: 'navbar',
          },
        },
      })
    },
    startTimer() {
      let time = 60 * 1000
      let app = this

      app.$options.interval = setTimeout(function () {
        // We only want to continue emitting this event if the user is logged in.
        if (!app.$auth.loggedIn) return

        // If impersonating & an admin - stop the timer
        // Also show modal
        if (app.$auth.user.is_internal && app.$cookies.get('impersonating')) {
          app.$modal.show('oldTabWarning')
          return
        }

        app.$root.$emit('tick')
        app.startTimer()
      }, time)
    },
    stopTimer() {
      clearTimeout(this.$options.interval)
    },
    reloadPage(path) {
      if (window.location.pathname === path) {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  background: #ffffff;
  z-index: 20;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #ffffff;

    @include bp('md') {
      min-height: 68px;
      padding: 0 15px;
    }
  }

  &.header--shadow {
    box-shadow: 0 6px 14px 0 rgba(81, 92, 108, 0.23);
  }

  .auth {
    display: flex;
    width: 100%;
    padding: 0 10px;

    @include bp('md') {
      order: 4;
      width: 184px;
      padding: 10px 0;
      justify-content: flex-end;
    }
  }

  .auth__login,
  .auth__join {
    display: block;
    width: 50%;
    padding: 14px 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4em;
    letter-spacing: 0.5px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    @include bp('md') {
      padding: 12px 10px;
    }

    @include bp('lg') {
      padding: 14px 10px;
    }
  }

  .auth__login {
    margin-right: 10px;

    @include bp('md') {
      border: none;
      color: #000000;
      margin-right: 0;

      &:hover,
      &:focus,
      &:active {
        color: #000000;
      }
    }
  }

  .nav-btn__open,
  .nav-btn__close {
    cursor: pointer;

    @include bp('md') {
      display: none;
    }
  }
}

.header__logo-link {
  display: flex;
  align-items: center;
  width: 130px;
  margin: 0;

  @include bp('sm') {
    width: 150px;
    margin: 15px 20px 15px 0;
  }
}

.header__logo {
  width: 100%;
}

.nav-btn__open {
  padding: 14px 24px;
}

.nav-btn__close {
  position: relative;
  width: 42px;
  height: 42px;
  border: none;
}

.nav-btn__line {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 21px;
  height: 2px;
  margin: -1px 0 0 -10px;
  background-color: #000000;
  transform: rotate(-45deg);

  &:first-child {
    transform: rotate(45deg);
  }
}

.nav {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;

  @include bp('md') {
    display: flex !important;
    position: relative;
    top: initial;
    left: initial;
    justify-content: space-between;
  }

  &.nav--show {
    display: block;
  }
}

.links {
  @include bp('md') {
    display: flex;
  }
}

.nav__item {
  position: relative;
  display: block;
  width: 100%;
  padding: 14px 8px;
  border-top: solid 1px #979797;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;

  &:hover,
  &.nuxt-link-exact-active {
    background-color: #fbfbfb;
    color: #000000;
  }

  &.nav__item--noborder {
    border: none;
  }

  @include bp('md') {
    display: inline-block;
    width: auto;
    border: none;
    padding: 24px 8px;

    &:hover,
    &:focus {
      .nav__sub-menu {
        display: block;
      }
    }
  }

  @include bp('lg') {
    padding: 24px 14px;
    font-size: 16px;
  }
}

.nav__item--button {
  padding: 0;

  @include bp('md') {
    padding: 12px 14px;
  }
}

.nav__item--sub {
  padding: 0;
  border: none;

  @include bp('md') {
    &:focus-within,
    &:hover {
      .nav__sub-menu {
        display: block;
      }
    }
  }
}

.nav__item--title {
  padding: 14px 8px;
  text-align: left;
  border: none;
  border-top: solid 1px #979797;
  border-bottom: solid 1px #979797;

  @include bp('md') {
    padding: 24px 8px;
    border: none;
  }
}

.nav__item-arrow {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 7px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #000000;
  pointer-events: none;

  @include bp('lg') {
    position: relative;
    top: -2px;
  }
}

.nav__sub-menu {
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;

  &.nav__sub-menu--show {
    display: block;
  }

  > a {
    display: block;
    width: 100%;
    padding: 14px 8px 14px 28px;
    border-top: solid 1px #979797;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;

    &:first-child {
      border: none;
    }

    &:last-child {
      border-bottom: solid 1px #979797;
    }
  }

  @include bp('md') {
    position: absolute;
    top: 100%;
    left: 0;
    width: 226px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0 0 4px 4px;

    > a {
      padding: 14px 8px;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &.nav__sub-menu--show {
      display: block;
    }
  }

  @include bp('lg') {
    > a {
      font-size: 16px;
    }
  }
}

.user-links {
  display: flex;
  padding: 5px 0;
  align-items: center;
  font-size: 10px;
  flex-wrap: wrap;

  @include bp('md') {
    padding: 0;
    flex-wrap: nowrap;
  }

  > div,
  > a {
    padding: 5px;
    cursor: pointer;
  }
}

.user-links .user-links__favorites {
  svg {
    width: 16px;
    height: 16px;
    margin: 4px auto;
  }
}

.user-links__account {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.user-links__user-image {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 2px;
  left: 2px;
  background-position: 50%;
  background-size: cover;
  border-radius: 50%;

  background-color: #fff;
}

.user-links .user-links__spacer {
  width: 1px;
  height: 70%;
  margin: 0 15px 0 10px;
  padding: 0;
  background: #ababab;
  flex-grow: 0;
}

.user-links .user-links__affiliation {
  width: 100%;
  margin: 5px;
  padding: 10px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  @include bp('md') {
    width: 170px;
    margin: 0;
  }
}

.user-links__affiliation--disabled {
  cursor: initial !important;
}

.user-links svg {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

.add-project--disabled {
  opacity: 0.5;

  &:hover,
  &:focus {
    background: $primary !important;
  }
}

.user-links__notifications,
.user-links__messages {
  position: relative;
}

.user-links__notice {
  position: absolute;
  top: 3px;
  right: 18px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.user-links__messages .user-links__notice {
  right: 11px;
}

#header.header--fullwidth {
  z-index: 101;

  .container {
    max-width: none;
  }

  .links {
    @include bp('lg') {
      padding-left: 50px;
    }
  }

  .user-links__affiliation {
    width: auto;
    max-width: 170px;

    @include bp('lg') {
      width: 170px;
    }
  }

  .logo {
    width: 94px;
    height: 31px;
    background-image: url('/atlas-logo.svg');
    background-size: 94px;
    background-repeat: no-repeat;
    background-position: center;

    @include bp('md') {
      background-size: 118px;
      width: 220px;
      height: 38px;
      margin: auto;
    }
  }

  .nav {
    max-width: 1440px;
    margin: auto;
  }
}
</style>

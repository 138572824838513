<template>
  <div class="add-link" @click="add(type)">
    <div class="icon">
      <svgAdd />
    </div>

    <div class="add">Add {{ text }}</div>
  </div>
</template>

<script>
import svgAdd from '~/static/icons/round-add-24px.svg'

export default {
  components: {
    svgAdd,
  },
  props: ['role', 'type', 'text', 'cb'],
  methods: {
    add(type) {
      this.cb(type, this.role)
    },
  },
}
</script>

<style lang="scss">
.add-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;

  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
</style>

import Vue from 'vue'
import openPopup from '@/utils/openPopup'

const state = () => ({
  users: {
    items: [],
    pagination: [],
  },
  user: null,
  tags: [],
  uploadStatus: '',
  suggestedTags: [],
  suggestedLocation: [],
  suggestedMembers: [],
  newMembers: [],
  notifications: 0,
  conversations: 0,
  requesting: {
    items: [],
    pagination: [],
  },
  queueItems: [],
  location: null,
})

const getters = {
  users: ({ users }) => users,
  user: ({ user }) => user,
  tags: ({ tags }) => tags,
  uploadStatus: ({ uploadStatus }) => uploadStatus,
  suggestedTags: ({ suggestedTags }) => suggestedTags,
  suggestedLocation: ({ suggestedLocation }) => suggestedLocation,
  suggestedMembers: ({ suggestedMembers }) => suggestedMembers,
  newMembers: ({ newMembers }) => newMembers,
  requesting: ({ requesting }) => requesting,
  queueItems: ({ queueItems }) => queueItems,
  notifications: ({ notifications }) => notifications,
  conversations: ({ conversations }) => conversations,
  location: ({ location }) => location,
}

const linkedinStorageKey = 'oauth-linkedin'

/**
 * Given a linkedin oauth window that we opened, determine if we're done, and if we encountered errors along the way.
 * Our callback (final url that we provide linkedin, as part of the auth flow), will keep a localstorage key updated with the status.
 * The code for the callback lives on the WP repo.
 */
const getLinkedinState = function (openedWindow) {
  if (openedWindow.closed) {
    const oauthResult = localStorage[linkedinStorageKey]
    const done = true

    switch (oauthResult) {
      case 'user-fetched':
        // We're good
        return {
          done,
          success: true,
        }
      // Following cases are error states & details
      case 'error-user':
      // not able to fetch profile, but did authenticate (possibly not enough permissions? Server error)
      case 'authenticated':
      // user is authenticated but profile was not fetched
      case undefined:
      // never hit the callback, so ...this is bad too
      default:
        return {
          done,
          success: false,
        }
    }
  }

  return {
    done: false,
  }
}

/**
 * Check the window we opened until the process is done.
 */
// TODO: What if they never close / continue?
const watchLinkedinState = (windowToWatch) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const linkedinState = getLinkedinState(windowToWatch)
      const { done, success } = linkedinState
      if (done) {
        clearInterval(interval)
        if (success) {
          resolve('complete')
        } else {
          reject(new Error('Something went wrong when connecting to Linkedin.'))
        }
      }
      // Keep on waiting
    }, 500)
  })
}

const mutations = {
  setPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination
    state[name].items = data.value[data.type][data.name]
  },
  setValue(state, data) {
    if (data.path) {
      let target = state[data.name]

      Vue.set(state[data.name], data.path[data.path.length - 1], data.value)

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadUsers({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.filters ? data.filters : {},
      path: '/users/filter' + (data.page ? '?page=' + data.page : ''),
      headers: data.headers ? data.headers : {},
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'users',
            value: res,
            reset: data.reset ? true : false,
            type: 'users_paginated',
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadUser({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/user/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'user',
            value: res.user,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadTags({ commit }, tagsUrl) {
    let tags = []

    let response1 = await this.$axios.get('/tag-group/' + 4)
    tags.push(response1.data.tag_group)
    let response2 = await this.$axios.get('/tag-group/' + 5)
    tags.push(response2.data.tag_group)
    let response3 = await this.$axios.get('/tag-group/' + 6)
    tags.push(response3.data.tag_group)
    let response4 = await this.$axios.get('/tag-group/' + 7)
    tags.push(response4.data.tag_group)
    let response5 = await this.$axios.get('/tag-group/' + 1)
    tags.push(response5.data.tag_group)

    commit('setValue', {
      name: 'tags',
      value: tags,
    })
  },
  async loadRequesting({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.filters ? data.filters : {},
      path: '/users/filter',
      headers: data.headers ? data.headers : {},
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'users',
            value: res,
            type: 'users_paginated',
            handle: 'requesting',
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateUser({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/user/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'user',
            value: res.user,
          })

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteUser({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/user/' + data.id,
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addAttachment({ commit, state }, data) {
    commit('setValue', {
      name: 'uploadStatus',
      value: 'Loading...',
    })

    let requestData = {
      method: 'post',
      data: data.data,
      path: '/attachment',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'uploadStatus',
            value: 'Completed!',
          })

          commit('setValue', {
            name: 'user',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        } else {
          commit('setValue', {
            name: 'uploadStatus',
            value:
              res.errors && res.errors.file && res.errors.file.length
                ? res.errors.file[0]
                : res.message,
          })

          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadQueue({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/queue-items',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'queueItems',
            value: res.queue_items,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async connectLinkedin({ commit }, data) {
    localStorage[linkedinStorageKey] = null

    // const token = this.getters['auth/token']

    // Our api gives us a unique linkedin oauth url to hit every time
    const linkedinResponse = await this.$axios({
      method: 'get',
      // headers: {
      //   'Content-Type': 'application/json',
      //   'Authorization': `Bearer ${token}`,
      // },
      url: '/oauth/linkedin/authorization/',
    })

    const linkedinOauthUrl = linkedinResponse.data.url

    const oauthWindow = openPopup(linkedinOauthUrl)

    try {
      // Promise that finishes when flow is complete
      await watchLinkedinState(oauthWindow)
      return {
        success: true,
      }
    } catch (error) {
      // Return error and message through module, to be used in next step by caller.
      // console.error(error)
      return {
        success: false,
        error,
      }
    }
  },

  async getNotices({ commit }, data) {
    // console.log("GET NOTICES")
    const app = this

    let notificationData = {
      method: 'get',
      data: {},
      path: '/user/' + data.id + '/notifications-unread',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'notifications',
            value: res.notifications_unread,
          })

          if (data.cb) data.cb()
        } else {
          // console.log("ERROR")
          // console.log(res)
          // console.log(res.error)
          // console.log(res.error.name)
          // console.log(app)
          if (
            res.error === 'Unauthenticated' ||
            res.error.name === 'ExpiredAuthSessionError'
          ) {
            // console.log("CALL LOGOUT")
            app.$auth.logout()
          }
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', notificationData)

    if (!app.$auth.loggedIn) return

    let conversationData = {
      method: 'get',
      data: {},
      path: '/user/' + data.id + '/conversations-unread',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'conversations',
            value: res.conversations_unread,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', conversationData)
  },
  async chatsRead({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        read: 'all',
      },
      path: '/user/' + data.id + '/conversations-read',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'conversations',
            value: res.conversations_unread,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  // updateFollow ({ commit }, data) {
  //   commit('updateFollow', data)
  // },
  async loadSuggestedTags({ commit }, data) {
    const resultsNum = data.resultsNum ? data.resultsNum : 3
    let requestData = {
      method: 'post',
      data: {
        suggested: data.id,
        suggested_type: 'similar_tags',
        roles: [
          {
            value: 'city-partner',
          },
          {
            value: 'implementing-partner',
          },
          {
            value: 'stakeholder',
          },
        ],
      },
      path: '/users/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'suggestedTags',
            value: res.users_paginated.users.splice(0, resultsNum),
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadSuggestedLocation({ commit }, data) {
    const resultsNum = data.resultsNum ? data.resultsNum : 3
    let requestData = {
      method: 'post',
      data: {
        suggested: data.id,
        suggested_type: 'similar_location',
        roles: [
          {
            value: 'city-partner',
          },
          {
            value: 'implementing-partner',
          },
          {
            value: 'stakeholder',
          },
        ],
      },
      path: '/users/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'suggestedLocation',
            value: res.users_paginated.users.splice(0, resultsNum),
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadSuggestedMembers({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        suggested: data.id,
        suggested_type: 'role_match',
      },
      path: '/users/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'suggestedMembers',
            value: res.users_paginated.users.splice(0, 3),
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadNewMembers({ commit }, data) {
    let requestData = {
      method: 'post',
      path: '/users/filter',
      data: {
        roles: [
          {
            value: 'city-partner',
          },
        ],
        recent_joins: true,
        global_cache: true,
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'newMembers',
            value: res.users_paginated.users.splice(0, 5),
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async recoveryRequest({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/forgot-password/',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async resetRequest({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/reset-password',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createUser({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/user',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getLocation({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path:
        'https://pro.ip-api.com/json/' +
        (data && data.ip ? data.ip : '') +
        '?key=' +
        process.env.PROIP_KEY,
      cb: async function (res) {
        if (res && res.country) {
          commit('setValue', {
            name: 'location',
            value: {
              country: res.country,
              countryCode: res.countryCode,
              region: res.region,
              regionName: res.regionName,
              city: res.city,
              zip: res.zip,
              isp: res.isp,
              as: res.as,
              org: res.org,
            },
          })
        } else {
          // console.log('GET LOCATION')
          // console.log(data)
          // console.log(
          //   'https://pro.ip-api.com/json/' +
          //     (data && data.ip ? data.ip : '') +
          //     '?key=' +
          //     process.env.PROIP_KEY
          // )
          // console.log('RESPONSE')
          // console.log(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <modal
    name="project__image-view"
    class="project__image-view"
    height="auto"
    :width="550"
    :adaptive="true"
    data-test="project-image-modal"
    @before-open="beforeOpen"
  >
    <img :src="modalImage" />
  </modal>
</template>

<script>
export default {
  data() {
    return {
      modalImage: '',
    }
  },
  methods: {
    beforeOpen(event) {
      this.modalImage = event.params.modalImage
    },
  },
}
</script>

<style lang="scss" scoped>
.project__image-view {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  /deep/ .v--modal {
    padding: 0;
  }
}
</style>

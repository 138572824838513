<template>
  <div v-if="user" class="sidebar" :class="{ 'sidebar--open': open }">
    <div class="sidebar__container">
      <nav class="sidebar__content">
        <div class="sidebar__nav-title">
          <Avatar
            v-if="showAvatar()"
            class="sidebar__nav-title-avatar"
            :size="20"
            :imgSrc="sidebarIcon"
          />
          {{ navTitle }}
        </div>
        <div v-for="link in navLinks[navType]" :key="link.testId">
          <nuxt-link
            v-if="showLink(link)"
            class="sidebar__link"
            :to="link.url"
            :data-test="link.testId"
          >
            <component :is="link.icon" class="sidebar__link-icon" />
            {{ link.name }}
          </nuxt-link>
        </div>
      </nav>
    </div>

    <button
      class="btn--unstyled sidebar__toggle"
      aria-label="toggle sidebar panel"
      :aria-checked="open"
      @click="toggleOpen"
    >
      <component
        :is="open ? openSvg : closedSvg"
        class="sidebar__toggle-icon"
      />
      <span class="sidebar__toggle-span">{{ navTitle }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, get } from 'lodash-es'

import svgUsers from '~/static/icons/round-group-24px.svg'
import svgAffiliations from '~/static/icons/round-location_city-24px.svg'
import svgInsights from '~/static/icons/trending_up-24px.svg'
import svgCaseStudies from '~/static/icons/add-post-24px.svg'
import svgMenu from '~/static/icons/round-menu-42px.svg'
import svgClose from '~/static/icons/round-close-42px.svg'

import Avatar from '~/components/user/Avatar'

export default {
  components: {
    svgMenu,
    svgClose,
    Avatar,
  },
  data() {
    return {
      user: this.$auth.user,
      navType: 'affiliation',
      open: false,
      openSvg: svgClose,
      closedSvg: svgMenu,
    }
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
      affiliation: 'affiliations/affiliation',
    }),
    navTitle() {
      let title = ''
      switch (this.navType) {
        case 'affiliations':
          if (this.user.current_affiliation.name) {
            title = this.user.current_affiliation.name
          } else if (this.affiliation) {
            title = this.affiliation.name
          }
          break
        case 'admin':
          title = 'ADMIN'
          break
      }
      return title
    },
    sidebarIcon() {
      return this.user.current_affiliation.default_logo_url
        ? this.user.current_affiliation.default_logo_url
        : ''
    },
    curId() {
      const { affiliationId, slug } = this.$route.params
      return affiliationId || slug
    },
    navLinks() {
      return {
        affiliations: [
          {
            url: '/affiliations/' + this.curId + '/overview',
            name: 'Overview',
            affiliation_type: 'partner-focused',
            icon: svgAffiliations,
          },
          {
            url: '/affiliations/' + this.curId + '/projects',
            name: 'Case Studies',
            affiliation_type: null,
            platform_permission: 'Project::View',
            current_affiliation_permissions: 'Project::Manage',
            icon: svgCaseStudies,
          },
          {
            url: '/affiliations/' + this.curId + '/insights',
            name: 'Insights',
            affiliation_type: null,
            platform_permission: 'Insight::View',
            current_affiliation_permissions: 'Insight::View',
            icon: svgInsights,
          },
          {
            url: '/affiliations/' + this.curId + '/team',
            name: 'Team',
            affiliation_type: 'city-focused',
            icon: svgUsers,
          },
        ],
        admin: [
          {
            url: '/admin/users',
            name: 'Users',
            platform_permission: 'User::Search',
            icon: svgUsers,
          },
          {
            url: '/admin/projects',
            name: 'Case Studies',
            platform_permission: 'Project::Search',
            icon: svgCaseStudies,
          },
          {
            url: '/admin/affiliations',
            name: 'Affiliations',
            platform_permission: 'Affiliation::Search',
            icon: svgAffiliations,
          },
          {
            url: '/admin/insights',
            name: 'Insights',
            platform_permission: 'Affiliation::Search',
            icon: svgInsights,
          },
        ],
      }
    },
  },
  watch: {
    $route: function () {
      this.navType =
        this.user && this.user.is_internal
          ? 'admin'
          : this.$route.path.split('/')[1]
    },
  },
  async created() {
    this.navType =
      this.user && this.user.is_internal
        ? 'admin'
        : this.$route.path.split('/')[1]

    if (
      this.navType === 'affiliations' &&
      !this.user.current_affiliation.name
    ) {
      await this.$store.dispatch('affiliations/loadAffiliation', {
        id: this.$route.params.slug,
      })
    }
  },
  methods: {
    toggleOpen: debounce(function () {
      this.open = !this.open
    }, 300),
    showLink(link) {
      return link.affiliation_type
        ? link.affiliation_type ===
            this.user.current_affiliation.affiliation_type
        : true
    },
    showAvatar() {
      // only show the avatar if:
      // 1. they're not a city official (who cannot set/change this image)
      // 2. there's an image available
      const affiliationType = get(
        this,
        'user.current_affiliation.affiliation_type'
      )
      return affiliationType !== 'city-focused' && this.sidebarIcon
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: absolute;
  left: 0;
  z-index: 1;
  display: flex;

  transition: 300ms left;

  .sidebar__container {
    display: none;
  }

  // Set min-height and display: flex if it's either open, or on desktop
  &.sidebar--open {
    min-height: 100%;
    left: 0;

    .sidebar__toggle {
      box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
      border-radius: 0 3px 3px 0;
    }

    .sidebar__container {
      display: flex;
    }
  }

  @include bp('sm') {
    position: relative;
    left: 0;
    min-height: 100%;

    .sidebar__container {
      display: flex;
    }
  }
}

.sidebar__toggle {
  background: #ffffff;
  height: 52px;
  padding: 10px;
  align-items: center;
  min-width: 100px;
  max-width: 190px;
  margin: 20px 0;
  border-radius: 0 3px 3px 0;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
  display: flex;

  @include bp('sm') {
    display: none;
  }
}

.sidebar__toggle-span {
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar__toggle-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.sidebar__container {
  font-size: 12px;
  letter-spacing: 0.5px;
  overflow: hidden;
  display: flex;
  background: #50505d;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
  width: 110px;
  flex-shrink: 0;

  @include bp('lg') {
    width: 220px;
    font-size: 14px;
  }
}

.sidebar__content {
  width: 100%;
}

.sidebar__nav-title-avatar {
  margin: 0 0 5px 0;

  @include bp('lg') {
    margin: 0 10px 0 0;
  }
}

.sidebar__nav-title {
  padding: 10px;
  background: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 91px;
  font-size: 14px;

  @include bp('lg') {
    flex-direction: row;
    padding: 22px 20px;
    justify-content: left;
  }
}

.sidebar__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  white-space: nowrap;

  &:focus,
  &:hover,
  &.nuxt-link-exact-active {
    color: #ffffff;
    background: #28282e;
    text-decoration: none;
  }

  @include bp('lg') {
    flex-direction: row;
    padding: 20px;
  }
}

.sidebar__link-icon {
  fill: #ffffff;
  width: 24px;
  margin: 0 0 10px 0;

  @include bp('sm') {
    margin: 0 10px 0 0;
  }
}
</style>

import Vue from 'vue'

const state = () => ({
  users: {
    items: [],
    pagination: [],
  },
  cities: {
    items: [],
    pagination: [],
  },
  partners: {
    items: [],
    pagination: [],
  },
  affiliations: {
    items: [],
    pagination: [],
  },
  projects: {
    items: [],
    pagination: [],
  },
  tags: [],
})

const getters = {
  users: ({ users }) => users,
  cities: ({ cities }) => cities,
  partners: ({ partners }) => partners,
  affiliations: ({ affiliations }) => affiliations,
  projects: ({ projects }) => projects,
  tags: ({ tags }) => tags,
}

const mutations = {
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
  setValue(state, data) {
    if (data.path) {
      let target = state[data.name]

      data.path.forEach(function (step) {
        target = target[step]
      })

      target = data.value

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadUsers({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        favoriteable_type: 'users',
        filter: 'city-stakeholder-partner',
      },
      path: '/favorites/filter' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'favorites',
            value: res,
            reset: data.reset ? true : false,
            type: 'favorites_paginated',
            handle: 'users',
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAffiliations({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        favoriteable_type: 'affiliations',
        filter: 'companies',
      },
      path: '/favorites/filter' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'favorites',
            value: res,
            reset: data.reset ? true : false,
            type: 'favorites_paginated',
            handle: 'affiliations',
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadProjects({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        favoriteable_type: 'projects',
      },
      path: '/favorites/filter' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'favorites',
            value: res,
            reset: data.reset ? true : false,
            type: 'favorites_paginated',
            handle: 'projects',
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadTags({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        favoriteable_type: 'tags',
        filter: 'tag-group-1',
      },
      path: '/favorites/filter' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'tags',
            value: res.favorites,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addFavorite({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/favorite',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteFavorite({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: data.data,
      path: '/favorite',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

const state = () => ({
  partnerships: [],
  interests: {},
  collaborators: [],
})

const getters = {
  partnerships: ({ partnerships }) => partnerships,
  interests: ({ interests }) => interests,
  collaborators: ({ collaborators }) => collaborators,
}

const mutations = {
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  async loadPartnerships({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/groups/joinable-partnerships',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'partnerships',
            value: res.joinable_partnerships,
          })

          if (data && data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadInterests({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/user/' + data.id + '/subscribe-settings',
      headers: {
        Format: 'category_interest_digests',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'interests',
            value: res.subscribe_settings,
          })

          if (data && data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadCollaborators({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/groups/typeahead',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'collaborators',
            value: res.affiliation_collaboratables,
          })

          if (data && data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createLead({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/lead',
      cb: async function (res) {
        if (res.success) {
          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <modal
    class="badges scrollable"
    name="profileBadges"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__title">Achievements</div>

      <div class="modal__content">
        <p
          v-if="
            $route.path === '/users/profile' ||
            $route.path === '/users/profile/'
          "
          class="modal__intro"
        >
          Standout to your colleagues by earning badges for your profile. Want
          to unlock more? Engage more!
        </p>

        <p
          v-if="
            $route.path !== '/users/profile' &&
            $route.path !== '/users/profile/'
          "
          class="modal__intro"
        >
          All members of The Atlas can earn the achievement badges shown below.
          Want to see your own?
          <a class="modal__link" href="/users/profile"
            >Go to your member profile</a
          >.
        </p>

        <div
          v-if="
            user &&
            user.profile_badges &&
            user.profile_badges.earned_unearned_limited
          "
          class="badges"
        >
          <div
            v-for="badge in user.profile_badges.earned_unearned_limited"
            :key="`badge-${badge.id}`"
            class="badges__badge"
          >
            <div
              class="badges__image"
              :class="{
                'badges__image--inactive':
                  !badge.earned && user.profile_badges.earned_count,
              }"
              :style="{
                'background-image': 'url(\'' + badge.image_url + '\')',
              }"
            />

            <div class="badges__info">
              <div class="badges__title">
                {{ badge.name }}
              </div>
              <div class="badges__text" v-html="badge.description_html" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal__buttons">
        <div class="btn" @click="$modal.hide('profileBadges')">Done</div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileBadges')
    },
  },
  methods: {
    beforeOpen(event) {},
    validateBeforeSubmit() {},
  },
}
</script>

<style lang="scss" scoped>
.badges__badge {
  display: flex;

  + .badges__badge {
    margin-top: 20px;
  }
}

.modal__content .modal__intro {
  margin-bottom: 30px;
}

.badges__image {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  background-size: cover;
  flex-grow: 0;
  flex-shrink: 0;
}

.badges__image--inactive {
  filter: grayscale(100%);
  opacity: 0.5;
}

.badges__title {
  margin-bottom: 10px;
  font-size: 18px;
}

.badges__text {
  /deep/ a {
    text-decoration: underline;
  }
}

.modal__link {
  text-decoration: underline;
}
</style>

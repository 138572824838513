<template>
  <modal
    name="cohortSelect"
    :clickToClose="false"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form>
      <div class="modal__title">Cohort</div>

      <div class="modal__content">
        <Dropdown
          v-for="prop in cohorts"
          :key="prop.prop"
          v-model="curValues[prop.prop]"
          v-validate="'required'"
          :options="prop.options"
          trackBy="name"
          optionLabel="name"
          placeholder="Select option"
          :name="prop.prop"
          :error="errors.first('cohorts.' + prop.prop)"
          :label="{
            text: prop.display,
          }"
          @input="updateValue($event, prop.prop)"
        />
      </div>

      <div class="modal__buttons">
        <div class="btn" @click="updateCohort">OK</div>

        <div class="btn" @click="$modal.hide('cohortSelect')">Cancel</div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Dropdown from '~/components/form/Dropdown'

export default {
  components: {
    Dropdown,
  },
  inject: ['$validator'],
  data() {
    return {
      userId: null,
      curValues: {},
    }
  },
  computed: {
    ...mapGetters({
      cohorts: 'cohorts/cohorts',
    }),
  },
  methods: {
    beforeOpen(event) {
      let app = this

      this.userId = event.params.id

      this.$store.dispatch('cohorts/loadCohorts', {
        id: this.userId,
        cb: function () {
          app.cohorts.forEach(function (prop) {
            app.curValues[prop.prop] = prop.selected ? prop.selected : null
          })
        },
      })
    },
    updateValue(value, prop) {
      // Refresh view
      this.$forceUpdate()
    },
    async updateCohort() {
      this.$validator.validateAll('cohorts').then((x) => {
        if (x) {
          let app = this
          let submitData = {
            cohort_form: [],
          }

          this.cohorts.forEach(function (prop) {
            let curProp = {
              id: prop.id,
              prop: prop.prop,
              display: prop.display,
              selected: app.curValues[prop.prop],
              options: prop.options,
            }

            submitData.cohort_form.push(curProp)
          })

          this.$store.dispatch('cohorts/updateCohort', {
            id: this.userId,
            data: submitData,
          })

          this.$modal.hide('cohortSelect')
        }
      })
    },
    beforeDestroy() {
      this.$store.dispatch('cohorts/setValue', {
        name: 'cohorts',
        value: null,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}

.dropdown:nth-child(1) {
  z-index: 9;
}

.dropdown:nth-child(2) {
  z-index: 8;
}

.dropdown:nth-child(3) {
  z-index: 7;
}

.dropdown:nth-child(4) {
  z-index: 6;
}
</style>

<template>
  <modal
    data-test="join-prompt-modal"
    name="joinPrompt"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form>
      <div class="modal__title">
        You need to be logged in to access this feature.
      </div>

      <div class="modal__content">
        <p>
          Consider joining if you haven't already! It's completely, totally, and
          always free for everyone who works for local government.
        </p>
      </div>

      <div class="modal__buttons">
        <div class="btn btn--blank" @click="$modal.hide('joinPrompt')">
          Cancel
        </div>

        <nuxt-link to="/users/login" class="btn btn--inverse">
          Login
        </nuxt-link>

        <a href="/join" class="btn" @click="trackJoin"> Join </a>
      </div>
    </form>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      type: '',
      action: '',
    }
  },
  watch: {
    $route: function () {
      this.$modal.hide('joinPrompt')
    },
  },
  methods: {
    beforeOpen(event) {
      if (event.params) {
        if (event.params.type) this.type = event.params.type
        if (event.params.action) this.action = event.params.action
      }
    },
    trackJoin() {
      if (!this.$track) return

      this.$track.event({
        segment: {
          action: 'Join Clicked',
          data: {
            from: 'faux_' + this.action + '_' + this.type,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.btn--blank {
  background: none;
  color: #50505d;

  &:focus,
  &:hover {
    background: none;
    color: #50505d !important;
  }
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}
</style>

import Vue from 'vue'

const state = () => ({
  settings: [],
})

const getters = {
  settings: ({ settings }) => settings,
}

const mutations = {
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  async getSettings({ commit }, data) {
    let requestData = {
      method: 'get',
      data: data.data,
      path: '/user/' + data.id + '/subscribe-settings',
      headers: {
        Format: 'grouped',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'settings',
            value: res.subscribe_settings_grouped,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async setIndividualSettingAsync({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/user/' + data.id + '/subscribe-setting/' + data.key,
    }

    return this.dispatch('externalRequestAsync', requestData)
  },
  async setSettings({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/user/' + data.id + '/subscribe-settings',
      headers: {
        Format: 'grouped',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'settings',
            value: res.subscribe_settings_grouped,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateUser({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/user/' + data.id,
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

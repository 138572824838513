const state = () => ({
  impersonating: false,
  lastActivity: null,
  // lastRoute: null,
})

const getters = {
  lastActivity: ({ lastActivity }) => lastActivity,
  impersonating: ({ impersonating }) => impersonating,
}

const mutations = {
  setLastActivity(state) {
    state.lastActivity = new Date().getTime()
  },
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  switchUser({ commit }, data) {
    const app = this

    let requestData = {
      data: data.data,
      path: '/switch-user',
      cb: async function (res) {
        if (res.success) {
          await app.$auth.setUserToken(res.token)

          app.$auth.$storage.setUniversal(
            '_refresh_token.local',
            res.token,
            false
          )

          app.dispatch('authHelpers/setImpersonating', true)

          app.$router.push({ path: '/users/dashboard' })
        } else {
          // console.log("FAILURE")
        }
      },
      method: 'post',
    }

    app.dispatch('externalRequest', requestData)
  },
  accessCode({ commit }, data) {
    const app = this

    let requestData = {
      data: data.data,
      path: '/access',
      cb: data.cb,
      method: 'post',
    }

    app.dispatch('externalRequest', requestData)
  },
  setImpersonating({ commit }, value) {
    commit('setValue', {
      name: 'impersonating',
      value: value,
    })

    this.$cookies.set('impersonating', value, {
      path: '/',
      maxAge: 60 * 60 * 24 * 1,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"following scrollable",attrs:{"name":"profileFollowing","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('div',{staticClass:"modal__content modal__content--scroll"},[_c('div',{staticClass:"modal__title"},[_vm._v("Following")]),_vm._v(" "),_c('div',{staticClass:"favorite__title",on:{"click":() => {
            _vm.showPeople = _vm.showPeople ? false : true
          }}},[_vm._v("\n        People\n        "),_c('div',{staticClass:"favorite__caret"})]),_vm._v(" "),_c('p',[_vm._v("\n        Grow your local government network.\n        "),_c('a',{staticClass:"favorite__link",attrs:{"href":"/users/community"}},[_vm._v("Follow members now")]),_vm._v(".\n      ")]),_vm._v(" "),(_vm.showPeople)?_c('div',{staticClass:"favorite__content"},[(_vm.users && _vm.users.items.length)?_c('ul',{staticClass:"favorite__list favorite__list--users"},_vm._l((_vm.users.items),function(user){return _c('li',{key:user.id,staticClass:"user",attrs:{"data-test":"following-modal-user"}},[_c('div',{staticClass:"user__data"},[_c('nuxt-link',{attrs:{"to":'/users/' + user.slug}},[_c('Avatar',{style:({ 'margin-right': '10px' }),attrs:{"imgSrc":user.default_profile_pic_url,"userName":user.name}})],1),_vm._v(" "),_c('nuxt-link',{staticClass:"user__info",attrs:{"to":'/users/' + user.slug}},[(user.name)?_c('div',{staticClass:"user__name"},[_vm._v("\n                  "+_vm._s(user.name)+"\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"user__meta"},[_c('UserTitle',{attrs:{"user":user,"disableAffiliationLink":""}})],1)])],1),_vm._v(" "),_c('FavoriteInline',{attrs:{"active":true,"data":{
                data: {
                  favoriteable_id: user.id,
                  favoriteable_type: 'users',
                },
              }}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.users.pagination.current_page !== _vm.users.pagination.last_page)?_c('div',{staticClass:"favorites__show-more",on:{"click":_vm.loadUsers}},[_vm._v("\n          Show More\n        ")]):_vm._e(),_vm._v(" "),(!_vm.users || !_vm.users.items.length)?_c('p',[_vm._v("\n          You’re not following anyone yet.\n          "),_c('br'),_vm._v("\n          Head to the\n          "),_c('nuxt-link',{staticClass:"modal__link",attrs:{"to":"/users/community"}},[_vm._v("Browse Users")]),_vm._v("\n          page to find colleagues to follow.\n        ")],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"favorite__title",on:{"click":() => {
            _vm.showAffiliations = _vm.showAffiliations ? false : true
          }}},[_vm._v("\n        Companies\n        "),_c('div',{staticClass:"favorite__caret"})]),_vm._v(" "),_c('p',[_vm._v("\n        Keep tabs on local government's proven private sector partners.\n        "),_c('a',{staticClass:"favorite__link",attrs:{"href":"/company-partners"}},[_vm._v("Follow companies now")]),_vm._v(".\n      ")]),_vm._v(" "),(_vm.showAffiliations)?_c('div',{staticClass:"favorite__content"},[(_vm.affiliations && _vm.affiliations.items.length)?_c('ul',{staticClass:"favorite__list favorite__list--affiliations"},_vm._l((_vm.affiliations.items),function(affiliation){return _c('li',{key:`following-affiliation-${affiliation.id}`,staticClass:"affiliation"},[_c('div',{staticClass:"affiliation__data"},[_c('nuxt-link',{staticClass:"affiliation__image",class:{
                  'affiliation__image--empty': !affiliation.default_logo_url,
                },style:([
                  affiliation.default_logo_url
                    ? {
                        'background-image':
                          'url(\'' + affiliation.default_logo_url + '\')',
                      }
                    : '',
                ]),attrs:{"to":'/company-partners/' + affiliation.slug}}),_vm._v(" "),_c('nuxt-link',{staticClass:"affiliation__info",attrs:{"to":'/company-partners/' + affiliation.slug}},[(affiliation.name)?_c('div',{staticClass:"affiliation__name"},[_vm._v("\n                  "+_vm._s(affiliation.name)+"\n                ")]):_vm._e()])],1),_vm._v(" "),_c('FavoriteInline',{attrs:{"active":true,"data":{
                data: {
                  favoriteable_id: affiliation.id,
                  favoriteable_type: 'affiliations',
                },
              }}})],1)}),0):_vm._e(),_vm._v(" "),(
            _vm.affiliations.pagination.current_page !=
            _vm.affiliations.pagination.last_page
          )?_c('div',{staticClass:"favorites__show-more",on:{"click":_vm.loadAffiliations}},[_vm._v("\n          Show More\n        ")]):_vm._e(),_vm._v(" "),(!_vm.affiliations || !_vm.affiliations.items.length)?_c('p',[_vm._v("\n          You’re not following any companies yet.\n          "),_c('br'),_vm._v("\n          You can head to the\n          "),_c('nuxt-link',{staticClass:"modal__link",attrs:{"to":"/company-partners"}},[_vm._v("Browse Partners")]),_vm._v("\n          page to find some to follow.\n        ")],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn btn--clear",on:{"click":function($event){return _vm.$modal.hide('profileFollowing')}}},[_vm._v("\n          Cancel\n        ")]),_vm._v(" "),_c('Submit',{attrs:{"text":"Save","loading":_vm.loading}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
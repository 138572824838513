<template>
  <modal
    data-test="participants-modal"
    name="participants"
    class="scrollable"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="modal__title">
      {{ title }}
    </div>

    <div class="modal__content">
      <div v-if="participants && participants.length">
        <UserSuggested
          v-for="item in participants"
          :key="item.id"
          :user="item"
          :trackClick="trackUsers"
        />
      </div>

      <div v-if="!participants || !participants.length">None found</div>
    </div>

    <div class="modal__buttons">
      <div class="btn" @click="$modal.hide('participants')">Ok</div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import UserSuggested from '~/components/cards/UserSuggested'

export default {
  components: {
    UserSuggested,
  },
  data() {
    return {
      title: 'Users',
      participants: [],
    }
  },
  watch: {
    $route: function () {
      this.$modal.hide('participants')
    },
  },
  methods: {
    async beforeOpen(event) {
      if (event.params.title) this.title = event.params.title
      if (event.params.participants)
        this.participants = event.params.participants
    },

    async beforeClose(event) {
      if (this.title === 'Reactions') {
        this.$store.dispatch('posts/setValue', {
          name: 'reactions',
          value: [],
        })
      }
    },
    trackUsers(id) {
      if (!this.$track) return
      if (id) {
        this.$track.event({
          segment: {
            action: 'User Card Clicked From Profile',
            data: {
              id: id,
            },
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}

.modal__content {
  .user + .user {
    margin-top: 10px;
  }
}
</style>

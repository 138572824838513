import { RefreshScheme } from '@nuxtjs/auth/dist/runtime'
import { RefreshToken } from '@nuxtjs/auth/dist/runtime'
import { SchemeCheck } from '@nuxtjs/auth'
import { RefreshSchemeOptions } from '@nuxtjs/auth'

import jwtDecode from 'jwt-decode'

interface DecodedToken extends RefreshToken {
  acc: string[]
}

interface AtlasRefreshSchemeOptions extends RefreshSchemeOptions {
  allowedDomain: string
}

export default class AtlasRefresh extends RefreshScheme {
  check(checkStatus = false): SchemeCheck {
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true,
    }

    // Sync tokens
    const token = this.token.sync()
    const refreshToken = this.refreshToken.sync()

    if (!token || !refreshToken) {
      return response
    }

    if (token) {
      const formattedToken = (token as string).replace('Bearer ', '')
      const decodedToken: DecodedToken = jwtDecode(formattedToken)

      const allowedDomain = (this.options as AtlasRefreshSchemeOptions)
        ?.allowedDomain

      if (decodedToken && !decodedToken.acc?.includes(allowedDomain)) {
        response.valid = false
        return response
      }
    }

    // Check status wasn't enabled, let it pass
    if (!checkStatus) {
      response.valid = true
      return response
    }

    // Get status
    const tokenStatus = this.token.status()
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token has expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      response.refreshTokenExpired = true
      return response
    }

    // Token has expired, Force reset.
    if (tokenStatus.expired()) {
      response.tokenExpired = true
      return response
    }

    response.valid = true
    return response
  }
  reset(args) {
    // Do the normal reset for refresh scheme, which sets storage to false (:
    super.reset(args)

    // @ts-expect-error _state is private but it's actually accessible at runtime
    const authStateKeys = Object.keys(this.$auth.$storage._state) as string[]

    if (authStateKeys.length) {
      authStateKeys.forEach((key) => {
        this.$auth.$storage.removeUniversal(key)
      })
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"thought scrollable",attrs:{"name":"profileThought","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('div',{staticClass:"modal__title"},[_vm._v("Thought Leadership")]),_vm._v(" "),_c('div',{staticClass:"modal__content"},[_c('h3',[_vm._v("Articles")]),_vm._v(" "),_c('div',{staticClass:"thought__headers"},[_c('div',[_vm._v("Display Text")]),_vm._v(" "),_c('div',[_vm._v("Link")])]),_vm._v(" "),_c('ul',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          handle: '.draggable',
        }),expression:"{\n          handle: '.draggable',\n        }"}],staticClass:"thought__list",attrs:{"id":"articles"}},_vm._l((_vm.links.self_articles),function(article,index){return _c('li',{key:`edit-article-${index}`},[_c('span',{staticClass:"draggable"},[_c('svgDrag')],1),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:({
              max: 150,
            }),expression:"{\n              max: 150,\n            }"}],attrs:{"placeholder":"Display Text","name":'article_display' + index,"data-vv-as":"article display","data-vv-scope":"thought","error":_vm.errors.first('thought.article_display' + index)},model:{value:(article.display),callback:function ($$v) {_vm.$set(article, "display", $$v)},expression:"article.display"}}),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:({
              regex: /^http:\/\/|https:\/\//,
              url: true,
            }),expression:"{\n              regex: /^http:\\/\\/|https:\\/\\//,\n              url: true,\n            }"}],attrs:{"placeholder":"Link","name":'article_url' + index,"data-vv-as":"url","data-vv-scope":"thought","error":_vm.errors.first('thought.article_url' + index)},model:{value:(article.url),callback:function ($$v) {_vm.$set(article, "url", $$v)},expression:"article.url"}}),_vm._v(" "),_c('DeleteLink',{attrs:{"array":"links.self_articles","index":_vm.links.self_articles.indexOf(article),"tagName":"input","type":"article","cb":_vm.updateResources}})],1)}),0),_vm._v(" "),(_vm.links.self_articles && _vm.links.self_articles.length < 5)?_c('AddLink',{attrs:{"role":"self_article","type":_vm.links.self_articles,"text":"Article","cb":_vm.addItem}}):_vm._e(),_vm._v(" "),_c('h3',[_vm._v("Presentations")]),_vm._v(" "),_c('div',{staticClass:"thought__headers"},[_c('div',[_vm._v("Display Text")]),_vm._v(" "),_c('div',[_vm._v("Link")])]),_vm._v(" "),_c('ul',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          handle: '.draggable',
        }),expression:"{\n          handle: '.draggable',\n        }"}],staticClass:"thought__list",attrs:{"id":"presentations"}},_vm._l((_vm.links.self_presentations),function(presentation,index){return _c('li',{key:`edit-presentation-${index}`},[_c('span',{staticClass:"draggable"},[_c('svgDrag')],1),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:({
              max: 150,
            }),expression:"{\n              max: 150,\n            }"}],attrs:{"placeholder":"Display Text","name":'presentation_display' + index,"data-vv-as":"presentation display","data-vv-scope":"thought","error":_vm.errors.first('thought.presentation_display' + index)},model:{value:(presentation.display),callback:function ($$v) {_vm.$set(presentation, "display", $$v)},expression:"presentation.display"}}),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:({
              regex: /^http:\/\/|https:\/\//,
              url: true,
            }),expression:"{\n              regex: /^http:\\/\\/|https:\\/\\//,\n              url: true,\n            }"}],attrs:{"placeholder":"Link","name":'presentation_url' + index,"data-vv-as":"url","data-vv-scope":"thought","error":_vm.errors.first('thought.presentation_url' + index)},model:{value:(presentation.url),callback:function ($$v) {_vm.$set(presentation, "url", $$v)},expression:"presentation.url"}}),_vm._v(" "),_c('DeleteLink',{attrs:{"array":"links.self_presentations","index":_vm.links.self_presentations.indexOf(presentation),"tagName":"input","type":"presentation","cb":_vm.updateResources}})],1)}),0),_vm._v(" "),(_vm.links.self_presentations && _vm.links.self_presentations.length < 5)?_c('AddLink',{attrs:{"role":"self_presentation","type":_vm.links.self_presentations,"text":"Presentation","cb":_vm.addItem}}):_vm._e(),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"about__error"},[_vm._v("\n        "+_vm._s(_vm.error)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn btn--clear",on:{"click":function($event){return _vm.$modal.hide('profileThought')}}},[_vm._v("\n        Cancel\n      ")]),_vm._v(" "),_c('Submit',{attrs:{"text":"Save","loading":_vm.loading}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <modal
    class="thought scrollable"
    name="profileThought"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__title">Thought Leadership</div>

      <div class="modal__content">
        <h3>Articles</h3>

        <div class="thought__headers">
          <div>Display Text</div>

          <div>Link</div>
        </div>

        <ul
          id="articles"
          v-sortable="{
            handle: '.draggable',
          }"
          class="thought__list"
        >
          <li
            v-for="(article, index) in links.self_articles"
            :key="`edit-article-${index}`"
          >
            <span class="draggable"><svgDrag /></span>

            <TextInput
              v-model="article.display"
              v-validate="{
                max: 150,
              }"
              placeholder="Display Text"
              :name="'article_display' + index"
              data-vv-as="article display"
              data-vv-scope="thought"
              :error="errors.first('thought.article_display' + index)"
            />

            <TextInput
              v-model="article.url"
              v-validate="{
                regex: /^http:\/\/|https:\/\//,
                url: true,
              }"
              placeholder="Link"
              :name="'article_url' + index"
              data-vv-as="url"
              data-vv-scope="thought"
              :error="errors.first('thought.article_url' + index)"
            />

            <DeleteLink
              array="links.self_articles"
              :index="links.self_articles.indexOf(article)"
              tagName="input"
              type="article"
              :cb="updateResources"
            />
          </li>
        </ul>

        <AddLink
          v-if="links.self_articles && links.self_articles.length < 5"
          role="self_article"
          :type="links.self_articles"
          text="Article"
          :cb="addItem"
        />

        <h3>Presentations</h3>

        <div class="thought__headers">
          <div>Display Text</div>

          <div>Link</div>
        </div>

        <ul
          id="presentations"
          v-sortable="{
            handle: '.draggable',
          }"
          class="thought__list"
        >
          <li
            v-for="(presentation, index) in links.self_presentations"
            :key="`edit-presentation-${index}`"
          >
            <span class="draggable"><svgDrag /></span>

            <TextInput
              v-model="presentation.display"
              v-validate="{
                max: 150,
              }"
              placeholder="Display Text"
              :name="'presentation_display' + index"
              data-vv-as="presentation display"
              data-vv-scope="thought"
              :error="errors.first('thought.presentation_display' + index)"
            />

            <TextInput
              v-model="presentation.url"
              v-validate="{
                regex: /^http:\/\/|https:\/\//,
                url: true,
              }"
              placeholder="Link"
              :name="'presentation_url' + index"
              data-vv-as="url"
              data-vv-scope="thought"
              :error="errors.first('thought.presentation_url' + index)"
            />

            <DeleteLink
              array="links.self_presentations"
              :index="links.self_presentations.indexOf(presentation)"
              tagName="input"
              type="presentation"
              :cb="updateResources"
            />
          </li>
        </ul>

        <AddLink
          v-if="links.self_presentations && links.self_presentations.length < 5"
          role="self_presentation"
          :type="links.self_presentations"
          text="Presentation"
          :cb="addItem"
        />

        <div v-if="error" class="about__error">
          {{ error }}
        </div>
      </div>

      <div class="modal__buttons">
        <div class="btn btn--clear" @click="$modal.hide('profileThought')">
          Cancel
        </div>

        <Submit text="Save" :loading="loading" />
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import TextInput from '~/components/form/Input'
import Submit from '~/components/form/Submit'

import AddLink from '~/components/project/AddLink'
import DeleteLink from '~/components/project/DeleteLink'

import svgDrag from '~/static/icons/round-drag_indicator-24px.svg'

import '~/plugins/vue-sortable'

export default {
  components: {
    TextInput,
    Submit,
    AddLink,
    DeleteLink,
    svgDrag,
  },
  inject: ['$validator'],
  data() {
    return {
      links: {
        self_articles: [],
        self_presentations: [],
      },
      allLinks: [],
      error: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileThought')
    },
  },
  methods: {
    async beforeOpen(event) {
      let app = this
      this.links.self_articles = []
      this.links.self_presentations = []

      if (this.user.links_sorted.self_articles.length) {
        this.user.links_sorted.self_articles.forEach(function (article) {
          app.links.self_articles.push({
            display: article.display,
            url: article.url,
            link_role: 'self_article',
          })
        })
      } else {
        app.links.self_articles.push({
          display: '',
          url: '',
          link_role: 'self_article',
        })
      }

      if (this.user.links_sorted.self_presentations.length) {
        this.user.links_sorted.self_presentations.forEach(function (
          presentation
        ) {
          app.links.self_presentations.push({
            display: presentation.display,
            url: presentation.url,
            link_role: 'self_presentations',
          })
        })
      } else {
        app.links.self_presentations.push({
          display: '',
          url: '',
          link_role: 'self_presentations',
        })
      }
    },
    validateBeforeSubmit() {
      let app = this
      let submitData = {}

      this.error = ''

      this.$validator.validateAll('thought').then((x) => {
        if (x) {
          app.allLinks = []

          app.buildLinks('articles', 'article', 'self_article')
          app.buildLinks('presentations', 'presentation', 'self_presentation')
          app.buildSocial()

          let updateData = {
            id: app.user.id,
            data: {
              links: app.allLinks,
            },
            cb: function () {
              app.$modal.hide('profileThought')
              app.loading = false
            },
          }

          app.loading = true

          app.$store.dispatch('users/updateUser', updateData)
        }
      })
    },
    buildLinks(id, type, role) {
      let app = this
      let $listElem = document.getElementById(id)
      let $listItems = $listElem.getElementsByTagName('li')
      let items = []
      let count = 0

      Object.keys($listItems).forEach(function (index) {
        let $item = $listItems[index]
        let $inputs = $item.getElementsByTagName('input')
        let inputSubmit = {
          display: null,
          url: null,
          link_role: role,
        }

        // Loops through resource inputs and stash values
        Object.keys($inputs).forEach(function (indexInput) {
          let $input = $inputs[indexInput]
          let label = $input.name.replace(type + '_', '').slice(0, -1)

          inputSubmit[label] = $input.value
        })

        // Check if resource has url
        if (inputSubmit.url != null && inputSubmit.url != '') {
          items.push(inputSubmit)
        }

        if (count == $listItems.length - 1) {
          app.links[role + 's'] = items
          app.allLinks = app.allLinks.concat(items)
        }

        count++
      })
    },
    buildSocial() {
      let app = this
      let social = []

      if (app.user.links_sorted.facebook.length) {
        social.push({
          display: app.user.links_sorted.facebook[0].display,
          url: app.user.links_sorted.facebook[0].url,
          link_role: app.user.links_sorted.facebook[0].link_role,
        })
      }

      if (app.user.links_sorted.twitter.length) {
        social.push({
          display: app.user.links_sorted.twitter[0].display,
          url: app.user.links_sorted.twitter[0].url,
          link_role: app.user.links_sorted.twitter[0].link_role,
        })
      }

      if (app.user.links_sorted.linkedin.length) {
        social.push({
          display: app.user.links_sorted.linkedin[0].display,
          url: app.user.links_sorted.linkedin[0].url,
          link_role: app.user.links_sorted.linkedin[0].link_role,
        })
      }

      app.allLinks = app.allLinks.concat(social)
    },
    addItem(type, role) {
      type.push({
        display: '',
        url: '',
        link_role: role,
      })
    },
    updateResources(type) {
      if (type === 'links.self_articles') {
        this.links.self_articles.push({
          display: '',
          url: '',
          link_role: 'self_article',
        })
      }

      if (type === 'links.self_presentations') {
        this.links.self_presentations.push({
          display: '',
          url: '',
          link_role: 'self_presentations',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

// .input,
// .textarea {
//   margin-bottom: 20px;
// }

.thought {
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.78px;
    text-transform: uppercase;
  }
}

.thought__headers {
  display: flex;
  margin-bottom: 5px;
  padding: 0 29px;
  color: #1b1c1a;
  font-size: 17px;

  > div {
    width: 100%;

    & + div {
      margin-left: 15px;
    }
  }
}

.thought__list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    margin: 0 0 5px;
    align-items: flex-start;
  }
}

.draggable {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 9px;
  margin-right: 5px;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    pointer-events: none;
  }
}

.delete-item {
  margin-top: 9px;
  margin-left: 5px;
  width: 24px;
  height: 24px;
}

.add-link {
  color: #000000;
  margin-top: 10px;
  text-decoration: underline;
}

.input {
  width: 100%;

  & + .input {
    margin-left: 15px;
  }
}
</style>

<template>
  <modal
    data-test="interests-modal"
    name="interests"
    :clickToClose="true"
    :width="modalWidth"
    height="auto"
    @before-open="setInitialTags"
  >
    <div class="modal__title">Topics I Follow</div>
    <div class="modal__subtitle">
      Follow topics to get relevant case studies and news articles included in
      your Interests Digest email sent each month.
    </div>
    <div ref="contentBody" class="modal__content">
      <ErrorAlert v-if="error">
        <div v-html="error" />
      </ErrorAlert>

      <div v-if="$fetchState.pending">Loading challenge tags...</div>

      <div v-else>
        <div class="search-wrapper">
          <Multiselect
            v-if="interestGroupChildren"
            ref="multiselectRef"
            :options="interestGroupChildren"
            :searchable="true"
            track-by="label"
            label="label"
            :showLabels="false"
            :showSelected="false"
            placeholder="Search for topics to follow"
            data-test="interests-modal-search"
            @select="handleSearchSelection"
          />
          <svgSearch fill="#ccc" width="22" />
        </div>

        <div
          v-for="interestGroup in interestGroups"
          :key="interestGroup.id"
          class="section"
        >
          <h4 class="section-title">
            {{ interestGroup.label }}
          </h4>
          <div class="tags-container">
            <ToggleButton
              v-for="tag in interestGroup.kids"
              :key="tag.id"
              :on="selectedTags.some((k) => k.id === tag.id)"
              @click="toggleTag(tag)"
            >
              {{ tag.label }}
            </ToggleButton>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__footer">
      <ButtonV2
        data-test="interests-modal-submit"
        :onClick="submit"
        :loading="submitting"
      >
        {{ submitting ? 'Saving...' : 'Save' }}
      </ButtonV2>

      <ButtonV2
        variant="secondary"
        :onClick="hideModal"
        data-test="interests-modal-close"
      >
        Close
      </ButtonV2>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { xor, uniq, get, values } from 'lodash-es'

import getUserErrorMessage from '~/utils/getUserErrorMessage'

import svgSearch from '~/static/icons/search-24px.svg'

import ErrorAlert from '~/components/form/ErrorAlert'
import ButtonV2 from '~/components/ButtonV2'
import ToggleButton from '~/components/form/ToggleButton'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    ButtonV2,
    ErrorAlert,
    Multiselect,
    ToggleButton,
    svgSearch,
  },
  data() {
    return {
      modalWidth: 550,
      searchedTags: [],
      submitting: false,
      error: null,
      submittingMessage: null,
      selectedTags: [],
      errorElement: null,
    }
  },
  async fetch() {
    const { store } = this.$nuxt.context

    await store.dispatch('projects/loadChallengeTags')
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      interestGroups: 'projects/challengeTags',
    }),
    interestGroupChildren: function () {
      if (!this.interestGroups) {
        return []
      }

      const result = []
      this.interestGroups.forEach((interestGroup) => {
        result.push(...interestGroup.kids)
      })

      return result
    },
    hasSelectedTags: function () {
      return this.selectedTags.length !== 0
    },
  },
  mounted() {
    // $refs are non-reactive, so error element gets set within the data on mount
    this.errorElement = this.$refs.contentBody
  },
  beforeDestroy() {
    if (this.$refs && this.$refs.multiselectRef) {
      this.$refs.multiselectRef.deactivate()
    }
  },
  beforeMount() {
    this.getModalWidth()
  },
  methods: {
    setInitialTags() {
      const { tags_categorized } = this.$store.state.users.user
      const challengeTagsFollowed = tags_categorized[1].tags

      this.selectedTags = challengeTagsFollowed
    },
    toggleTag(tag) {
      // We have the tag, remove
      if (this.selectedTags.find((selectedTag) => selectedTag.id === tag.id)) {
        this.selectedTags = this.selectedTags.filter(
          (selectedTag) => selectedTag.id !== tag.id
        )
      } else {
        this.selectedTags = [...this.selectedTags, tag]
      }
    },
    handleSearchSelection(selectedTag) {
      this.selectedTags = uniq([selectedTag, ...this.selectedTags])
      this.searchedTags = uniq([selectedTag, ...this.searchedTags])
    },
    submit() {
      this.submitting = true
      this.error = null

      let otherUserTagIds = []

      values(this.user.tags_categorized).forEach((tagGroup) => {
        if (tagGroup.group_id === 1) return

        const tagIds = tagGroup.tags.map((tag) => tag.id)
        otherUserTagIds.push(...tagIds)
      })

      const challengeTagIds = this.selectedTags.map((tag) => tag.id)

      const data = {
        tags: [...challengeTagIds, ...otherUserTagIds],
      }

      this.$store.dispatch('users/updateUser', {
        id: this.$auth.user.id,
        data,
        cb: () => {
          this.submitting = false
          this.track()
          this.hideModal()
        },
        error: (res) => {
          this.submitting = false

          this.error = getUserErrorMessage(res)

          // Scroll body to top on error, to show message
          this.errorElement.scrollTop = 0
        },
      })
    },
    getModalWidth() {
      const width = get(window, 'innerWidth', 0)

      if (Number.isInteger(width) && width >= 550) {
        this.modalWidth = 550
      } else {
        this.modalWidth = '100%'
      }
    },
    hideModal() {
      this.$modal.hide('interests')
    },
    track() {
      if (!this.$track) return

      const ids = this.selectedTags.map((tag) => tag.id)
      const labels = this.selectedTags.map((tag) => tag.label)

      this.$track.event({
        segment: {
          action: 'Interests Updated',
          data: {
            interest_ids: ids,
            interest_labels: labels,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__subtitle {
  color: $grey-700;
  margin-bottom: 20px;
}

.modal__title {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 300;
}

.modal__content {
  max-height: 50vh;
  overflow: auto;
  margin-bottom: 20px;
}

.modal__footer {
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: -25px; // get around having to use `top`
    height: 5px;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
  }
}

.checkbox {
  display: block;
  margin-bottom: 10px;
}

.search-wrapper /deep/ .multiselect {
  color: rgba(74, 85, 104, 1);

  .multiselect__select {
    display: none;
  }

  &.multiselect--active .multiselect__tags {
    border-color: $primary-600;
  }

  .multiselect__option--highlight {
    background: $aa-light-blue;
    color: $primary-600;
  }

  .multiselect__input,
  .multiselect__placeholder {
    padding: 0;
    margin: 0;
    top: 1;
    line-height: 38px;
    height: 38px;
    font-size: 1rem;
    white-space: nowrap;
  }

  input.multiselect__input {
    color: rgba(74, 85, 104, 1);
  }

  .multiselect__content-wrapper {
    border-color: #ccc;
  }

  .multiselect__tags {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    line-height: 40px;
    padding-left: calc(0.75rem + 22px + 0.5rem);
    border-color: #ccc;

    transition-property: all;
    transition-duration: 300ms;

    &:focus {
      border-color: $primary-600;
    }
  }
}

input {
  font-size: 1rem;
  appearance: none;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 0.25rem;
  color: #4a5568;
  color: rgba(74, 85, 104, 1);
  line-height: 1.25;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 300ms;
  height: 40px;
  outline: none;

  padding-left: calc(0.75rem + 22px + 0.5rem);

  &:focus {
    border-color: $primary-600;
  }
}

.search-wrapper {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  svg {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 51;
  }

  input {
    padding-left: calc(0.75rem + 22px + 0.5rem);
  }
}
</style>

<template>
  <div>
    <Unsupported />

    <div class="wrapper">
      <div class="minimal-header">
        <div class="minimal-header__content">
          <svgAtlasLogo width="150px" />
          <Avatar
            v-if="user"
            :imgSrc="user.default_profile_pic_url"
            :variant="'round'"
          />
          <nuxt-link v-else to="/users/login"> Log In </nuxt-link>
        </div>
      </div>
      <main>
        <nuxt />
      </main>
    </div>

    <div v-if="impersonating && user" class="impersonating">
      <div class="impersonating__close" @click="removeImpersonating">
        <svgClose />
      </div>

      You are logged in as <b>{{ user.name }}</b
      >, a {{ user.role_value }} user - please log in again to return to admin.
      Use caution, all activity is recorded as this user.
    </div>

    <OldTabWarning />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import svgAtlasLogo from '~/static/logos/logo.svg'
import svgClose from '~/static/icons/round-close-24px.svg'
import Unsupported from '~/components/banners/Unsupported.vue'
import OldTabWarning from '~/components/modals/OldTabWarning'
import Avatar from '~/components/user/Avatar.vue'

export default {
  components: {
    Unsupported,
    svgAtlasLogo,
    svgClose,
    OldTabWarning,
    Avatar,
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
    }),
    user() {
      return this.$auth.user
    },
    initials() {
      if (this.$auth.user && this.$auth.user.name) {
        let nameArray = this.$auth.user.name.split(' ')
        let twoLetters = ''

        nameArray.forEach(function (name) {
          if (twoLetters.length > 1) return

          twoLetters += name.substring(0, 1)
        })

        return twoLetters
      }
      return ''
    },
  },
  methods: {
    removeImpersonating() {
      this.$store.dispatch('authHelpers/setImpersonating', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.minimal-header {
  padding: 2px 1rem;
  border-bottom-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: #fff;

  @include bp('sm') {
    padding-left: 0;
    padding-right: 0;
  }

  a {
    font-weight: 700;
    font-size: 17px;
    line-height: 25.5px;
    color: $primary;
  }
}

.minimal-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;

  @include bp('sm') {
    width: 75vw;
  }

  @include bp('lg') {
    max-width: 800px;
  }
}

main {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.wrapper {
  background-color: #f5f7f9;
  min-height: 100vh;
}
</style>

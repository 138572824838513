import Vue from 'vue'

Vue.mixin({
  methods: {
    getValue(item, field) {
      let fieldValue = ''
      let app = this

      // Handle first level string value
      if (typeof field == 'string') {
        if (item[field]) fieldValue = item[field]
      }

      // Handle multiple level string value
      if (Array.isArray(field)) {
        let builtValue = item

        field.forEach(function (elem) {
          if (builtValue[elem] == null) return
          builtValue = builtValue[elem]
        })

        if (typeof builtValue == 'string' || Array.isArray(builtValue))
          fieldValue = builtValue
      }

      return fieldValue
    },
    checkPermission(type, permission, user) {
      if (!user) return false

      let permissions = this.getValue(user, type)

      if (permissions.includes('All')) return true

      return permissions.includes(permission)
    },
    timeSince(date) {
      const transformedDate = new Date(date)
      const seconds = Math.floor((new Date() - transformedDate) / 1000)
      let interval = Math.floor(seconds / 31536000)

      if (isNaN(seconds) || isNaN(interval)) {
        this.$bugsnag.notify(
          `Invalid date supplied to timeSince function. Current date: ${new Date()}, supplied date: ${date}, transformed date: ${transformedDate}`
        )
        return ''
      }

      if (interval > 1) {
        return interval + ' years ago'
      }

      interval = Math.floor(seconds / 2592000)

      if (interval > 1) {
        return interval + ' months ago'
      }

      interval = Math.floor(seconds / 86400)

      if (interval > 1) {
        return interval + ' days ago'
      }

      interval = Math.floor(seconds / 3600)

      if (interval > 1) {
        return interval + ' hours ago'
      }

      interval = Math.floor(seconds / 60)

      if (interval > 1) {
        return interval + ' minutes ago'
      }

      return Math.floor(seconds) + ' seconds ago'
    },
    toggleClass(elem, target, className) {
      let type = target.substring(0, 1)
      let targetElem = null
      let activeClass = elem.id
        ? elem.id + '--active'
        : elem.classList.item(0) + '--active'

      switch (type) {
        case '#':
          targetElem = document.getElementById(target.substring(1))
          break
        case '.':
          targetElem = elem.querySelector(target)
          break
        case 'n':
          targetElem = elem.nextElementSibling
      }

      if (elem.classList) elem.classList.toggle(activeClass)
      if (targetElem.classList) targetElem.classList.toggle(className)
    },
    getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
    },
    getHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      )
    },
  },
})

<template lang="html">
  <div v-if="loading" class="loading-page">
    <div v-if="loading">
      Loading
      <div class="stage">
        <div class="dot-flashing" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10000;
  bottom: 60px;
  left: 50%;
  width: 230px;
  margin-left: -115px;
  padding: 20px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  color: #2d6079;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.stage {
  display: inline-flex;
  width: 80px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.dot-flashing,
.dot-flashing::before,
.dot-flashing::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2d6079;
  color: #2d6079;
}

.dot-flashing {
  position: relative;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -30px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 30px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #2d6079;
  }
  50%,
  100% {
    background-color: #e6e6e6;
  }
}
</style>

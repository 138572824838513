<template>
  <div class="input" :class="inputModifiers">
    <label v-if="label" class="input__label" :class="labelModifiers" :for="id">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
      <span v-if="label.required" class="optional">&nbsp;- Required</span>
    </label>

    <div class="input-wrapper">
      <client-only v-if="tributeOptions">
        <vue-tribute :options="tributeOptions">
          <input
            :id="id"
            v-model="curValue"
            :type="type"
            :min="min"
            step="any"
            class="input__element"
            :class="{ 'input__elem--error': error }"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :data-test="dataTest"
            @keydown="charCount"
            @input="updateValue"
            @change="updateValue"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur')"
            @tribute-replaced="replaced"
          />
        </vue-tribute>
      </client-only>

      <input
        v-if="!tributeOptions"
        :id="id"
        v-model="curValue"
        :type="type"
        step="any"
        :min="min"
        class="input__element"
        :class="{
          'input__elem--error': error || hasErrored,
          'input__elem--error-icon': (error || hasErrored) && showErrorIcon,
        }"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :data-test="dataTest"
        :data-type="dataType"
        @keydown="charCount"
        @input="updateValue"
        @change="updateValue"
        @copy="$emit('copy', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur')"
      />
      <div
        v-if="maxlength"
        class="input__count"
        :class="{
          'input__count--error': (value ? value.length : 0) >= maxlength,
        }"
      >
        <span class="count-value">{{ value ? value.length : 0 }}</span
        >/{{ maxlength }}
      </div>
    </div>
    <div
      v-if="error"
      class="form__error"
      aria-live="polite"
      data-test="error-message"
      data-error-message
    >
      {{ error }}
      {{ placeholder == 'Link' ? 'Correct format: http://example.com' : '' }}
    </div>
  </div>
</template>

<script>
import utils from '~/plugins/globals.js'

export default {
  components: {
    'vue-tribute': () => import('vue-tribute'),
  },
  props: {
    id: String,
    name: String,
    placeholder: String,
    min: String,
    value: [String, Number],
    maxlength: {
      type: Number,
      required: false,
    },
    dataTest: {
      type: String,
      required: false,
      default: 'input-element',
    },
    dataType: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (val) => {
        return (
          ['url', 'text', 'password', 'email', 'search', 'number'].indexOf(
            val
          ) !== -1
        )
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modifiers: Array,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
      required: false,
    },
    hasErrored: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    showErrorIcon: {
      type: Boolean,
      default: false,
    },
    tributeOptions: Object,
    mentionId: String,
  },
  emits: ['input', 'focus', 'blur'],
  computed: {
    inputModifiers() {
      let mods = []

      if (this.modifiers) {
        mods = this.buildMods('input--', this.modifiers)
      }

      if (this.maxlength) {
        mods.push('input--count')
      }

      if (this.error) {
        mods.push('form--error')
      }

      return mods
    },
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        return this.value
      },
      set(newValue) {
        // this.curChoice = newValue
      },
    },
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    charCount($event) {
      // console.log("CHAR COUNT")
      if (this.maxlength) {
        utils.charCount($event, this.maxlength)
      }
    },
    updateValue($event) {
      // console.log("UPDATE VALUE INPUT")
      // console.log($event.target.value)
      this.$emit('input', $event.target.value)
    },
    replaced($event) {
      this.$store.dispatch('posts/addKeyed', {
        name: 'mentions',
        id: this.mentionId,
        value: $event.detail.item.original,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  max-width: 100%;
}

.input__label {
  display: block;
  margin-bottom: 5px;
  color: rgba(74, 85, 104, 1);
}

input {
  font-size: 1rem;
  appearance: none;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: rgba(74, 85, 104, 1);
  line-height: 1.25;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 300ms;
  height: 40px;
  outline: none;

  &:focus {
    border-color: #219ebc;
  }
}

.input--inline {
  display: inline-block;

  .form__error {
    font-size: 10px;
  }
}

.form-error {
  margin-top: 5px;
}

.input__elem--error {
  border: 1px solid $error;
  margin-bottom: 5px;
}

.input__elem--error-icon {
  padding-right: 32px;

  &:focus {
    border-color: $error;
  }
}

.input--inline .input__element {
  display: inline-block;
  height: auto;
  padding: 5px;
  border: 1px dashed #ababab;

  &:focus {
    border: 1px solid #50505d;
  }
}

.input-wrapper {
  position: relative;
}

.input--count .input__element {
  padding-right: 60px;
}

.input__count {
  position: absolute;
  top: 50%;
  right: 5px;
  color: #c4cbce;
  transform: translateY(-50%);
}

.input__count--error {
  color: $error;
  /deep/ .count-value {
    font-weight: bold;
  }
}

.input--drop-shadow {
  box-shadow: $box-shadow-1;
  border-radius: $rounded-border-1;
  height: 44px;
  padding-left: 8px;

  input {
    margin: 2px 0;
    border: none;
  }
}
</style>

<template>
  <div
    id="unsupported"
    class="unsupported"
    :class="{ 'unsupported--show': unsupported }"
  >
    <div class="unsupported__content">
      <p class="unsupported__header">Unsupported Browser</p>

      <p>
        We've detected an older browser version that will not give you the best
        experience while using The Atlas. Please consider revisitng this site
        after downloading one of the alternatives below.
      </p>

      <div class="unsupported__links">
        <a href="https://www.google.com/chrome/" target="_blank">Chrome</a>

        <a href="https://www.mozilla.org/en-US/firefox/" target="_blank"
          >Firefox</a
        >

        <a
          href="https://www.microsoft.com/en-us/windows/microsoft-edge"
          target="_blank"
          >Edge</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      unsupported: false,
    }
  },
  mounted() {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ') > -1

    // Check for IE
    if (!ua.indexOf('MSIE ') > -1) return

    // Check if IE 11
    if (ua.indexOf('Trident/') > -1) return

    // Check if IE 12
    if (ua.indexOf('Edge/') > -1) return

    this.unsupported = true

    // document.getElementById('unsupported').classList.add('unsupported--show')
  },
}
</script>

<style lang="scss" scoped>
.unsupported {
  display: none;
  padding: 0 10px;
}

.unsupported--show {
  display: block;
}

.unsupported__content {
  max-width: 710px;
  margin: 40px auto;
  padding: 20px;
  background: #fff3dd;
  border-left: 5px solid #e39600;
}
.unsupported__header {
  margin: 0 0 5px;
  font-weight: bold;
}

.unsupported__links {
  a {
    text-decoration: underline;

    & + a {
      margin-left: 10px;
    }
  }
}
</style>

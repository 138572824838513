import getYearRange from '~/utils/getYearRange'

export const COOKIE_NAMES = {
  TOP_5_OPTED_IN: 'atlas.top_5_opted_in',
}

export const BREAKPOINTS = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1980,
  xxxl: 2500,
}

export const COST_OPTIONS = [
  'USD',
  'CAD',
  'AUD',
  'EUR',
  'JPY',
  'GBP',
  'RMB',
  'CNY',
]

export const MULTIPLIER_OPTIONS = ['Thousand', 'Million', 'Billion']

export const STATUS_OPTIONS = ['Operational', 'In Progress/Under Construction']

export const YEAR_OPTIONS = getYearRange()

export const EMAILS = {
  COMMUNITY: 'community@the-atlas.com',
  SUPPORT: 'support@the-atlas.com',
}

<template>
  <modal name="oldTabWarning" :width="550" height="auto">
    <form>
      <div class="modal__title">This tab is outdated</div>

      <div class="modal__content">
        <p>
          You are impersonating in another tab, and no longer truly acting as an
          admin. Please refresh.
        </p>
      </div>

      <div class="modal__buttons">
        <div class="btn" @click="refresh">Refresh</div>
      </div>
    </form>
  </modal>
</template>

<script>
export default {
  methods: {
    refresh() {
      window.location = window.location
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"project-seo",attrs:{"name":"pageSeo","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen}},[_c('form',[_c('div',{staticClass:"modal__title"},[_vm._v("SEO Settings")]),_vm._v(" "),_c('div',{staticClass:"modal__content"},[_c('p',[_vm._v("\n        You can fill out your page's metadata here. This will improve your\n        project's search engine visibility.\n      ")]),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:('max:60'),expression:"'max:60'"}],attrs:{"id":"page_title","name":"page_title","label":{
          text: 'Page Title',
          modifiers: ['header'],
        },"data-vv-as":"page title","data-vv-scope":"pageSeo","maxlength":60,"error":_vm.errors.first('pageSeo.page_title') ||
          (_vm.error && _vm.error.page_title_seo && _vm.error.page_title_seo[0])},model:{value:(_vm.pageData.page_title_seo),callback:function ($$v) {_vm.$set(_vm.pageData, "page_title_seo", $$v)},expression:"pageData.page_title_seo"}}),_vm._v(" "),_c('TextArea',{directives:[{name:"validate",rawName:"v-validate",value:('max:150'),expression:"'max:150'"}],attrs:{"id":"page_description","name":"page_description","data-vv-as":"page description","data-vv-scope":"pageSeo","maxlength":150,"error":_vm.errors.first('pageSeo.page_description') ||
          (_vm.error && _vm.error.description_seo && _vm.error.description_seo[0]),"label":{
          text: 'Page Description',
          modifiers: ['header'],
        }},model:{value:(_vm.pageData.description_seo),callback:function ($$v) {_vm.$set(_vm.pageData, "description_seo", $$v)},expression:"pageData.description_seo"}}),_vm._v(" "),(_vm.user.is_internal)?_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:('max:75'),expression:"'max:75'"}],attrs:{"id":"page_slug","name":"page_slug","label":{
          text: 'Slug',
          modifiers: ['header'],
        },"data-vv-scope":"pageSeo","data-vv-as":"page slug","maxlength":75,"error":_vm.errors.first('pageSeo.page_slug') ||
          (_vm.error && _vm.error.slug && _vm.error.slug[0])},model:{value:(_vm.pageData.slug),callback:function ($$v) {_vm.$set(_vm.pageData, "slug", $$v)},expression:"pageData.slug"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn btn--clear btn--spaced",on:{"click":function($event){return _vm.$modal.hide('pageSeo')}}},[_vm._v("\n        Cancel\n      ")]),_vm._v(" "),_c('SubmitButton',{attrs:{"isDisabled":!_vm.formIsDirty,"loading":_vm.loading,"onClick":_vm.updateSeo,"variant":"solid"}},[_vm._v("\n        Update\n      ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
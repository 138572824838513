export default function ({ $auth, $axios, app, route, redirect }) {
  const user = $auth.user
  const basePath = route.path.split('/')[1]

  const userHasCompletedOnboarding = user && user.user_has_completed_onboarding
  // TODO: We should rely on the auth/user object here as well. There should be a switch prpoerty if they are impersonating
  const impersonating = user && app.store.getters['authHelpers/impersonating']
  const userIsInternal = user && user.is_internal
  const userHasAccessCode = user && user.using_access_code
  const userHasJobTitle = user && user.job_title

  if (
    user &&
    !userHasAccessCode &&
    route.path.includes('/users/create-password')
  ) {
    // If they have not completed onboarding we will catch them below
    redirect('/users/dashboard')
    return
  }

  // Missing job title will require this onboarding step once they log in without an access code
  if (
    user &&
    !route.path.includes('/users/onboarding/job-title') &&
    !userHasJobTitle &&
    !userHasAccessCode &&
    !impersonating &&
    !userIsInternal
  ) {
    redirect('/users/onboarding/job-title')
    return
  }

  // If users are logged in and not finished with onboarding, redirect them back to it
  if (
    user &&
    !route.path.includes('/users/onboarding/interests') &&
    !userHasCompletedOnboarding &&
    !userHasAccessCode &&
    userHasJobTitle
  ) {
    if (!userIsInternal && !impersonating) {
      redirect('/users/onboarding/interests')
      return
    }
  }

  // Onboarding is completed and tries to visit join
  // TODO: Uncomment once we want this behavior to go live
  // if (basePath == 'welcome') {
  //   if (user && user.user_has_completed_onboarding) redirect('/users/dashboard')
  //   return
  // }

  // Affiliation unapproved
  if (basePath == 'affiliations') {
    if (user && user.current_affiliation.requesting)
      redirect('/users/dashboard')
    return
  }

  // Admin
  if (basePath == 'admin') {
    if (!userIsInternal) redirect('/users/dashboard')
    return
  }
}

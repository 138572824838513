
import Vue from 'vue'
import Submit from '~/components/form/Submit'
import ExitSvg from '~/static/icons/round-close-42px.svg'

import InfoSvg from '~/static/icons/info-24px.svg'
import { MODAL_NAMES } from '~/pages/projects/helpers'
import { BREAKPOINTS } from '~/constants.js'
import { get } from 'lodash-es'

export default Vue.extend({
  components: {
    Submit,
    ExitSvg,
    InfoSvg,
  },
  props: {
    subtitle: String,
    beforeOpen: {
      type: Function,
      required: true,
    },
    modalDimensions: {
      type: Object,
      required: false,
    },
    closed: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    errorCount: {
      type: Number,
      required: true,
    },
    // eslint-disable-next-line vue/no-unused-properties
    requiredFieldsCount: {
      type: Number,
      required: false,
    },
    modalName: {
      type: String,
      required: true,
    },
    showApiError: {
      type: Boolean,
      required: true,
    },
    apiErrorCode: {
      type: Number,
      required: false,
    },
    isDirty: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      viewportWidth: 0,
    }
  },
  computed: {
    setModalDimensions(): object {
      if (this.modalDimensions) {
        if (this.viewportWidth < BREAKPOINTS.sm) {
          return {
            width: '100%',
            height: 'auto',
            maxWidth: 350,
            adaptive: true,
          }
        }
        return this.modalDimensions
      }

      if (this.viewportWidth < BREAKPOINTS.sm) {
        return { maxWidth: 350, height: 'auto', adaptive: true }
      }

      return { minWidth: 650, height: 'auto', adaptive: true }
    },
    apiErrorMessage(): string {
      if (this.showApiError) {
        if (this.apiErrorCode === 401) {
          return '<strong>Your login token has expired.</strong><br>Please login in another tab to avoid losing your changes and try again.'
        }

        return `<strong>We are experiencing an issue.</strong> Contact <a href="mailto:support@the-atlas.com">support@the-atlas.com</a>`
      }
      return ''
    },
    errorStyles(): object {
      if (this.errorCount || this.showApiError) {
        return { 'justify-content': 'space-between' }
      }
      return { 'justify-content': 'flex-end' }
    },
    formattedErrorCount(): string {
      if (this.errorCount < 2) {
        return `${this.errorCount} Error`
      }

      return `${this.errorCount} Errors`
    },
  },
  mounted() {
    if (this.$parent) {
      this.$parent.$on('setLoading', this.setLoading)
    }
    window.addEventListener('resize', this.setViewportSize)
    this.setViewportSize()
  },
  beforeDestroy() {
    if (this.$parent) {
      this.$parent.$off('setLoading', this.setLoading)
    }
    window.removeEventListener('resize', this.setViewportSize)
  },
  methods: {
    beforeClose(event) {
      // you can force the modal to close with { force: true }
      const { force } = get(event, 'params', {})
      if (this.isDirty && !force) {
        // Prevent the original modal from closing
        event.stop()

        // Open the unsaved changes modal and pass the OG name in
        // We'll use that to close both modals if they want to "Proceed"
        this.$modal.show(MODAL_NAMES.UNSAVED_CHANGES, {
          originalModalName: this.modalName,
        })
      }
    },
    setLoading(loadingState) {
      this.loading = loadingState
    },
    hideModal() {
      this.$modal.hide(this.modalName)
    },
    scrollToError() {
      // Scroll to first error present in modal
      const contentBodyElement = this.$refs.contentBody as Element

      const error = contentBodyElement.querySelector('[data-error-message]')

      if (error) {
        error.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })
      }
    },
    setViewportSize() {
      if (window) {
        this.viewportWidth = window.innerWidth
      }
    },
  },
})

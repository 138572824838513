var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"favorites scrollable",attrs:{"name":"profileFavorites","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__title"},[_vm._v("Favorites")]),_vm._v(" "),(_vm.projects && _vm.projects.items.length)?_c('ul',{staticClass:"favorite__list favorite__list--projects"},_vm._l((_vm.projects.items),function(project){return _c('li',{key:`favorites-project-${project.id}`,staticClass:"project"},[_c('div',{staticClass:"project__image-wrap"},[_c('Favorite',{attrs:{"active":true,"type":"star","data":{
                data: {
                  favoriteable_id: project.id,
                  favoriteable_type: 'projects',
                },
              }}}),_vm._v(" "),_c('nuxt-link',{staticClass:"project__image",style:([
                project.default_project_image &&
                project.default_project_image.url
                  ? {
                      'background-image':
                        'url(\'' + project.default_project_image.url + '\')',
                    }
                  : '',
              ]),attrs:{"to":'/projects/' + project.slug}})],1),_vm._v(" "),_c('nuxt-link',{staticClass:"project__info",attrs:{"to":'/projects/' + project.slug}},[(project.name)?_c('div',{staticClass:"project__name"},[_vm._v("\n              "+_vm._s(project.name)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"project__location",class:{
                'project__location--empty': !project.location_address,
              }},[_vm._v("\n              "+_vm._s(project.location_address
                  ? project.location_address
                  : 'No Location')+"\n            ")])])],1)}),0):_vm._e(),_vm._v(" "),(
          _vm.projects.pagination.current_page != _vm.projects.pagination.last_page
        )?_c('div',{staticClass:"favorites__show-more",on:{"click":_vm.loadProjects}},[_vm._v("\n        Show More\n      ")]):_vm._e(),_vm._v(" "),(!_vm.projects || !_vm.projects.items.length)?_c('p',[_vm._v("\n        You’re not following any projects yet.\n        "),_c('br'),_vm._v("\n        You can head to the\n        "),_c('nuxt-link',{staticClass:"modal__link",attrs:{"to":"/projects"}},[_vm._v("Browse Projects")]),_vm._v("\n        page to find some.\n      ")],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn btn--clear",on:{"click":function($event){return _vm.$modal.hide('profileFavorites')}}},[_vm._v("\n          Cancel\n        ")]),_vm._v(" "),_c('Submit',{attrs:{"text":"Done","loading":_vm.loading}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
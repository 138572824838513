<template>
  <component
    :is="getIs"
    class="button"
    :class="getButtonModifiers"
    :type="getType"
    :disabled="loading || isDisabled"
    :to="to ? to : ''"
    :href="href ? href : ''"
    :target="newTab ? '_blank' : ''"
    @click="onClick ? onClick() : ''"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: [
    'onClick',
    'variant',
    'type',
    'to',
    'href',
    'loading',
    'isDisabled',
    'newTab',
  ],
  computed: {
    getIs() {
      if (this.to) return 'nuxt-link'

      if (this.href) return 'a'

      return 'button'
    },
    getButtonModifiers() {
      if (this.variant) {
        if (this.variant === 'secondary') {
          return 'button--secondary'
        }
        if (this.variant === 'highlight') {
          return 'button--highlight'
        }
        if (this.variant === 'outline') {
          return 'button--outline'
        }
        if (this.variant === 'solid') {
          return 'button--solid'
        }
        if (this.variant === 'solid-lowercase') {
          return 'button--lowercase button--solid'
        }

        return ''
      }
      return ''
    },
    getType() {
      // This is not a button, don't specify the type attribute
      if (this.to || this.href) {
        return ''
      }

      if (this.type) {
        return this.type
      }

      // Default behavior if not specified is to submit :O. 'button' prevents that
      // https://stackoverflow.com/a/10836076/2514961
      return 'button'
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  border: none;
  appearance: none;
  background-color: $primary;
  padding: 0.7rem 1.6rem;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0px 4px rgba(0, 147, 211, 0.3);
  white-space: nowrap;
  cursor: pointer;
  display: block;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: darken($primary, 10%);
  }

  &:active,
  &:focus,
  &:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: darken($primary, 3%);
  }
}

.button--secondary {
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-flex;
  transition: 300ms background;
  background-color: transparent;
  box-shadow: none;
  color: #979797;

  &:active,
  &:focus,
  &:hover {
    color: #979797;
    background-color: transparent;
  }
}

.button--outline {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: inline-flex;
  transition: 300ms background;
  display: block;
  text-align: center;
  background-color: transparent;
  border: 1px solid $primary;
  color: $primary;
  box-shadow: none;

  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
    color: darken($primary, 3%);
    border-color: darken($primary, 3%);
  }
}

.button--solid {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: inline-flex;
  transition: 300ms background;
  background-color: $primary;
  padding: 12px 42px;
  border: none;
  box-shadow: none;
  border-radius: 6px;

  &:active,
  &:focus,
  &:hover {
    background-color: darken($primary, 3%);
  }

  @include bp('sm') {
    font-size: 14px;
    padding: 16px 48px;
  }
}

.button--lowercase {
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  padding: 12px 15px;

  &:hover {
    background-color: darken($primary, 3%);
  }
}
</style>

import Vue from 'vue'

Vue.directive('loading', function (el, binding) {
  if (binding.value) {
    el.disabled = true
    el.classList.add('btn--loading')
  } else {
    el.disabled = false
    el.classList.remove('btn--loading')
  }
})

const state = () => ({
  keywords: [],
  suggested: [],
  selected: [],
})

const getters = {
  keywords: ({ keywords }) => keywords,
  suggested: ({ suggested }) => suggested,
  selected: ({ selected }) => selected,
}

const mutations = {
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  async load({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/keywords',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'keywords',
            value: res.keywords_paginated.keywords,
          })

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async search({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/keywords/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'keywords',
            value: res.keywords_paginated.keywords,
          })

          if (data && data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async suggested({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/keywords/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'suggested',
            value: res.keywords_paginated.keywords,
          })

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async set({ commit }, data) {
    const app = this
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/favorites/sync',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'keywords',
            value: [],
          })

          app.dispatch('keywords/get')

          if (data && data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async get({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        favoriteable_type: 'keywords',
      },
      path: '/favorites/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'selected',
            value: res.favorites,
          })

          if (data && data.cb) {
            data.cb(res.favorites)
          }
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"groupInfo","width":550,"height":"auto"},on:{"before-open":_vm.beforeOpen}},[_c('div',{staticClass:"modal__title"},[_vm._v("Group Information")]),_vm._v(" "),(_vm.users)?_c('div',{staticClass:"modal__content"},_vm._l((_vm.users),function(user){return _c('div',{key:`group-user-${user.id}`,staticClass:"users__user"},[_c('div',{staticClass:"users__image",class:{ 'users__image--empty': !user.default_profile_pic_url },style:([
          user.default_profile_pic_url
            ? {
                'background-image':
                  'url(\'' + user.default_profile_pic_url + '\')',
              }
            : '',
        ])}),_vm._v(" "),_c('div',{staticClass:"users__meta"},[_c('div',{staticClass:"users__name"},[_vm._v("\n          "+_vm._s(user.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"users__title"},[_vm._v("\n          "+_vm._s(user.job_title)+"\n        ")])])])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__buttons"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.$modal.hide('groupInfo')}}},[_vm._v("Ok")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"uploadReport",attrs:{"name":"uploadReport","height":"auto","width":550},on:{"before-open":_vm.beforeOpen}},[(_vm.error)?_c('ErrorAlert',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error)}})]):_vm._e(),_vm._v(" "),_c('h2',[_vm._v("Upload Report")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}}},[_c('Dropdown',{attrs:{"options":_vm.scopes,"optionLabel":"name","allowEmpty":false,"preselectFirst":true,"label":{
        text: 'Scope',
      },"name":"scope"},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}}),_vm._v(" "),_c('div',{staticClass:"report"},[_c('h3',[_vm._v("REPORT DOCUMENT")]),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:175'),expression:"'required|max:175'"}],attrs:{"id":"report_upload__caption","name":"report_upload__caption","placeholder":"Eg. 2019 Fall Report","data-vv-as":"caption","data-vv-scope":"uploadReport","label":{
          text: 'Title',
        },"error":_vm.errors.first('uploadReport.report_upload__caption')},model:{value:(_vm.caption),callback:function ($$v) {_vm.caption=$$v},expression:"caption"}}),_vm._v(" "),_c('TextInput',{directives:[{name:"validate",rawName:"v-validate",value:('max:350'),expression:"'max:350'"}],attrs:{"id":"report_upload__description","name":"report_upload__description","placeholder":"Eg. This report is about something specific...","data-vv-as":"description","data-vv-scope":"uploadReport","label":{
          text: 'Description (Displays only in the email notification)',
        },"error":_vm.errors.first('uploadReport.report_upload__description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),_c('div',{attrs:{"id":"report_upload"}},[_c('FileUpload',{directives:[{name:"validate",rawName:"v-validate",value:('required|ext:pdf,docx,doc,csv,xls,xlsx|size:10240'),expression:"'required|ext:pdf,docx,doc,csv,xls,xlsx|size:10240'"}],attrs:{"id":"report_upload","name":"report_upload","placeholder":"report.pdf","error":_vm.errors.first('uploadReport.report_upload'),"data-vv-as":"report","data-vv-scope":"uploadReport","label":{
            text: 'Report Upload',
          }},on:{"change":_vm.updateAttachments},model:{value:(_vm.report_upload),callback:function ($$v) {_vm.report_upload=$$v},expression:"report_upload"}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{attrs:{"id":"report_upload"}},[_c('h3',[_vm._v("REPORT COVER IMAGE")]),_vm._v(" "),_c('FileUpload',{directives:[{name:"validate",rawName:"v-validate",value:('ext:png,jpg,jpeg|size:10240'),expression:"'ext:png,jpg,jpeg|size:10240'"}],attrs:{"id":"report_upload__cover","name":"report_upload__cover","placeholder":"report-cover.png","error":_vm.errors.first('uploadReport.report_upload__cover'),"data-vv-as":"cover","data-vv-scope":"uploadReport","label":{
            text: 'Report Image',
          }},on:{"change":_vm.updateAttachments},model:{value:(_vm.cover),callback:function ($$v) {_vm.cover=$$v},expression:"cover"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"btn btn--inverse",on:{"click":function($event){return _vm.$modal.hide('uploadReport')}}},[_vm._v("\n        Close\n      ")]),_vm._v(" "),_c('button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.submitting),expression:"submitting"}],staticClass:"btn",attrs:{"type":"submit"}},[_vm._v("\n        Publish\n      ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
  get,
  intersection,
  has,
  size,
  cloneDeep,
  isEqual,
  isEmpty,
  omit,
  set,
  keys,
  pick,
} from 'lodash-es'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { v1 as uuidv1 } from 'uuid'

import TextArea from '~/components/form/TextArea'
import InputV2 from '~/components/form/InputV2'
import ActionButton from '~/components/ActionButton'
import Checkbox from '~/components/CheckboxV2'
import Dropdown from '~/components/form/DropdownV3'

import SvgAdd from '~/static/icons/round-add-24px.svg'
import SvgDelete from '~/static/icons/delete_outline_black_24dp.svg'

import ProjectEditModal from '~/components/project/modals/ProjectEditModal'
import { MODAL_NAMES as modalNames } from '~/pages/projects/helpers'
import { ProjectExtended } from '~/types/Project'

export type ProjectFields = keyof ProjectExtended

export type ProjectFieldPaths = Paths<ProjectExtended>

export const MODAL_NAMES = modalNames

export default Vue.extend({
  components: {
    TextArea,
    InputV2,
    ProjectEditModal,
    ActionButton,
    SvgAdd,
    SvgDelete,
    Dropdown,
    Checkbox,
  },
  inject: ['$validator'],
  props: {
    requiredFieldsCount: Number,
  },
  data() {
    return {
      values: {},
      apiErrors: {},
      showApiError: false,
      modalName: '',
      validationScope: '',
      title: '',
      subtitle: '',
      errorFields: null as null | string[], // defined as needed in component if error field names do not correspond to submit values
      initialValues: {},
      imageUpload: null as null | File[],
      listName: '', // The list of items in values to use for rearranging, deleting, etc
      listApiPrefix: '', // This is how we retrieve field-level errors about list items from the API.
      skipCloning: false, // set this to true to avoid populating prokect values in beforeOpen. "Roll your own" instead!
      apiErrorCode: 0,
    }
  },
  computed: {
    ...(mapGetters({
      project: 'projects/project',
    }) as {
      project(): ProjectExtended
    }),
    errorCount(): number {
      return get(this.$validator.errors, 'items.length') + size(this.apiErrors)
    },
    isDirty(): boolean {
      return !isEqual(this.values, this.initialValues)
    },
    showSortableIcon(): boolean {
      return Boolean(this.listItems.length > 1)
    },
    modalProps(): object {
      return {
        beforeOpen: this.beforeOpen,
        title: this.title,
        subtitle: this.subtitle,
        onSubmit: this.validateBeforeSubmit,
        errorCount: this.errorCount,
        showApiError: this.showApiError,
        modalName: this.modalName,
        requiredFieldsCount: this.requiredFieldsCount,
        isDirty: this.isDirty,
        closed: this.closed,
        apiErrorCode: this.apiErrorCode,
      }
    },
    showListItems(): boolean {
      return Boolean(this.listItems && this.listItems.length)
    },
    listItems(): any[] {
      return get(this.values, this.listName, [])
    },
    resolvedErrorFields(): string[] {
      if (this.errorFields) return this.errorFields

      if (this.listName && this.listItems) {
        const newItem = this.newItem()

        const newItemKeys = keys(newItem)

        const generatedErrorFields: string[] = []

        this.listItems.forEach((value, index) => {
          newItemKeys.forEach((key) => {
            generatedErrorFields.push(`${this.listApiPrefix}.${index}.${key}`)
          })
        })

        return generatedErrorFields
      }

      return Object.keys(this.values)
    },
  },
  watch: {
    isDirty(newVal) {
      if (newVal) window.onbeforeunload = this.confirmExit
      else window.onbeforeunload = null
    },
  },
  mounted() {
    if (!this.$parent) return
    this.$parent.$on('openEditModal', this.openEditModal)

    // Log any problems with the modal if in dev mode
    this.checkSetupIssues()
  },
  beforeDestroy() {
    if (!this.$parent) return

    this.$parent.$off('openEditModal', this.openEditModal)
  },
  methods: {
    confirmExit() {
      // This text will not be shown in most browsers but it's the thought that counts?
      return 'Your case study has unsaved changes. Are you sure you want to close the window?'
    },
    newItem() {
      return {}
    }, // defined as needed in component
    mutateValues() {}, // defined as needed in component
    /**
     * Use this to manipulate the contents of `value` properties/data
     * prior to making the API call to update the project
     * @function
     */
    updateDataForSubmit() {}, // defined as needed in component

    /**
     * Use this to override what gets sent to the API.
     * Otherwise, `values` will be used
     * @function
     */
    getCustomDataForSubmit() {
      return false
    }, // defined as needed in component
    openEditModal() {
      this.$modal.show(this.modalName)
    },
    setInitialValues() {
      this.initialValues = cloneDeep(this.values)
    },
    checkSetupIssues() {
      // Check that there are no issues with the listName
      if (!(process.env.NODE_ENV === 'development')) return
      if (this.listName && !get(this.values, this.listName)) {
        // eslint-disable-next-line no-console
        console.error(this.modalName, 'List name is not in modal values!')
      }

      if (this.listName) {
        if (!this.listApiPrefix || !get(this.values, this.listApiPrefix)) {
          // eslint-disable-next-line no-console
          console.error(this.modalName, 'List API prefix not in modal values!')
        }
        const newItem = this.newItem()
        if (isEmpty(newItem)) {
          // eslint-disable-next-line no-console
          console.error(this.modalName, 'newItem method is not set correctly')
        }
      }
    },
    handleDisplayErrors() {
      const fields = this.resolvedErrorFields
      const apiKeys = Object.keys({ ...this.apiErrors })

      // If apiErrors includes fields that match the value keys, they can be handled and displayed by the validator only
      if (intersection(apiKeys, fields).length) {
        this.showApiError = false
      } else {
        // If apiErrors does not include fields, the error cannot be handled and should display the generic API error
        this.showApiError = true
      }
    },
    getListItemErrors(item, index, property) {
      const frontendItemError = this.$validator.errors.first(
        `${this.validationScope}.item${item.temporary_id}`
      )

      if (frontendItemError) return frontendItemError

      const frontendItemFieldError = this.$validator.errors.first(
        `${this.validationScope}.item${item.temporary_id}.${property}`
      )

      if (frontendItemFieldError) return frontendItemFieldError

      if (this.showApiError) return ''

      // If API error comes back matching the values key, ex: 'links.0.text'
      return this.displayCustomFieldApiErrors(
        `${this.listApiPrefix}.${index}.${property}`
      )
    },
    mutateValuesForList() {
      let list = get(this.values, this.listName)
      if (list) {
        const newItem = this.newItem()
        const whitelistedKeys = keys(newItem)

        // Add temp id to[listName] for validation purposes
        list = list.map((item) => {
          const whitelistedItem = pick(item, whitelistedKeys)

          return {
            ...whitelistedItem,
            temporary_id: uuidv1(),
          }
        })

        set(this.values, this.listName, list)
      }
    },
    displayErrors(
      fieldName: ProjectFields | ProjectFieldPaths,
      apiFieldName?: string
    ) {
      if (!has(this.values, fieldName)) {
        // eslint-disable-next-line no-console
        console.error(
          'Trying to get validation errors on a non-existent field:',
          fieldName
        )
      }

      if (apiFieldName) {
        const apiError = this.displayCustomFieldApiErrors(apiFieldName)
        if (apiError) return apiError
      }

      return (
        this.$validator.errors.first(`${this.validationScope}.${fieldName}`) ||
        get(this.apiErrors, `${fieldName}[0]`)
      )
    },
    displayCustomFieldApiErrors(fieldName) {
      // When the API error field name does not match the veevalidate field name, use this method
      if (this.apiErrors?.[fieldName]?.[0]) {
        return this.apiErrors[fieldName][0]
      }
      return ''
    },
    beforeOpen() {
      // In some cases, we can avoid setup issues by doing this in mutateValues instead
      if (!this.skipCloning) {
        const valuesKeys = Object.keys(this.values)

        valuesKeys.forEach((key) => {
          const valueInModal = this.values[key]
          const valueInProject = cloneDeep(this.project[key])

          // We don't want to coerce empty strings to null. it causes validation issues with $validator
          // We also don't want to clone a project value that is null and overwrite the structure we've set up in values on the component levels
          if (
            (valueInProject ||
              valueInProject === false ||
              valueInProject === 0 ||
              valueInProject === '') &&
            !(valueInProject === null && valueInModal === '')
          ) {
            this.values[key] = cloneDeep(this.project[key])
          }
        })
      }

      if (this.mutateValues) {
        this.mutateValues()
      }

      // after custom mutateValues, alter for lists
      if (this.listName) {
        this.mutateValuesForList()
      }

      this.$validator.reset()
      this.apiErrors = {}
      this.showApiError = false
      this.setInitialValues()
    },
    closed() {
      // Triggered when the modal is closed
      // We want to reset values so that isDirty goes to false
      // and the listener on the window gets removed
      this.values = cloneDeep(this.initialValues)
    },
    reorderItems({ oldIndex, newIndex }) {
      if (!this.listItems || !this.listItems.length) return

      const movedItem = this.listItems.splice(oldIndex, 1)[0]
      this.listItems.splice(newIndex, 0, movedItem)
    },
    deleteItem(index) {
      if (this.listItems && this.listItems.length) {
        this.listItems.splice(index, 1)
      }
    },
    addItem() {
      const newItem = this.newItem()

      if (this.listItems) {
        this.listItems.push({
          ...newItem,
          temporary_id: uuidv1(),
        })
      }
    },
    // clear out any empty items from the list
    clearEmptyListItems(data) {
      if (this.listItems) {
        const emptyItem = this.newItem()

        let list = get(data || this.values, this.listName)

        list = list.map((item) => {
          return omit(item, 'temporary_id')
        })

        const filtered = list.filter((item) => {
          const itemToCompare = omit(item, 'temporary_id')

          return !isEqual(emptyItem, itemToCompare)
        })

        return filtered
      }
    },
    validateBeforeSubmit() {
      const app = this

      this.$emit('setLoading', true)

      this.apiErrors = {}
      this.showApiError = false

      this.$validator
        .validateAll(this.validationScope)
        .then(async (frontendValid) => {
          if (frontendValid) {
            await app.updateDataForSubmit()

            // after custom updateDataForSubmit, alter for lists
            let data = cloneDeep(app.values)

            const customData = this.getCustomDataForSubmit()

            if (customData) {
              data = customData
            }

            if (this.listName) {
              const filteredItems = this.clearEmptyListItems(customData)
              set(data, this.listName, filteredItems)
            }

            if (size(app.apiErrors) === 0) {
              const updateData = {
                id: app.project.id,
                data: data,
                cb: function () {
                  app.$modal.hide(app.modalName, { force: true })

                  app.$emit('setLoading', false)
                },
                error: function (res) {
                  app.apiErrors = res.errors
                  app.apiErrorCode = res.statusCode
                  app.handleDisplayErrors()
                  app.$emit('setLoading', false)
                },
              }

              app.$store.dispatch('projects/updateProject', updateData)
            }
          } else {
            this.$emit('setLoading', false)
          }
        })
    },
  },
})

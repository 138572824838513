<template>
  <div
    class="user"
    :class="{ 'user--small': card, 'user--inactive': user.inactive }"
    data-test="suggested-user-card"
  >
    <div class="user__follow disabled">
      <svgAddPerson />
    </div>
    <Favorite
      v-if="user.id !== curUser.id"
      class="user__follow"
      :active="user.current_user_is_follower"
      type="follow"
      :data="{
        data: {
          favoriteable_id: user.id,
          favoriteable_type: 'users',
        },
        cb: updateFollow,
      }"
    />

    <nuxt-link
      :to="'/users/' + (user.slug ? user.slug : user.id)"
      @click.native="trackClick(user.id)"
    >
      <div class="user__info">
        <Avatar
          :imgSrc="user.default_profile_pic_url"
          :userName="user.name"
          :inactive="user.inactive"
          :style="{ 'margin-right': '10px' }"
          :size="'70px'"
        />

        <div class="user__meta">
          <div v-if="user.name" class="user__name">
            <span class="name">{{ user.name }}</span>

            <div v-if="user.profile_badges_count" class="circle">
              <div class="circle__content">
                {{ user.profile_badges_count }}
              </div>
            </div>
          </div>

          <div class="user__title">
            <UserTitle :user="user" disableAffiliationLink />
          </div>

          <div
            data-test="suggested-user-card-location"
            class="user__location"
            :class="{ 'user__location--empty': !userLocation }"
          >
            <svgLocation />{{
              userLocation ? userLocation.address : 'No Location'
            }}
          </div>
        </div>
      </div>

      <div v-if="showTags" class="user__tags">
        <div class="user__tags-title">
          {{ user.role_value == 'city-partner' ? 'Priorities' : 'Expertise' }}:
        </div>

        <ul
          v-if="
            user.tags_categorized &&
            user.tags_categorized[1] &&
            user.tags_categorized[1].tags.length
          "
          class="users__tags-list"
        >
          <li
            v-for="tag in Object(user.tags_categorized[1]).tags.slice(0, 9)"
            :key="`suggested-tag-${tag.id}`"
          >
            {{ tag.label }}
          </li>

          <li v-if="Object(user.tags_categorized[1]).tags.length > 9">
            <b>and more</b>
          </li>
        </ul>

        <ul
          v-if="
            user.tags_categorized &&
            (!user.tags_categorized[1] || !user.tags_categorized[1].tags.length)
          "
          class="users__tags-list"
        >
          <li class="users__tags-item--none">
            No
            {{ user.role_value == 'city-partner' ? 'priorities' : 'expertise' }}
            provided.
          </li>
        </ul>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import Favorite from '~/components/Favorite'
import UserTitle from '~/components/UserTitle'
import Avatar from '~/components/user/Avatar'

import svgAddPerson from '~/static/icons/round-person_add-24px.svg'
import svgLocation from '~/static/icons/round-location_on-24px.svg'

export default {
  components: {
    Favorite,
    svgAddPerson,
    svgLocation,
    UserTitle,
    Avatar,
  },
  props: ['user', 'card', 'showTags', 'trackClick'],
  data() {
    return {
      curUser: this.$auth.user,
    }
  },
  computed: {
    userLocation: function () {
      const { user } = this
      const {
        location,
        default_affiliation,
        default_affiliation_location_address,
      } = user

      if (location) {
        return location
      }

      if (default_affiliation && default_affiliation.location) {
        return default_affiliation.location
      }

      if (default_affiliation_location_address)
        return { address: default_affiliation_location_address }

      return null
    },
  },
  methods: {
    updateFollow(res) {
      if (res.favorite) {
        this.$store.dispatch('users/updateFollow', {
          id: res.favorite.favoriteable_id,
          status: true,
        })
      } else {
        this.$store.dispatch('users/updateFollow', {
          id: res.favoriteable_id,
          status: false,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user {
  position: relative;
  display: flex;
  border-radius: 3px;

  a {
    width: 100%;
  }

  &:focus,
  &:hover {
    * {
      text-decoration: none;
    }
  }

  > a {
    background: #ffffff;
    border: 1px solid #ababab;
    border-radius: 3px;
    text-decoration: none;
  }
}

.user--inactive {
  opacity: 0.5;
}

.user__follow {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0 0 0 0;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #ababab;
  cursor: pointer;
  justify-content: center;
  z-index: 1;

  svg {
    width: 18px;
    height: 18px;
    fill: #ababab;
  }

  &:focus,
  &:hover,
  &.user__follow--active {
    svg {
      fill: #e39600;
    }
  }

  &.disabled {
    background: darken(#ffffff, 10%);

    &:focus,
    &:hover,
    &.user__follow--active {
      svg {
        fill: #ababab;
      }
    }
  }
}

.user__info {
  position: relative;
  display: flex;
  padding: 10px;
}

.user .type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 22px;
  height: 22px;
  padding: 2px;
  background: #ffffff;
  border-radius: 2px;
}

.user__meta {
  position: relative;
  padding-right: 30px;
  padding-bottom: 25px;
}

.user__name {
  // display: flex;
  margin: 0 0 5px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 23.5px;

  .name {
    margin-right: 5px;
  }
}

.user--small .user__name {
  font-size: 14px;
}

.circle {
  position: relative;
  top: -2px;
  display: inline-flex;
  // margin-left: 5px;
}

.circle__content {
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  background: #e39600;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: #ffffff;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
}

.user__title {
  margin: 0 0 5px;
  font-size: 10px;
}

.user--small .user__title {
  font-size: 10px;
}

.user__title--empty {
  color: #ababab;
}

.user__location {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 100px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}

// .user--small .user__location {
//   font-size: 10px;
// }

.user__location--empty {
  color: #ababab;

  svg {
    fill: #ababab;
  }
}

.user__tags {
  margin: 0 0 -5px;
  padding: 10px;
  border-top: 1px solid #ababab;
  font-size: 10px;
}

.user--small .user__tags {
  display: none;
}

.user__tags-title {
  margin: 0 0 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.users__tags-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  color: #50505d;
  list-style: none;
}

.users__tags-list li {
  width: 50%;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include bp('sm') {
    width: 32%;
    margin-right: 1%;
  }
}

.users__tags-item--none {
  width: 100%;
  color: #ababab;
  font-style: italic;
}
</style>

<template>
  <modal
    class="favorites scrollable"
    name="profileFavorites"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <form @submit.prevent="validateBeforeSubmit($event)">
      <div class="modal__content">
        <div class="modal__title">Favorites</div>

        <ul
          v-if="projects && projects.items.length"
          class="favorite__list favorite__list--projects"
        >
          <li
            v-for="project in projects.items"
            :key="`favorites-project-${project.id}`"
            class="project"
          >
            <div class="project__image-wrap">
              <Favorite
                :active="true"
                type="star"
                :data="{
                  data: {
                    favoriteable_id: project.id,
                    favoriteable_type: 'projects',
                  },
                }"
              />
              <nuxt-link
                :to="'/projects/' + project.slug"
                class="project__image"
                :style="[
                  project.default_project_image &&
                  project.default_project_image.url
                    ? {
                        'background-image':
                          'url(\'' + project.default_project_image.url + '\')',
                      }
                    : '',
                ]"
              />
            </div>

            <nuxt-link :to="'/projects/' + project.slug" class="project__info">
              <div v-if="project.name" class="project__name">
                {{ project.name }}
              </div>

              <div
                class="project__location"
                :class="{
                  'project__location--empty': !project.location_address,
                }"
              >
                {{
                  project.location_address
                    ? project.location_address
                    : 'No Location'
                }}
              </div>
            </nuxt-link>
          </li>
        </ul>

        <div
          v-if="
            projects.pagination.current_page != projects.pagination.last_page
          "
          class="favorites__show-more"
          @click="loadProjects"
        >
          Show More
        </div>

        <p v-if="!projects || !projects.items.length">
          You’re not following any projects yet.
          <br />
          You can head to the
          <nuxt-link class="modal__link" to="/projects"
            >Browse Projects</nuxt-link
          >
          page to find some.
        </p>

        <div class="modal__buttons">
          <div class="btn btn--clear" @click="$modal.hide('profileFavorites')">
            Cancel
          </div>

          <Submit text="Done" :loading="loading" />
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Submit from '~/components/form/Submit'
import Favorite from '~/components/Favorite'

export default {
  components: {
    Submit,
    Favorite,
  },
  inject: ['$validator'],
  data() {
    return {
      error: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      projects: 'favorites/projects',
    }),
  },
  watch: {
    $route: function () {
      this.$modal.hide('profileFavorites')
    },
  },
  methods: {
    async beforeOpen(event) {
      let app = this

      this.error = ''
    },
    beforeClose(event) {
      this.$store.dispatch('favorites/loadProjects', { reset: true })
    },
    validateBeforeSubmit() {
      let app = this
      let submitData = {}

      this.error = ''

      this.$validator.validateAll('following').then((x) => {
        if (x) {
          let updateData = {
            id: app.user.id,
            data: {},
            cb: function () {
              app.$modal.hide('profileFavorites')
              app.loading = false
            },
          }

          app.loading = true

          app.$store.dispatch('users/updateUser', updateData)
        }
      })
    },
    loadProjects() {
      this.$store.dispatch('favorites/loadProjects', {
        page: this.projects.pagination.current_page + 1,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

.modal__content {
  margin: 0;
}

.modal__title {
  color: #000000;
}

/deep/ .favorite {
  position: absolute;
  top: 5px;
  right: 15px;
}

.favorite__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.project {
  display: flex;
  margin-bottom: 20px;
}

.project__image-wrap {
  position: relative;
}

.project__image {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  flex-grow: 0;
  flex-shrink: 0;
}

.project__info {
  display: block;
}

.project__name {
  font-size: 18px;
}

.project__location {
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.project__location--empty {
  color: #ababab;
}

.favorites__show-more {
  margin-bottom: 20px;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
}

.modal__link {
  text-decoration: underline;
}
</style>

import Vue from 'vue'

const state = () => ({
  insights: {
    items: [],
    pagination: [],
  },
  post_comments: [],
  insight: {},
  statistics: {},
})

const getters = {
  insights: ({ insights }) => insights,
  comments: ({ post_comments }) => post_comments,
  insight: ({ insight }) => insight,
  statistics: ({ statistics }) => statistics,
}

const mutations = {
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
  updatePaginated(state, data) {
    if (
      !state[data.name] ||
      !state[data.name].items ||
      !state[data.name].items.length
    )
      return

    let matched = false

    state[data.name].items.forEach(function (curItem) {
      if (
        curItem.public_id
          ? curItem.public_id === data.value.public_id
          : curItem.postable_id === data.value.postable_id
      ) {
        let index = state[data.name].items.indexOf(curItem)
        matched = true

        if (data.fields && data.fields.length) {
          data.fields.forEach(function (field) {
            state[data.name].items[index][field] = data.value[field]
          })

          return
        }

        Vue.set(state[data.name].items, index, data.value)
      }
    })

    if (!matched) {
      Vue.set(state[data.name].items, state[data.name].items.length, data.value)
    }
  },
  deletePaginated(state, data) {
    state[data.name].items.forEach(function (curItem) {
      if (curItem.public_id == data.id) {
        const index = state[data.name].items.indexOf(curItem)

        state[data.name].items.splice(index, 1)
      }
    })
  },
  appendKeyedPaginated(state, data) {
    let allKeyed = data.value[data.type][data.name]

    if (
      state[data.name][data.id] &&
      state[data.name][data.id].items &&
      state[data.name][data.id].items.length &&
      !data.reset
    ) {
      allKeyed = state[data.name][data.id].items.concat(
        data.value[data.type][data.name]
      )
    }

    Vue.set(state[data.name], data.id, {
      items: allKeyed,
      pagination: data.value[data.type].pagination,
    })
  },
  updateKeyedPaginated(state, data) {
    if (state[data.name] && state[data.name][data.id]) {
      state[data.name][data.id].items.forEach(function (curItem) {
        if (curItem.public_id === data.value.public_id) {
          let index = state[data.name][data.id].items.indexOf(curItem)

          Vue.set(state[data.name][data.id].items, index, data.value)
        }
      })
    }
  },
  deleteKeyedPaginated(state, data) {
    state[data.name][data.post_id].items.forEach(function (curComment) {
      if (curComment.public_id == data.id) {
        const index = state[data.name][data.post_id].items.indexOf(curComment)

        state[data.name][data.post_id].items.splice(index, 1)
      }
    })
  },
  setValue(state, data) {
    if (data.path) {
      let target = state[data.name]

      data.path.forEach(function (step) {
        target = target[step]
      })

      target = data.value

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async createInsight({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/post/',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateInsight({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('updatePaginated', {
            name: 'insights',
            value: res.post,
            fields: ['text_formatted', 'text_plain', 'pinned'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteInsight({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('deletePaginated', {
            name: 'insights',
            id: res.post_id,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async sendNotifications({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {},
      path: '/post/' + data.id + '/send-notifications',
      cb: async function (res) {
        if (res.success) {
          commit('updatePaginated', {
            name: 'insights',
            value: {
              public_id: data.id,
              notifications_sent: 1,
            },
            fields: ['notifications_sent'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadStatistics({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/group/' + data.id + '/insights',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'statistics',
            value: res.insights,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

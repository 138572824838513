<template>
  <figure
    class="avatar"
    :class="{
      'avatar--empty': !imgSrc,
      'avatar--round': variant && variant === 'round',
      'avatar--deleted-user': deleted,
    }"
    :style="styles"
    data-test="avatar"
    role="img"
    :aria-label="`Avatar for ${userName || user.name}`"
  >
    <span v-if="!imgSrc && !deleted && initials" class="avatar__initials">
      {{ initials }}
    </span>
    <div v-if="inactive" class="avatar__inactive">Inactive</div>
    <missingLogoUser v-if="deleted" class="avatar__deleted-user-icon" />
  </figure>
</template>

<script>
import { get } from 'lodash-es'
import missingLogoUser from '~/static/icons/missing-logo-user.svg'

export default {
  components: {
    missingLogoUser,
  },
  props: ['imgSrc', 'variant', 'size', 'inactive', 'userName', 'deleted'],
  computed: {
    user() {
      return this.$auth.user
    },
    styles() {
      let result = {
        backgroundImage: this.imgSrc ? `url('${this.imgSrc}')` : 'none',
      }

      if (this.size) {
        result = {
          ...result,
          width: this.size,
          height: this.size,
        }
      }

      return result
    },
    initials() {
      const userName =
        get(this, 'userName', '') || (this.user && this.user.name)
      if (!userName) return ''

      let nameArray = userName.split(' ')
      let initials = ''

      nameArray.forEach(function (name) {
        if (initials.length > 1) return

        initials += name.substring(0, 1)
      })

      return initials
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ababab;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
}

.avatar--empty {
  background-size: 50%;
  display: flex;
  background: #595959;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.avatar--round {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #ffffff;
}

.avatar--deleted-user {
  background: #ababab;
}

.avatar__inactive {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px;
  background: #e6e6e6;
  border-radius: 2px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  color: #000;
}

.avatar__deleted-user-icon {
  padding: 3px;
}
</style>

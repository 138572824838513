var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea",class:_vm.modifiersBuilt},[(_vm.label)?_c('label',{staticClass:"label",class:_vm.labelModifiers,attrs:{"for":_vm.id}},[_vm._v("\n    "+_vm._s(_vm.label.text)),(_vm.label.optional)?_c('span',{staticClass:"optional"},[_vm._v(" - Optional")]):_vm._e(),_vm._v(" "),(_vm.label.required)?_c('span',{staticClass:"optional"},[_vm._v(" - Required")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"textarea__wrap"},[_c('client-only',[(_vm.tributeOptions)?_c('vue-tribute',{attrs:{"data-test":"textarea-vue-tribute","options":_vm.tributeOptions}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.curValue),expression:"curValue"}],staticClass:"textarea__elem",class:{
            'textarea__elem--error': _vm.error,
          },attrs:{"id":_vm.id,"name":_vm.name,"data-test":_vm.dataTest,"placeholder":_vm.placeholder},domProps:{"value":(_vm.curValue)},on:{"keydown":function($event){return _vm.charCount($event)},"keyup":_vm.textAreaAdjust,"input":[function($event){if($event.target.composing)return;_vm.curValue=$event.target.value},_vm.updateValue],"change":_vm.updateValue,"blur":function($event){return _vm.$emit('blur', $event)},"focus":_vm.focus,"tribute-replaced":_vm.replaced}})]):_vm._e()],1),_vm._v(" "),(!_vm.tributeOptions)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.curValue),expression:"curValue"}],staticClass:"textarea__elem",class:{
        'textarea__elem--error': _vm.error,
      },attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"data-test":_vm.dataTest},domProps:{"value":(_vm.curValue)},on:{"keydown":function($event){return _vm.charCount($event)},"keyup":_vm.textAreaAdjust,"input":[function($event){if($event.target.composing)return;_vm.curValue=$event.target.value},_vm.updateValue],"change":_vm.updateValue,"blur":function($event){return _vm.$emit('blur', $event)},"focus":_vm.focus}}):_vm._e(),_vm._v(" "),(_vm.maxlength)?_c('div',{staticClass:"textarea__count",class:{
        'textarea__count--error': (_vm.value ? _vm.value.length : 0) >= _vm.maxlength,
      }},[_c('span',{staticClass:"count-value"},[_vm._v(_vm._s(_vm.value ? _vm.value.length : 0))]),_vm._v("/"+_vm._s(_vm.maxlength)+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"form__error",attrs:{"data-error-message":""}},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
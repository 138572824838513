<template>
  <div
    class="tag-group"
    :class="{
      'form--error': error,
    }"
  >
    <label v-if="label" class="label" :class="labelModifiers">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
    </label>

    <multiselect
      ref="multiselectRef"
      v-model="curValue"
      data-test="multiselect-input"
      class="legacy-multiselect multiselect--tags"
      :class="className"
      :options="options"
      :placeholder="placeholder"
      tagPlaceholder=""
      :searchable="true"
      :show-labels="false"
      :showNoResults="false"
      :taggable="true"
      :multiple="true"
      :max="max"
      :label="keyTerm ? keyTerm : 'name'"
      track-by="id"
      open-direction="bottom"
      :custom-label="customLabel"
      @input="inputCb"
      @tag="addTag"
      @search-change="searchCb"
      @close="close"
    >
      <span slot="noOptions">Start typing...</span>
      <span v-if="maxElements" slot="maxElements">
        {{ maxElements }}
      </span>
    </multiselect>

    <div
      v-if="error"
      data-test="error-message"
      data-error-message
      class="form__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Multiselect,
  },
  props: {
    id: String,
    name: String,
    className: String,
    placeholder: String,
    value: Array,
    options: Array,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
    },
    cb: Function,
    search: Function,
    error: {
      type: String,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    customLabel: Function,
    keyTerm: String,
    addTag: {
      type: Function,
      default: function () {},
    },
    close: {
      type: Function,
      default: function () {},
    },
    maxElements: String,
  },
  data() {
    return {
      curTags: [],
    }
  },
  computed: {
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        return this.curTags.length ? this.curTags : this.value
      },
      set(newValue) {
        // console.log("TAG SET")
        // console.log(newValue)
        this.curTags = newValue
      },
    },
  },
  watch: {
    value: function (value) {
      this.curTags = value
    },
  },
  beforeDestroy() {
    if (this.$refs && this.$refs.multiselectRef) {
      this.$refs.multiselectRef.deactivate()
    }
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    inputCb() {
      if (this.cb)
        this.cb({
          group_id: this.id,
          tags: this.curTags,
          name: this.name,
        })
    },
    searchCb(value) {
      if (this.search) this.search(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.multiselect--tags {
  height: auto;
  min-height: 44px;

  /deep/ .multiselect__tags {
    display: flex;
    height: auto;
    min-height: 44px;
    align-items: center;
    flex-wrap: wrap;
  }

  /deep/ .multiselect__tags-wrap {
    padding-top: 5px;
    padding-left: 5px;
  }

  /deep/ .multiselect__tag {
    padding: 5px 20px 5px 10px;
    border-radius: 3px;
    color: #000000;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
  }

  /deep/ .multiselect__select {
    background-image: url('/icons/round-add-24px.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    &:before {
      border: none;
    }
  }

  &.multiselect--active {
    /deep/ .multiselect__select {
      transform: none;
    }
  }

  /deep/ .multiselect__tag-icon {
    line-height: 20px;

    &:after {
      color: #000000;
    }

    &:focus,
    &:hover {
      background: none;

      &:after {
        color: #000000;
      }
    }
  }
}

.form--error {
  margin: 0 0 20px;

  .multiselect {
    margin: 0 0 10px;

    /deep/ .multiselect__tags {
      border-color: #e33a00;
    }
  }
}

.multiselect--tags-green {
  /deep/ .multiselect__tag {
    background: #b8d7b1;
  }
}

.multiselect--tags-red {
  /deep/ .multiselect__tag {
    background: #e8cbae;
  }
}

.multiselect--tags-blue {
  /deep/ .multiselect__tag {
    background: #8fb6da;
  }
}

.multiselect--tags-purple {
  /deep/ .multiselect__tag {
    background: #c0b8b8;
  }
}

.multiselect--tags-grey {
  /deep/ .multiselect__tag {
    background: #e6e6e6;
    color: rgba(0, 0, 0, 0.75);
  }
}
</style>

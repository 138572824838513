import { render, staticRenderFns } from "./PageSeo.vue?vue&type=template&id=15259ad7&scoped=true"
import script from "./PageSeo.vue?vue&type=script&lang=js"
export * from "./PageSeo.vue?vue&type=script&lang=js"
import style0 from "./PageSeo.vue?vue&type=style&index=0&id=15259ad7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@2.7.16_babel-core@6.26.3_cache-loader@4.1.0_webpack@4.47_7frlxbdcecgiv56xeuvhoro4b4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15259ad7",
  null
  
)

export default component.exports
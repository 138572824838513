<template>
  <div
    class="location"
    :class="{
      'form--error': error,
      'form--disabled': disabled,
    }"
  >
    <label v-if="label" class="input__label" for="location">
      {{ label.text }}</label
    >
    <input
      ref="autocomplete"
      :placeholder="placeholder ? placeholder : 'Location'"
      class="location-search input__elem"
      :value="curValue"
      name="location"
      type="text"
      :disabled="disabled"
      @input="updateValue"
      @blur="$emit('blur')"
    />

    <div v-if="error" class="form__error">
      {{ error }}
    </div>

    <!-- DISABLED
    {{ disabled }} -->
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

export default {
  props: ['placeholder', 'value', 'error', 'disabled', 'label'],
  emits: ['input', 'blur'],
  computed: {
    curValue: {
      get() {
        return this.value ? this.value.address : ''
      },
      set(newValue) {},
    },
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAPS_KEY,
      libraries: ['places'],
    })

    await loader.load()

    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ['geocode'] }
    )

    this.autocomplete.addListener('place_changed', () => {
      let place = this.autocomplete.getPlace()

      if (!place.address_components) return

      let ac = place.address_components
      let lat = place.geometry.location.lat()
      let lon = place.geometry.location.lng()
      let city = ac[0]['short_name']

      this.$root.$emit('locationChange', {
        id: place.place_id,
        address: place.formatted_address,
      })

      // console.log(`The user picked ${city} with the coordinates ${lat}, ${lon}`)
    })
  },
  methods: {
    updateValue($event) {
      // console.log("UPDATE VALUE INPUT")
      // console.log(this.value)
      this.$emit('input', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-search:disabled {
  opacity: 0.3;
  border-color: #50505d;

  + .form__error {
    display: none;
  }
}

.input__label {
  display: block;
}
</style>

<template>
  <modal
    name="projectAlert"
    :clickToClose="false"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form>
      <div class="modal__title">Add Case Study Unavailable</div>

      <div class="modal__content" v-html="content[type]" />

      <div class="modal__buttons">
        <div class="btn" @click="$modal.hide('projectAlert')">OK</div>
      </div>
    </form>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      type: '',
      content: {
        'city-partner':
          '<p>You\'re not able to take certain actions (e.g add a project) until your local government affiliation has been verified. Please keep your eye on your email for that verification! Until then, reach out to <a href="mailto:ellory@the-atlas.com">ellory@the-atlas.com</a> with any questions.</p>',
        stakeholder:
          '<p>You\'re not able to take certain actions (e.g add a project) until your organization has been verified. Please keep your eye on your email for that verification! Until then, reach out to <a href="mailto:elle@the-atlas.com">elle@the-atlas.com</a> with any questions.</p>',
        'implementing-partner': '',
      },
    }
  },
  methods: {
    beforeOpen(event) {
      this.type = event.params.type
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.modal__content {
  line-height: 1.5;
}
</style>

import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=489c93cb&scoped=true&lang=html"
import script from "./loading.vue?vue&type=script&lang=js"
export * from "./loading.vue?vue&type=script&lang=js"
import style0 from "./loading.vue?vue&type=style&index=0&id=489c93cb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@2.7.16_babel-core@6.26.3_cache-loader@4.1.0_webpack@4.47_7frlxbdcecgiv56xeuvhoro4b4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489c93cb",
  null
  
)

export default component.exports
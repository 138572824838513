<template>
  <div>
    <Unsupported />

    <PageHeader />

    <Ama v-if="user && user.current_event_participant" />

    <nuxt />

    <PageFooter />

    <ProjectAlert />
    <ProjectImage />
    <DeleteConfirm />
    <JoinPrompt />
    <Badges />
    <Participants v-if="$auth.loggedIn" />
    <OldTabWarning />
  </div>
</template>

<script>
import PageHeader from '~/components/Header'
import PageFooter from '~/components/Footer'
import Unsupported from '~/components/banners/Unsupported'
import Ama from '~/components/banners/Ama'

import ProjectAlert from '~/components/modals/ProjectAlert'
import ProjectImage from '~/components/modals/ProjectImage'
import DeleteConfirm from '~/components/modals/DeleteConfirm'
import JoinPrompt from '~/components/modals/JoinPrompt'
import Badges from '~/components/modals/profile/Badges'
import Participants from '~/components/modals/Participants'
import OldTabWarning from '~/components/modals/OldTabWarning'
import { xor } from 'lodash-es'

export default {
  components: {
    PageHeader,
    PageFooter,
    Unsupported,
    Ama,
    ProjectAlert,
    ProjectImage,
    DeleteConfirm,
    JoinPrompt,
    Badges,
    Participants,
    OldTabWarning,
  },
  data() {
    return {
      openModals: [],
    }
  },
  computed: {
    user() {
      return this.$auth.user
    },
  },
  watch: {
    $route: function (to, from) {
      this.allowBodyScroll(true)
    },
    openModals(newVal, oldVal) {
      // If we opened the first modal, prevent scrolling
      if (newVal.length > 0 && oldVal.length === 0) {
        this.allowBodyScroll(false)
      }

      if (newVal.length === 0) {
        this.allowBodyScroll(true)
      }
    },
  },
  mounted() {
    this.$modalEvents.$on('toggle', this.handleModalToggled)
  },
  beforeDestroy() {
    this.$modalEvents.$off('toggle', this.handleModalToggled)
  },
  methods: {
    handleModalToggled(modalName, open) {
      if (!open) {
        // If it's sent as closed, remove it from the list
        this.openModals = this.openModals.filter((name) => name != modalName)
        return
      }

      // Add the modal name to the list
      this.openModals = xor(this.openModals, [modalName])
    },
    allowBodyScroll(value) {
      const docElement = document.documentElement

      if (!docElement) return

      // Setting this attribute will disable scroll via CSS
      if (!value) {
        docElement.setAttribute('DisableScroll', 'true')
      } else {
        docElement.removeAttribute('DisableScroll')
      }
    },
  },
}
</script>

<style lang="scss">
#__nuxt {
  background-color: #ffffff;
}

html {
  background-color: $midnight;
}

.main-content {
  padding: 30px 0 50px;

  @include bp('md') {
    padding: 60px 0 100px;
  }
}
</style>

<template>
  <div data-test="checkbox" class="checkbox">
    <label :for="name" :class="buildLabelModifier(modifier)">
      <input
        :id="name"
        v-model="checked"
        type="checkbox"
        :name="name"
        :value="val"
        data-test="checkbox-input"
        @change="onChange"
      />
      <span class="checkbox__box">
        <span class="checkbox__check">
          <svgCheck />
        </span>
      </span>
      <span data-test="checkbox-label" class="checkbox__title" v-html="title" />
    </label>
  </div>
</template>

<script>
import svgCheck from '~/static/icons/round-check-24px.svg'

export default {
  components: {
    svgCheck,
  },
  props: ['name', 'title', 'modifier', 'cb', 'value', 'val'],
  emits: ['input'],
  data() {
    return {
      checkedProxy: false,
    }
  },
  computed: {
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.checkedProxy = val
      },
    },
  },
  methods: {
    onChange: function ($event) {
      this.$emit('input', this.checkedProxy)

      if (this.cb) {
        this.cb($event)
      }
    },
    buildLabelModifier(modifier) {
      if (modifier) {
        modifier = 'checkbox__label--' + modifier

        return modifier
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;

  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:checked + .checkbox__box {
    background: $primary-600;
    border-color: $primary-600;
  }

  &:checked + .checkbox__box .checkbox__check {
    display: block;
  }

  &:focus + .checkbox__box {
    outline: 2px solid Highlight;
    outline: 2px solid -webkit-focus-ring-color;
    outline-offset: 1px;
  }

  &:focus:not(:focus-visible) + .checkbox__box {
    outline: none;
  }

  // Could not get this to trigger normally but seems to be the way to go per
  // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible#selectively_showing_the_focus_indicator
  &:focus-visible + .checkbox__box {
    outline: 2px solid Highlight;
    outline: 2px solid -webkit-focus-ring-color;
    outline-offset: 1px;
  }
}

label {
  display: flex;
  position: relative;
  align-items: center;
  color: #000000;
  cursor: pointer;
}

.checkbox__box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  border: solid 1px $black;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  pointer-events: none;
}

.checkbox__check {
  display: none;
  width: 20px;
  height: 20px;
  margin: -1px 0 0 -1px;

  svg {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}

.checkbox__title {
  display: block;
}
</style>

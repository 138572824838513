import Vue from 'vue'

const state = () => ({
  event: null,
  banner: null,
  attendees: [],
})

const getters = {
  event: ({ event }) => event,
  banner: ({ banner }) => banner,
  attendees: ({ attendees }) => attendees,
}

const mutations = {
  setValue(state, data) {
    state[data.name] = data.value
  },
}

const actions = {
  async loadEvent({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/event/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'event',
            value: res.event,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadBanner({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/event/' + data.id,
      headers: {
        Format: 'banner_text',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'banner',
            value: res.banner_text,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAttendees({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/event/' + data.id + '/attendees',
      headers: {
        Format: 'mentions',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'attendees',
            value: res.users,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import { render, staticRenderFns } from "./linkedin-icon.svg?vue&type=template&id=452d7481"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@2.7.16_babel-core@6.26.3_cache-loader@4.1.0_webpack@4.47_7frlxbdcecgiv56xeuvhoro4b4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
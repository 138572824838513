var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user",class:{ 'user--small': _vm.card, 'user--inactive': _vm.user.inactive },attrs:{"data-test":"suggested-user-card"}},[_c('div',{staticClass:"user__follow disabled"},[_c('svgAddPerson')],1),_vm._v(" "),(_vm.user.id !== _vm.curUser.id)?_c('Favorite',{staticClass:"user__follow",attrs:{"active":_vm.user.current_user_is_follower,"type":"follow","data":{
      data: {
        favoriteable_id: _vm.user.id,
        favoriteable_type: 'users',
      },
      cb: _vm.updateFollow,
    }}}):_vm._e(),_vm._v(" "),_c('nuxt-link',{attrs:{"to":'/users/' + (_vm.user.slug ? _vm.user.slug : _vm.user.id)},nativeOn:{"click":function($event){return _vm.trackClick(_vm.user.id)}}},[_c('div',{staticClass:"user__info"},[_c('Avatar',{style:({ 'margin-right': '10px' }),attrs:{"imgSrc":_vm.user.default_profile_pic_url,"userName":_vm.user.name,"inactive":_vm.user.inactive,"size":'70px'}}),_vm._v(" "),_c('div',{staticClass:"user__meta"},[(_vm.user.name)?_c('div',{staticClass:"user__name"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.user.name))]),_vm._v(" "),(_vm.user.profile_badges_count)?_c('div',{staticClass:"circle"},[_c('div',{staticClass:"circle__content"},[_vm._v("\n              "+_vm._s(_vm.user.profile_badges_count)+"\n            ")])]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"user__title"},[_c('UserTitle',{attrs:{"user":_vm.user,"disableAffiliationLink":""}})],1),_vm._v(" "),_c('div',{staticClass:"user__location",class:{ 'user__location--empty': !_vm.userLocation },attrs:{"data-test":"suggested-user-card-location"}},[_c('svgLocation'),_vm._v(_vm._s(_vm.userLocation ? _vm.userLocation.address : 'No Location')+"\n        ")],1)])],1),_vm._v(" "),(_vm.showTags)?_c('div',{staticClass:"user__tags"},[_c('div',{staticClass:"user__tags-title"},[_vm._v("\n        "+_vm._s(_vm.user.role_value == 'city-partner' ? 'Priorities' : 'Expertise')+":\n      ")]),_vm._v(" "),(
          _vm.user.tags_categorized &&
          _vm.user.tags_categorized[1] &&
          _vm.user.tags_categorized[1].tags.length
        )?_c('ul',{staticClass:"users__tags-list"},[_vm._l((Object(_vm.user.tags_categorized[1]).tags.slice(0, 9)),function(tag){return _c('li',{key:`suggested-tag-${tag.id}`},[_vm._v("\n          "+_vm._s(tag.label)+"\n        ")])}),_vm._v(" "),(Object(_vm.user.tags_categorized[1]).tags.length > 9)?_c('li',[_c('b',[_vm._v("and more")])]):_vm._e()],2):_vm._e(),_vm._v(" "),(
          _vm.user.tags_categorized &&
          (!_vm.user.tags_categorized[1] || !_vm.user.tags_categorized[1].tags.length)
        )?_c('ul',{staticClass:"users__tags-list"},[_c('li',{staticClass:"users__tags-item--none"},[_vm._v("\n          No\n          "+_vm._s(_vm.user.role_value == 'city-partner' ? 'priorities' : 'expertise')+"\n          provided.\n        ")])]):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Unsupported />

    <PageHeader />

    <Ama v-if="user && user.current_event_participant" />

    <div class="page__content">
      <UserSidebar />

      <div class="page__main">
        <nuxt />
      </div>
    </div>

    <PageFooter />

    <ImageUpload />

    <Edit />
    <Badges />
    <About />
    <InterestsModal v-if="fullUser" />
    <Thought />
    <Following />
    <Favorites />
    <NewConversation />
    <GroupInfo />

    <div v-if="impersonating && user" class="impersonating">
      <div class="impersonating__close" @click="removeImpersonating">
        <svgClose />
      </div>

      You are logged in as <b>{{ user.name }}</b
      >, a {{ user.role_value }} user - please log in again to return to admin.
      Use caution, all activity is recorded as this user.
    </div>

    <OldTabWarning />
  </div>
</template>

<script>
import PageHeader from '~/components/Header.vue'
import PageFooter from '~/components/Footer.vue'
import Unsupported from '~/components/banners/Unsupported.vue'
import Ama from '~/components/banners/Ama.vue'
import UserSidebar from '~/components/user/UserSidebar.vue'

import ImageUpload from '~/components/modals/ImageUpload'

import Edit from '~/components/modals/profile/Edit'
import Badges from '~/components/modals/profile/Badges'
import About from '~/components/modals/profile/About'
import InterestsModal from '~/components/modals/Interests'
import Thought from '~/components/modals/profile/Thought'
import Following from '~/components/modals/profile/Following'
import Favorites from '~/components/modals/profile/Favorites'
import NewConversation from '~/components/modals/NewConversation'
import GroupInfo from '~/components/modals/GroupInfo'
import OldTabWarning from '~/components/modals/OldTabWarning'

import svgClose from '~/static/icons/round-close-24px.svg'

import { mapGetters } from 'vuex'

export default {
  components: {
    PageHeader,
    PageFooter,
    Unsupported,
    Ama,
    UserSidebar,
    ImageUpload,
    Edit,
    Badges,
    About,
    InterestsModal,
    Thought,
    Following,
    Favorites,
    NewConversation,
    GroupInfo,
    svgClose,
    OldTabWarning,
  },
  data() {
    return {
      // This would normally be computed but it triggers an error with a component downstream.
      // seems safe here bc we're already logged in
      user: this.$auth.user,
    }
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
      // This will only become available once the page using this layout calls the getter
      // layouts cannot asynchronously call fetch in a page blocking way per https://nuxtjs.org/docs/2.x/features/data-fetching/#the-fetch-hook
      fullUser: 'users/user',
    }),
  },
  methods: {
    removeImpersonating() {
      this.$store.dispatch('authHelpers/setImpersonating', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.page__content {
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 10px;

  @include bp('md') {
    padding: 100px 10px;
    display: flex;
    justify-content: space-between;
  }
}

.page__main {
  width: 100%;
  max-width: 740px;

  @include bp('md') {
    width: 66%;
  }
}

.page__sidebar {
  min-width: 300px;
  max-width: 320px;
}

.impersonating {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 40px 15px 15px;
  background: #000000;
  color: #ffffff;
  text-align: center;
  z-index: 100;
}

.impersonating__close {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -12px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }
}
</style>

<template>
  <button
    class="toggle-button"
    data-test="toggle-button"
    :class="{ 'toggle-button--active': on }"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    on: Boolean,
  },
  emits: ['click'],
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-button {
  color: $primary-600;

  border-radius: 22px;
  margin: 0 10px 10px 0;
  padding: 8px 16px;
  border: 1px solid $primary-600;

  transition: 0.2s all ease-in;

  appearance: none;
  outline: none;
  cursor: pointer;

  &:focus {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.toggle-button--active {
  color: $primary-700;
  background-color: $aa-light-blue;
  border-color: $primary-700;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fb453944 = () => interopDefault(import('../pages/company-partners/index.vue' /* webpackChunkName: "pages/company-partners/index" */))
const _6e6b84f7 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _bb345cae = () => interopDefault(import('../pages/partner-companies/index.vue' /* webpackChunkName: "pages/partner-companies/index" */))
const _157cf11d = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _0dcdacb4 = () => interopDefault(import('../pages/admin/affiliations/index.vue' /* webpackChunkName: "pages/admin/affiliations/index" */))
const _46dd9bfe = () => interopDefault(import('../pages/admin/insights/index.vue' /* webpackChunkName: "pages/admin/insights/index" */))
const _50cb81dd = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _65276d5b = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _50a4db96 = () => interopDefault(import('../pages/projects/helpers.ts' /* webpackChunkName: "pages/projects/helpers" */))
const _2a65a932 = () => interopDefault(import('../pages/users/community.vue' /* webpackChunkName: "pages/users/community" */))
const _2028165d = () => interopDefault(import('../pages/users/dashboard.vue' /* webpackChunkName: "pages/users/dashboard" */))
const _0ce5e932 = () => interopDefault(import('../pages/users/login.vue' /* webpackChunkName: "pages/users/login" */))
const _1791139e = () => interopDefault(import('../pages/users/notifications.vue' /* webpackChunkName: "pages/users/notifications" */))
const _663d3b32 = () => interopDefault(import('../pages/users/profile.vue' /* webpackChunkName: "pages/users/profile" */))
const _39823822 = () => interopDefault(import('../pages/users/request-password-reset.vue' /* webpackChunkName: "pages/users/request-password-reset" */))
const _3af117ea = () => interopDefault(import('../pages/users/settings.vue' /* webpackChunkName: "pages/users/settings" */))
const _706e57dc = () => interopDefault(import('../pages/users/onboarding/interests.vue' /* webpackChunkName: "pages/users/onboarding/interests" */))
const _6d05721b = () => interopDefault(import('../pages/users/onboarding/job-title.vue' /* webpackChunkName: "pages/users/onboarding/job-title" */))
const _64035c71 = () => interopDefault(import('../pages/onboarding/partnerships/_slug/index.vue' /* webpackChunkName: "pages/onboarding/partnerships/_slug/index" */))
const _21f70a78 = () => interopDefault(import('../pages/users/conversations/_id.vue' /* webpackChunkName: "pages/users/conversations/_id" */))
const _51f948a4 = () => interopDefault(import('../pages/users/create-password/_token.vue' /* webpackChunkName: "pages/users/create-password/_token" */))
const _f35b2a8e = () => interopDefault(import('../pages/users/reset-password/_token.vue' /* webpackChunkName: "pages/users/reset-password/_token" */))
const _41ad42cb = () => interopDefault(import('../pages/admin/affiliations/_slug/edit.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/edit" */))
const _104a46fe = () => interopDefault(import('../pages/admin/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/projects/index" */))
const _7ebbb59e = () => interopDefault(import('../pages/admin/affiliations/_slug/team.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/team" */))
const _9023ef94 = () => interopDefault(import('../pages/admin/users/_slug/edit.vue' /* webpackChunkName: "pages/admin/users/_slug/edit" */))
const _c9e78bf0 = () => interopDefault(import('../pages/admin/users/_slug/permissions.vue' /* webpackChunkName: "pages/admin/users/_slug/permissions" */))
const _650dcd82 = () => interopDefault(import('../pages/access/_slug/index.vue' /* webpackChunkName: "pages/access/_slug/index" */))
const _04a2e019 = () => interopDefault(import('../pages/company-partners/_slug/index.vue' /* webpackChunkName: "pages/company-partners/_slug/index" */))
const _3d51ef37 = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _46596bde = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _13c64fd5 = () => interopDefault(import('../pages/projects/_slug.vue' /* webpackChunkName: "pages/projects/_slug" */))
const _2867fdd4 = () => interopDefault(import('../pages/users/_slug/index.vue' /* webpackChunkName: "pages/users/_slug/index" */))
const _6c8c585c = () => interopDefault(import('../pages/affiliations/_slug/insights.vue' /* webpackChunkName: "pages/affiliations/_slug/insights" */))
const _4df70efa = () => interopDefault(import('../pages/affiliations/_slug/overview.vue' /* webpackChunkName: "pages/affiliations/_slug/overview" */))
const _18620fbe = () => interopDefault(import('../pages/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/affiliations/_slug/projects/index" */))
const _5b936e5e = () => interopDefault(import('../pages/affiliations/_slug/team.vue' /* webpackChunkName: "pages/affiliations/_slug/team" */))
const _5855113c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company-partners",
    component: _fb453944,
    name: "company-partners"
  }, {
    path: "/media",
    component: _6e6b84f7,
    name: "media"
  }, {
    path: "/partner-companies",
    component: _bb345cae,
    name: "partner-companies"
  }, {
    path: "/projects",
    component: _157cf11d,
    name: "projects"
  }, {
    path: "/admin/affiliations",
    component: _0dcdacb4,
    name: "admin-affiliations"
  }, {
    path: "/admin/insights",
    component: _46dd9bfe,
    name: "admin-insights"
  }, {
    path: "/admin/projects",
    component: _50cb81dd,
    name: "admin-projects"
  }, {
    path: "/admin/users",
    component: _65276d5b,
    name: "admin-users"
  }, {
    path: "/projects/helpers",
    component: _50a4db96,
    name: "projects-helpers"
  }, {
    path: "/users/community",
    component: _2a65a932,
    name: "users-community"
  }, {
    path: "/users/dashboard",
    component: _2028165d,
    name: "users-dashboard"
  }, {
    path: "/users/login",
    component: _0ce5e932,
    name: "users-login"
  }, {
    path: "/users/notifications",
    component: _1791139e,
    name: "users-notifications"
  }, {
    path: "/users/profile",
    component: _663d3b32,
    name: "users-profile"
  }, {
    path: "/users/request-password-reset",
    component: _39823822,
    name: "users-request-password-reset"
  }, {
    path: "/users/settings",
    component: _3af117ea,
    name: "users-settings"
  }, {
    path: "/users/onboarding/interests",
    component: _706e57dc,
    name: "users-onboarding-interests"
  }, {
    path: "/users/onboarding/job-title",
    component: _6d05721b,
    name: "users-onboarding-job-title"
  }, {
    path: "/onboarding/partnerships/:slug",
    component: _64035c71,
    name: "onboarding-partnerships-slug"
  }, {
    path: "/users/conversations/:id?",
    component: _21f70a78,
    name: "users-conversations-id"
  }, {
    path: "/users/create-password/:token?",
    component: _51f948a4,
    name: "users-create-password-token"
  }, {
    path: "/users/reset-password/:token?",
    component: _f35b2a8e,
    name: "users-reset-password-token"
  }, {
    path: "/admin/affiliations/:slug/edit",
    component: _41ad42cb,
    name: "admin-affiliations-slug-edit"
  }, {
    path: "/admin/affiliations/:slug/projects",
    component: _104a46fe,
    name: "admin-affiliations-slug-projects"
  }, {
    path: "/admin/affiliations/:slug/team",
    component: _7ebbb59e,
    name: "admin-affiliations-slug-team"
  }, {
    path: "/admin/users/:slug/edit",
    component: _9023ef94,
    name: "admin-users-slug-edit"
  }, {
    path: "/admin/users/:slug/permissions",
    component: _c9e78bf0,
    name: "admin-users-slug-permissions"
  }, {
    path: "/access/:slug",
    component: _650dcd82,
    name: "access-slug"
  }, {
    path: "/company-partners/:slug",
    component: _04a2e019,
    name: "company-partners-slug"
  }, {
    path: "/events/:slug",
    component: _3d51ef37,
    name: "events-slug"
  }, {
    path: "/posts/:id",
    component: _46596bde,
    name: "posts-id"
  }, {
    path: "/projects/:slug",
    component: _13c64fd5,
    name: "projects-slug"
  }, {
    path: "/users/:slug",
    component: _2867fdd4,
    name: "users-slug"
  }, {
    path: "/affiliations/:slug?/insights",
    component: _6c8c585c,
    name: "affiliations-slug-insights"
  }, {
    path: "/affiliations/:slug?/overview",
    component: _4df70efa,
    name: "affiliations-slug-overview"
  }, {
    path: "/affiliations/:slug?/projects",
    component: _18620fbe,
    name: "affiliations-slug-projects"
  }, {
    path: "/affiliations/:slug?/team",
    component: _5b936e5e,
    name: "affiliations-slug-team"
  }, {
    path: "/",
    component: _5855113c,
    name: "index"
  }, {
    path: "/posts/:id/comment/:slug",
    component: _46596bde,
    name: "Single Post Page Comment"
  }, {
    path: "/affiliations/:affiliationId/projects/:id/edit",
    component: _13c64fd5,
    props: {"editing":true},
    name: "Edit Project"
  }, {
    path: "/admin/projects/:id/edit",
    component: _13c64fd5,
    props: {"editing":true},
    name: "Admin Edit Project Temp"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

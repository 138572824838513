import Vue from 'vue'
import {
  Validator,
  install as VeeValidate,
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import {
  required,
  regex,
  url,
  max,
  min,
  email,
  ext,
  size,
  confirmed,
} from 'vee-validate/dist/rules.esm.js'
import veeEn from 'vee-validate/dist/locale/en'

// Add the rules you need.
Validator.extend('required', required)
Validator.extend('max', max)
Validator.extend('regex', regex)
Validator.extend('url', url)
Validator.extend('min', min)
Validator.extend('email', email)
Validator.extend('ext', ext)
Validator.extend('size', size)
Validator.extend('confirmed', confirmed)

// Merge the messages.
Validator.localize('en', veeEn)

// install the plugin
Vue.use(VeeValidate, {
  inject: false,
  lang: 'en',
  events: 'blur',
  ssr: false,
})

export default ({ app }, inject) => {
  app.$validator = VeeValidate.Validator
}

import Vue from 'vue'

const state = () => ({
  projects: {
    items: [],
    pagination: [],
  },
  project: null,
  tags: null,
  challengeTags: [],
  contacts: [],
  collaborators: [],
  project_image: '',
  rfp: '',
  featured: [],
  pending: {
    items: [],
    pagination: [],
  },
})

const getters = {
  projects: ({ projects }) => projects,
  project: ({ project }) => project,
  tags: ({ tags }) => tags,
  challengeTags: ({ challengeTags }) => challengeTags,
  contacts: ({ contacts }) => contacts,
  collaborators: ({ collaborators }) => collaborators,
  uploadStatus: ({ project_image }) => project_image,
  uploadStatusRfp: ({ rfp }) => rfp,
  featured: ({ featured }) => featured,
  pending: ({ pending }) => pending,
}

const mutations = {
  setPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination
    state[name].items = data.value[data.type][data.name]
  },
  setValue(state, data) {
    // console.log("setValue")
    // console.log(data)
    if (data.path) {
      let target = state[data.name]

      // if (data.path.length > 1) {
      //   data.path.forEach(function (step) {
      //     if (data.path.indexOf(step) === data.path.length - 1) return
      //     target = target[step]
      //   })
      // }
      // console.log("TARGET")
      // console.log(target)
      // target = data.value

      // console.log("TARGET AFTER")
      // console.log(target)
      // console.log("BASE")
      // console.log(state[data.name])

      Vue.set(state[data.name], data.path[data.path.length - 1], data.value)

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadProjects({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data && data.filters ? data.filters : {},
      path:
        '/projects/filter' + (data && data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'projects',
            value: res,
            reset: data && data.reset ? true : false,
            type: 'projects_paginated',
          })

          if (data && data.cb) data.cb(res)
        } else {
          if (data && data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadProject({ commit }, data) {
    let requestData = {
      method: 'get',
      data: data.filters ? data.filters : {},
      path: '/project/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.project,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  clearProject({ commit }) {
    commit('setValue', {
      name: 'project',
      value: null,
    })
  },
  async updateProject({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/project/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.project,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createProject({ commit }, data) {
    let app = this

    let requestData = {
      method: 'post',
      data: data || {},
      path: '/project',
      cb: async function (res) {
        if (res.success) {
          app.$router.push({
            path:
              '/affiliations/' +
              res.project.affiliation_id +
              '/projects/' +
              res.project.id +
              '/edit',
          })

          if (data && data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadFeatured({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        last_published: true,
      },
      path: '/projects/filter?page=1',
      headers: {
        Format:
          'appends:tags_categorized,default_project_image_url,location_address|paginate:3',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'featured',
            value: res.projects_paginated.projects,
          })

          // if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadTags({ commit }) {
    let tags = []

    let response1 = await this.$axios.get('/tag-group/' + 1)
    tags.push(response1.data.tag_group)
    let response2 = await this.$axios.get('/tag-group/' + 2)
    tags.push(response2.data.tag_group)
    let response3 = await this.$axios.get('/tag-group/' + 3)
    tags.push(response3.data.tag_group)
    let response8 = await this.$axios.get('/tag-group/' + 8)
    tags.push(response8.data.tag_group)

    commit('setValue', {
      name: 'tags',
      value: tags,
    })
  },
  async loadChallengeTags({ commit }) {
    const response = await this.$axios.get('/tag-group/' + 1)

    commit('setValue', {
      name: 'challengeTags',
      value: response.data.tag_group.tags,
    })
  },
  async loadContacts({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/group/' + data.id + '/users/contactable',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'contacts',
            value: res.users_contactable,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadCollaborators({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/groups/collaboratables',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'collaborators',
            value: res.affiliation_collaboratables,
          })

          // if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addAttachment({ commit }, data) {
    commit('setValue', {
      name: data.type,
      value: 'Loading...',
    })

    let requestData = {
      method: 'post',
      data: data.data,
      path: '/attachment',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: data.type,
            value: 'Completed!',
          })

          commit('setValue', {
            name: 'project',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        } else {
          commit('setValue', {
            name: data.type,
            value:
              res.errors && res.errors.file && res.errors.file.length
                ? res.errors.file[0]
                : res.message,
          })

          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteAttachment({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/attachment/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async setDefault({ commit }, data) {
    let token = this.getters['auth/token']

    const response = await this.$axios({
      method: 'patch',
      data: {
        default_for_role: true,
      },
      path: '/attachment/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        }
      },
    })

    commit('setProjectImages', response.data)
  },
  async orderAttachments({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/attachments/reorder',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async defaultAttachment({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: {
        default_for_role: true,
      },
      path: '/attachment/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'project',
            value: res.attachments_sorted,
            path: ['attachments_sorted'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAdmin({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.filters ? data.filters : {},
      path:
        '/projects/filter' + (data && data.page ? '?page=' + data.page : ''),
      headers: {
        Format: 'admin',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'projects',
            value: res,
            type: 'projects_paginated',
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadPending({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        approved: false,
        published: true,
      },
      path:
        '/projects/filter' + (data && data.page ? '?page=' + data.page : ''),
      headers: {
        Format: 'admin',
      },
      cb: async function (res) {
        if (res.success) {
          commit('setPaginated', {
            name: 'projects',
            value: res,
            type: 'projects_paginated',
            handle: 'pending',
          })

          // if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

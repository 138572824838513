<template>
  <div class="container">
    <div class="content">
      <h1>{{ error.statusCode }}</h1>

      <p>
        {{ error.message }}
      </p>

      <ButtonV2 variant="solid" to="/">Home page</ButtonV2>
    </div>
  </div>
</template>

<script>
import ButtonV2 from '~/components/ButtonV2'
export default {
  components: {
    ButtonV2,
  },
  props: ['error'],
}
</script>

<style lang="scss" scoped>
.content {
  padding: 40px 15px;

  @include bp('sm') {
    padding: 80px 15px;
  }
}

.btn {
  display: inline-block;
}
</style>

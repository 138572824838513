import Vue from 'vue'
import { set } from 'lodash-es'

const state = () => ({
  feed: {
    items: [],
    pagination: [],
  },
  post: null,
  post_comments: {},
  mentions: {},
  pinned: {},
  search: {
    items: [],
    pagination: [],
  },
  reactions: [],
})

const getters = {
  feed: ({ feed }) => feed,
  post: ({ post }) => post,
  comments: ({ post_comments }) => post_comments,
  mentions: ({ mentions }) => mentions,
  pinned: ({ pinned }) => pinned,
  search: ({ search }) => search,
  reactions: ({ reactions }) => reactions,
}

const mutations = {
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
  updatePaginated(state, data) {
    // console.log("UPDATE PAGINATED")
    if (
      !state[data.name] ||
      !state[data.name].items ||
      !state[data.name].items.length
    )
      return

    // console.log("VALUE")
    // console.log(data.value)
    // console.log(data.value.public_id)

    let matched = false
    // console.log("ITEMS")
    // console.log(state[data.name].items)

    state[data.name].items.forEach(function (curItem) {
      // console.log("EACH")
      // console.log(curItem)
      // console.log(curItem.public_id)
      // console.log(data.value.public_id)

      const publicMatch =
        curItem.public_id && curItem.public_id === data.value.public_id
      const postableMatch =
        (curItem.postable_id &&
          curItem.postable_id === data.value.postable_id) ||
        (curItem.post && curItem.post.postable_id === data.value.postable_id)
      const feedItemMatch =
        data.associated_feed_item &&
        curItem.category === data.associated_feed_item.category &&
        curItem.id === data.associated_feed_item.id

      if (publicMatch || postableMatch || feedItemMatch) {
        // console.log("MATCHED")
        let index = state[data.name].items.indexOf(curItem)
        matched = true
        // console.log("INDEX")
        // console.log(state[data.name].items[index])

        if (data.fields && data.fields.length) {
          // console.log("HAS FIELDS")
          data.fields.forEach(function (field) {
            set(
              state,
              `${data.name}.items[${index}].${field}`,
              data.value[field]
            )
          })

          return
        }

        // console.log("NO FIELDS")
        // console.log(index)
        // state[data.name].items[index] = data.value
        Vue.set(state[data.name].items, index, data.value)
        // console.log(state[data.name].items)
      }
    })

    if (!matched) {
      // console.log("NO MATCH")
      Vue.set(state[data.name].items, state[data.name].items.length, data.value)
    }

    // console.log("DONE")
    // console.log(state[data.name].items)
  },
  deletePaginated(state, data) {
    // console.log("DELETE PAGINATED")
    state[data.name].items.forEach(function (curItem) {
      if (curItem.public_id == data.id) {
        // console.log("MATCH")
        const index = state[data.name].items.indexOf(curItem)

        state[data.name].items.splice(index, 1)
      }
    })
  },
  appendKeyedPaginated(state, data) {
    // console.log("APPEND KEYED")
    // console.log(data)
    let allKeyed = data.value[data.type][data.name]

    if (
      state[data.name][data.id] &&
      state[data.name][data.id].items &&
      state[data.name][data.id].items.length &&
      !data.reset
    ) {
      allKeyed = state[data.name][data.id].items.concat(
        data.value[data.type][data.name]
      )
    }

    Vue.set(state[data.name], data.id, {
      items: allKeyed,
      pagination: data.value[data.type].pagination,
    })

    // let allItems = state[data.name].items

    // Vue.set(allItems, data.id, allKeyed)

    // state[data.name].items = Object.assign([], [], allItems)

    // let allPagination = state[data.name].pagination

    // Vue.set(allPagination, data.id, data.value[data.type].pagination)

    // state[data.name].pagination = Object.assign([], [], allPagination)

    // state[data.name] = Object.assign([], state[data.name], {
    //   items: allItems,
    //   pagination: data.value[data.type].pagination
    // })

    // Vue.set(state[data.name].items, data.id, allKeyed)

    // state.test.splice(data.id, 1, allKeyed)
    // console.log(state.test)

    // Vue.set(state[data.name].pagination, data.id, data.value[data.type].pagination)
  },
  updateKeyedPaginated(state, data) {
    // console.log("UPDATED KEYED PAGINATED")
    // console.log(data)
    // console.log(state[data.name])
    // console.log(state[data.name][data.id])
    if (state[data.name] && state[data.name][data.id]) {
      state[data.name][data.id].items.forEach(function (curItem) {
        // console.log("EACH")
        // console.log(curItem)
        // console.log(data.value)
        if (curItem.public_id === data.value.public_id) {
          // console.log("MATCH")
          let index = state[data.name][data.id].items.indexOf(curItem)
          // console.log(index)
          // console.log(data.value)
          // console.log(state[data.name][data.id][index])
          Vue.set(state[data.name][data.id].items, index, data.value)
        }
      })
    }

    // console.log(state[data.name][data.id].items)
  },
  deleteKeyedPaginated(state, data) {
    state[data.name][data.post_id].items.forEach(function (curComment) {
      if (curComment.public_id == data.id) {
        const index = state[data.name][data.post_id].items.indexOf(curComment)

        state[data.name][data.post_id].items.splice(index, 1)
      }
    })
  },
  addKeyed(state, data) {
    // console.log("ADD KEYED")
    // console.log(data.id)
    // console.log(state[data.name])
    // console.log(state[data.name][data.id])

    if (!data) return

    // Add item if it doesn't exist
    if (!state[data.name][data.id]) {
      // console.log("ID NOT SET")
      Vue.set(state[data.name], data.id, [
        {
          id: data.value.id,
          text: data.value.name,
        },
      ])
      // console.log(state[data.name])
      // console.log(state[data.name][data.id])
      return
    }

    // Check if item exists
    if (
      state[data.name][data.id].filter(function (e) {
        return e.id === data.value.id
      }).length > 0
    )
      return

    // console.log("ID SET")

    // Add to existing item
    let items = state[data.name][data.id]
    // console.log(items)

    items.push({
      id: data.value.id,
      text: data.value.name,
    })

    Vue.set(state[data.name], data.id, items)

    // console.log(state.mentions)
  },
  setValue(state, data) {
    if (data.path) {
      Vue.set(state[data.name], data.path, data.value)

      return
    }
    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadPosts({ commit }, data) {
    let requestData = {
      method: 'get',
      data: data.filters ? data.filters : {},
      path:
        '/user/' + data.id + '/feed' + (data.page ? '?page=' + data.page : ''),
      headers: {
        Format: 'paginate:7',
      },
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'feed',
            value: res,
            reset: data.reset ? true : false,
            type: 'feed_paginated',
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadPost({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'post',
            value: res.post,
          })

          commit('appendKeyedPaginated', {
            name: 'post_comments',
            value: {
              post_comments_paginated: {
                post_comments: res.post.comments,
                pagination: [],
              },
            },
            reset: data.reset ? true : false,
            type: 'post_comments_paginated',
            id: data.id,
          })

          if (data.cb) data.cb()
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadComments({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path:
        '/post/' +
        data.id +
        '/comments' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendKeyedPaginated', {
            name: 'post_comments',
            value: res,
            reset: data.reset ? true : false,
            type: 'post_comments_paginated',
            id: data.id,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadCommentsProject({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path:
        '/post/' +
        data.id +
        '/comments' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendKeyedPaginated', {
            name: 'post_comments',
            value: res,
            reset: data.reset ? true : false,
            type: 'post_comments_paginated',
            id: data.id,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  // async clearComments({ commit }) {
  //   commit("clearComments")
  // },
  async addComment({ commit }, data) {
    let app = this
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/comment/',
      cb: async function (res) {
        if (res.success) {
          const madeFrom = data.data.made_from

          if (
            madeFrom === 'auto_feed_projects' ||
            madeFrom === 'auto_feed_users'
          ) {
            app.dispatch('posts/loadCommentsProject', {
              id: data.data.commentable_public_id,
              reset: true,
              data: {
                made_from: madeFrom,
              },
            })
          } else {
            app.dispatch('posts/loadComments', {
              id: data.data.commentable_public_id,
              reset: true,
            })
          }

          if (data.cb) data.cb(res)
        } else {
          if (data.error) {
            data.error(res)
          }
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteComment({ commit }, data) {
    let app = this

    let requestData = {
      method: 'delete',
      data: {},
      path: '/comment/' + data.id,
      cb: async function (res) {
        if (res.success) {
          let post = app.getters['posts/post']
          let comments = app.getters['posts/comments']

          // If comments are loaded delete, if not null last comment
          if (comments && comments[data.post_id]) {
            commit('deleteKeyedPaginated', {
              name: 'post_comments',
              post_id: data.post_id,
              id: data.id,
            })
          } else {
            commit('updatePaginated', {
              name: 'feed',
              value: {
                public_id: data.post_id,
                last_comment: null,
              },
              type: 'feed_paginated',
              fields: ['last_comment'],
            })
          }

          if (post.last_comment && post.last_comment.public_id === data.id) {
            commit('setValue', {
              name: 'post',
              value: null,
              path: 'last_comment',
            })
          }

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateComment({ commit }, data) {
    let app = this
    let comments = app.getters['posts/comments']

    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/comment/' + data.data.commentable_public_id,
      cb: async function (res) {
        if (res.success) {
          // Comments loaded
          if (comments[data.post_id]) {
            commit('updateKeyedPaginated', {
              name: 'post_comments',
              id: data.post_id,
              value: res.comment,
            })
          } else {
            // No comments loaded
            if (res.comment.auto_made_from === 'feed') {
              app.dispatch('post/loadCommentsProject', {
                id: data.post_id,
                data: {
                  made_from: res.comment.made_from,
                },
                reset: true,
              })
            } else {
              // Update last comment
              commit('updatePaginated', {
                name: 'feed',
                value: {
                  public_id: data.post_id,
                  last_comment: res.comment,
                },
                fields: ['last_comment'],
              })
            }
          }

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async addLike({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/reaction/',
      cb: async function (res) {
        if (res.success) {
          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteLike({ commit }, data) {
    // console.log("DELETE LIKE")
    // console.log(data)
    let requestData = {
      method: 'delete',
      data: data.data,
      path: '/reaction/',
      cb: async function (res) {
        // console.log(res)
        if (res.success) {
          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  updateLike({ commit }, data) {
    // console.log("UPDATE LIKE")
    // console.log(data)
    // commit("updateLike", data)
    commit('updatePaginated', {
      name: 'feed',
      value: {
        postable_id: data.id,
        'post.user_reacted': true,
        'post.reactions_count': 1,
      },
      fields: ['post.user_reacted', 'post.reactions_count'],
    })
  },
  async createPost({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/post/',
      cb: async function (res) {
        // console.log("POST CREATED")
        // console.log(res)

        if (res.success) {
          commit('setValue', {
            name: 'post',
            value: res.post,
          })

          commit('updatePaginated', {
            name: 'feed',
            value: res,
            type: 'feed_paginated',
            fields: ['post'],
            associated_feed_item: data.associated_feed_item,
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updatePost({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/post/' + data.id,
      cb: async function (res) {
        // console.log("POST UPDATE")
        // console.log(res)

        if (res.success) {
          commit('setValue', {
            name: 'post',
            value: res.post,
          })

          commit('updatePaginated', {
            name: 'feed',
            value: res.post,
            type: 'feed_paginated',
            fields: data.fields ? data.fields : [],
          })

          // if the post is pinned, update the pinned feed
          if (res.post.pinned) {
            commit('updatePaginated', {
              name: 'pinned',
              value: res.post,
              type: 'feed_paginated',
              fields: data.fields ? data.fields : [],
            })
          }

          if (data.cb) data.cb(res)
        } else {
          if (data.error) {
            data.error(res)
          }
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deletePost({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: {},
      path: '/post/' + data.id,
      cb: async function (res) {
        // console.log("POST UPDATE")
        // console.log(res)

        if (res.success) {
          commit('setValue', {
            name: 'post',
            value: null,
          })

          commit('deletePaginated', {
            name: 'feed',
            id: data.id,
          })

          if (data.cb) data.cb(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadPinned({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        pinned: true,
      },
      path:
        '/user/' +
        data.id +
        '/feed/filter' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'feed',
            value: res,
            reset: data.reset ? true : false,
            type: 'feed_paginated',
            handle: 'pinned',
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadSearch({ commit }, data) {
    // console.log("LOAD SEARCH")
    // console.log(data)
    let requestData = {
      method: 'post',
      data: data.data,
      path:
        '/user/' +
        data.id +
        '/feed/filter' +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        // console.log(res)
        if (res.success) {
          commit('appendPaginated', {
            name: 'feed',
            value: res,
            reset: data.reset ? true : false,
            type: 'feed_paginated',
            handle: 'search',
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async checkPost({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data,
      path: '/posts/filter',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'post',
            value: res.post_auto_feed_projects,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async getReactions({ commit }, data) {
    let requestData = {
      method: data.data && data.data.scope_affiliation_id ? 'post' : 'get',
      data: data.data ? data.data : {},
      path: '/' + data.type + '/' + data.id + '/reactions',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'reactions',
            value:
              data.type === 'post' ? res.post_reactions : res.comment_reactions,
          })

          if (data.cb) data.cb(res)
        } else {
          commit('setValue', {
            name: 'reactions',
            value: [],
          })

          if (data.error) {
            data.error(res)
          }
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  addKeyed({ commit }, data) {
    commit('addKeyed', data)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <modal name="groupInfo" :width="550" height="auto" @before-open="beforeOpen">
    <div class="modal__title">Group Information</div>

    <div v-if="users" class="modal__content">
      <div
        v-for="user in users"
        :key="`group-user-${user.id}`"
        class="users__user"
      >
        <div
          class="users__image"
          :class="{ 'users__image--empty': !user.default_profile_pic_url }"
          :style="[
            user.default_profile_pic_url
              ? {
                  'background-image':
                    'url(\'' + user.default_profile_pic_url + '\')',
                }
              : '',
          ]"
        />

        <div class="users__meta">
          <div class="users__name">
            {{ user.name }}
          </div>

          <div class="users__title">
            {{ user.job_title }}
          </div>
        </div>
      </div>
    </div>

    <div class="modal__buttons">
      <div class="btn" @click="$modal.hide('groupInfo')">Ok</div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  inject: ['$validator'],
  data() {
    return {
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      users: 'chats/users',
    }),
  },
  methods: {
    async beforeOpen(event) {
      if (event.params.id) {
        await this.$store.dispatch('chats/getUsers', {
          id: event.params.id,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}

.users__user {
  display: flex;
  margin-bottom: 15px;
}

.users__image {
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-image: url('/img/users/missing-logo-user.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ababab;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;

  @include retina() {
    background-image: url('/img/users/missing-logo-user2x.png');
  }
}

.users__image--empty {
  background-size: 50%;
}

.users__meta {
  position: relative;
  width: 100%;
}

.users__name {
  margin: 0 0 5px;
  font-size: 12px;
  font-weight: bold;
}

.users__title {
  font-size: 10px;
}
</style>

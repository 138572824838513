<template>
  <div class="input" :class="inputModifiers">
    <label v-if="label" class="label" :class="labelModifiers" :for="id">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
    </label>

    <client-only>
      <vue-tribute v-if="tributeOptions" :options="tributeOptions">
        <input
          :id="id"
          v-model="curValue"
          :type="type"
          step="any"
          class="input__elem"
          :name="name"
          :placeholder="placeholder"
          @keydown="charCount"
          @input="updateValue"
          @change="updateValue"
          @blur="$emit('blur')"
          @tribute-replaced="replaced"
        />
      </vue-tribute>
    </client-only>

    <input
      v-if="!tributeOptions"
      :id="id"
      v-model="curValue"
      :type="type"
      step="any"
      class="input__elem"
      :name="name"
      :placeholder="placeholder"
      @keydown="charCount"
      @input="updateValue"
      @change="updateValue"
      @blur="$emit('blur')"
    />

    <div
      v-if="maxlength"
      class="input__count"
      :class="{
        'input__count--error': (value ? value.length : 0) >= maxlength,
      }"
    >
      <span class="count-value">{{ curValue ? curValue.length : 0 }}</span
      >/{{ maxlength }}
    </div>

    <div
      v-if="error"
      class="form__error"
      data-test="error-message"
      data-error-message
    >
      {{ error }}
      {{ showDefaultUrlError }}
    </div>
  </div>
</template>

<script>
import utils from '~/plugins/globals.js'

export default {
  components: {
    'vue-tribute': () => import('vue-tribute'),
  },
  props: {
    id: String,
    name: String,
    placeholder: String,
    value: [String, Number],
    maxlength: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (val) => {
        return (
          ['url', 'text', 'password', 'email', 'search', 'number'].indexOf(
            val
          ) !== -1
        )
      },
    },
    modifiers: Array,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
    },
    error: {
      type: String,
      required: false,
    },
    tributeOptions: Object,
    mentionId: String,
  },
  emits: ['input', 'blur'],
  computed: {
    inputModifiers() {
      let mods = []

      if (this.modifiers) {
        mods = this.buildMods('input--', this.modifiers)
      }

      if (this.maxlength) {
        mods.push('input--count')
      }

      if (this.error) {
        mods.push('form--error')
      }

      return mods
    },
    showDefaultUrlError() {
      if (this.error === 'The url field format is invalid') {
        return 'Correct format: http://example.com or https://example.com'
      }
      return ''
    },
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        return this.value
      },
      set(newValue) {
        // this.curChoice = newValue
      },
    },
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    charCount($event) {
      // console.log("CHAR COUNT")
      if (this.maxlength) {
        utils.charCount($event, this.maxlength)
      }
    },
    updateValue($event) {
      // console.log("UPDATE VALUE INPUT")
      // console.log($event.target.value)
      this.$emit('input', $event.target.value)
    },
    replaced($event) {
      this.$store.dispatch('posts/addKeyed', {
        name: 'mentions',
        id: this.mentionId,
        value: $event.detail.item.original,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  max-width: 100%;
  position: relative;
}

.input--inline {
  display: inline-block;

  .form__error {
    font-size: 10px;
  }
}

.form--error {
  .input__elem {
    margin-bottom: 5px;
  }
}

.input__elem {
  padding: 13px 12px;

  &:focus {
    outline: none;
  }
}

.input--inline .input__elem {
  display: inline-block;
  height: auto;
  padding: 5px;
  border: 1px dashed #ababab;

  &:focus {
    border: 1px solid #50505d;
  }
}

.input--count .input__elem {
  padding-right: 60px;
}

.input__count {
  position: absolute;
  top: 50%;
  right: 5px;
  height: 16px;
  margin-top: -8px;
  color: #c4cbce;
}

.input__count--error {
  color: $error-1;

  /deep/ .count-value {
    font-weight: bold;
  }
}
</style>

<template>
  <div
    class="dropdown"
    :class="{
      'form--error': error,
    }"
  >
    <label v-if="label" class="label" :class="labelModifiers">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
      <span v-if="label.required" class="optional">&nbsp;- Required</span>
    </label>

    <multiselect
      ref="multiselectRef"
      v-model="curValue"
      class="legacy-multiselect multiselect--dropdown"
      :class="{
        'multiselect--small': variant && variant === 'small',
        'multiselect--rotated': dropdownExpanded,
        'multiselect--outlined': variant && variant === 'outlined',
        'multiselect--error': error,
      }"
      data-test="dropdown"
      :options="options"
      :placeholder="placeholder"
      :searchable="false"
      :show-labels="false"
      :label="optionLabel"
      :track-by="trackBy"
      :allow-empty="allowEmpty"
      open-direction="bottom"
      :data-value="curValue"
      role="listbox"
      @input="updateValue"
      @open="toggleOpen"
      @close="toggleOpen"
    ></multiselect>

    <div v-if="error" class="form__error" data-error-message>
      {{ error }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Multiselect,
  },
  props: {
    placeholder: String,
    value: [String, Boolean, Number, Object],
    options: Array,
    optionLabel: String,
    trackBy: String,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    variant: String,
  },
  data() {
    return {
      dropdownExpanded: false,
    }
  },
  computed: {
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        return this.value
      },
      set(newValue) {},
    },
  },
  beforeDestroy() {
    if (this.$refs && this.$refs.multiselectRef) {
      this.$refs.multiselectRef.deactivate()
    }
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    toggleOpen() {
      this.dropdownExpanded = !this.dropdownExpanded
    },
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .legacy-multiselect {
  margin-bottom: 3px;
  .multiselect__select {
    background-image: url('/icons/arrow-filled_black.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;

    &:before {
      border: none;
    }
  }
  .multiselect__tags {
    font-size: 0.875rem;
    appearance: none;
    border-width: 1px;
    border-color: $white;
    border-radius: 0.25rem;
    padding: 0 0 0 5px;
    color: black;
    line-height: 1rem;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-duration: 300ms;
    outline: none;
    box-shadow: 0 4px 8px 0 rgba(105, 120, 134, 0.15);
  }
  .multiselect__content-wrapper {
    font-size: 0.875rem;
    border-width: 5px;
    border-color: $white;
    color: #545e6f;
    margin-top: 5px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(105, 120, 134, 0.15);
  }
}
.multiselect--dropdown-expanded {
  transform: rotate(180deg);
}

.multiselect--dropdown.multiselect--active /deep/ .multiselect__tags {
  border-color: $grey-200;
}
.multiselect--dropdown.multiselect--active /deep/ .multiselect__option {
  border: none;
  border-radius: 4px;
}
.multiselect--dropdown.multiselect--active
  /deep/
  .multiselect__option--highlight {
  background-color: $grey-200;
}
.multiselect--dropdown.multiselect--active /deep/ .multiselect__element {
  margin-top: 5px;
}
.multiselect--dropdown.multiselect--active
  /deep/
  .multiselect__option--selected {
  color: $white;
  font-weight: normal;
  background-color: #189efb;
  &:hover {
    background-color: #189efb;
  }
}
.multiselect--dropdown.multiselect--active
  /deep/
  .multiselect__content-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 5px;
  background-color: $white;
}
.multiselect--dropdown.multiselect--active
  /deep/
  .multiselect__content-wrapper::-webkit-scrollbar {
  width: 10px;
  background-color: $white;
}
.multiselect--dropdown.multiselect--active
  /deep/
  .multiselect__content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #697886;
}
.form--error {
  margin: 0 0 20px;
  .multiselect {
    margin: 0 0 10px;
    /deep/ .multiselect__tags {
      border-color: $error;
    }
  }
}
.label {
  font-size: 0.875rem;
  color: #545e6f;
  margin-bottom: 10px;
}

.multiselect--rotated {
  &.legacy-multiselect /deep/ {
    .multiselect__select {
      transform: rotate(180deg);
    }
  }
}

.multiselect--small {
  &.legacy-multiselect /deep/ {
    .multiselect__select {
      height: 6px;
      padding: 0;
      margin: 8px 0 5px 0;
      width: 25px;
    }
    .multiselect__tags {
      border-color: $grey-100;
      background-color: $grey-100;
      border-radius: 4px;
      height: 24px;
      box-shadow: none;
    }

    .multiselect__content-wrapper {
      border-color: $grey-100;
      background-color: $grey-100;
    }
    .multiselect__single {
      padding: 5px;
    }

    .multiselect__option--highlight {
      background-color: #e2e6e9;
    }

    .multiselect__option--selected {
      background-color: #189efb;
      &:hover {
        background-color: #189efb;
      }
    }
  }
}

.multiselect--outlined {
  &.multiselect--active {
    border: solid 1px #8898a8;
    border-radius: $rounded-border-1;
    background-color: $white;
    box-shadow: none;
  }

  &.multiselect--dropdown.multiselect--active /deep/ .multiselect__tags {
    border-color: $white;
  }

  &.legacy-multiselect {
    height: 46px;
  }

  &.legacy-multiselect /deep/ {
    .multiselect__content-wrapper {
      border: solid 1px #8898a8;
      border-radius: $rounded-border-1;
      background-color: $white;
      box-shadow: none;
    }

    .multiselect__option {
      border: none;
      border-radius: 4px;
      margin-top: 5px;
      margin: 5px 3px;
      font-size: 14px;
      margin-top: 5px;
    }

    .multiselect--dropdown.multiselect--active /deep/ .multiselect__option {
      border: none;
      border-radius: 4px;
      border: solid 1px #8898a8;
    }

    .multiselect__option--highlight {
      background-color: #e2e6e9;
    }

    .multiselect__option--selected {
      background-color: #189efb;
      &:hover {
        background-color: #189efb;
      }
    }

    .multiselect__tags {
      border: solid 1px #8898a8;
      border-radius: $rounded-border-1;
      box-shadow: none;
      transition: none;
      height: 46px;

      &:focus {
        border-color: $primary-600;
      }

      .multiselect__option--selected {
        background-color: #189efb;
        border: none;

        &:hover {
          background-color: #189efb;
        }
      }

      .multiselect__input {
        border: none;
      }

      .multiselect__option--highlight {
        background-color: #e2e6e9;
      }
    }
  }

  &.multiselect--error.legacy-multiselect /deep/ {
    .multiselect__tags {
      border-color: $error;
    }
  }
}
</style>

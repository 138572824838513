<template>
  <modal
    name="uploadReport"
    class="uploadReport"
    height="auto"
    :width="550"
    @before-open="beforeOpen"
  >
    <ErrorAlert v-if="error">
      <div v-html="error"></div>
    </ErrorAlert>
    <h2>Upload Report</h2>

    <form @submit.prevent="validateBeforeSubmit($event)">
      <Dropdown
        v-model="scope"
        :options="scopes"
        optionLabel="name"
        :allowEmpty="false"
        :preselectFirst="true"
        :label="{
          text: 'Scope',
        }"
        name="scope"
      />

      <div class="report">
        <h3>REPORT DOCUMENT</h3>

        <TextInput
          id="report_upload__caption"
          v-model="caption"
          v-validate="'required|max:175'"
          name="report_upload__caption"
          placeholder="Eg. 2019 Fall Report"
          data-vv-as="caption"
          data-vv-scope="uploadReport"
          :label="{
            text: 'Title',
          }"
          :error="errors.first('uploadReport.report_upload__caption')"
        />

        <TextInput
          id="report_upload__description"
          v-model="description"
          v-validate="'max:350'"
          name="report_upload__description"
          placeholder="Eg. This report is about something specific..."
          data-vv-as="description"
          data-vv-scope="uploadReport"
          :label="{
            text: 'Description (Displays only in the email notification)',
          }"
          :error="errors.first('uploadReport.report_upload__description')"
        />

        <div id="report_upload">
          <FileUpload
            id="report_upload"
            v-model="report_upload"
            v-validate="'required|ext:pdf,docx,doc,csv,xls,xlsx|size:10240'"
            name="report_upload"
            placeholder="report.pdf"
            :error="errors.first('uploadReport.report_upload')"
            data-vv-as="report"
            data-vv-scope="uploadReport"
            :label="{
              text: 'Report Upload',
            }"
            @change="updateAttachments"
          />
        </div>

        <hr />

        <div id="report_upload">
          <h3>REPORT COVER IMAGE</h3>
          <FileUpload
            id="report_upload__cover"
            v-model="cover"
            v-validate="'ext:png,jpg,jpeg|size:10240'"
            name="report_upload__cover"
            placeholder="report-cover.png"
            :error="errors.first('uploadReport.report_upload__cover')"
            data-vv-as="cover"
            data-vv-scope="uploadReport"
            :label="{
              text: 'Report Image',
            }"
            @change="updateAttachments"
          />
        </div>
      </div>

      <div class="buttons">
        <div class="btn btn--inverse" @click="$modal.hide('uploadReport')">
          Close
        </div>

        <button v-loading="submitting" class="btn" type="submit">
          Publish
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Dropdown from '~/components/form/Dropdown'
import FileUpload from '~/components/form/FileUpload'
import TextInput from '~/components/form/Input'
import ErrorAlert from '~/components/form/ErrorAlert.vue'
import getUserErrorMessage from '~/utils/getUserErrorMessage'

export default {
  components: {
    Dropdown,
    FileUpload,
    TextInput,
    ErrorAlert,
  },
  inject: ['$validator'],
  data() {
    return {
      report_upload: '',
      caption: '',
      description: '',
      cover: '',
      scope: {
        name: 'Partners',
        value: 'report-partners',
      },
      scopes: [
        {
          name: 'Global',
          value: 'report-global',
        },
        {
          name: 'Partners',
          value: 'report-partners',
        },
        // {
        //   name: 'Stakeholders',
        //   value: 'report-stakeholders',
        // },
        // {
        //   name: 'Cities',
        //   value: 'report-cities',
        // },
      ],
      formSubmitted: false,
      edit: false,
      editReport: null,
      error: null,
      submitting: false,
    }
  },
  computed: {
    ...mapGetters({
      report: 'reports/report',
      uploadStatus: 'reports/uploadStatus',
    }),
  },
  methods: {
    beforeOpen(event) {
      // if (event.params && event.params.report) {
      //   this.edit = true
      //   this.editReport = event.params.report
      // }
    },
    updateAttachments(data) {},
    validateBeforeSubmit(e) {
      e.preventDefault()

      this.formSubmitted = true

      this.$validator.validateAll('uploadReport').then((x) => {
        if (x) {
          this.handleReportCreation()
        }
      })
    },
    async handleReportCreation() {
      const app = this
      let dataSubmit = new FormData()
      dataSubmit.append('attachmentable_id', Number(app.report.id))
      dataSubmit.append('attachmentable_type', 'posts')
      dataSubmit.append('attachment_role', 'report')
      dataSubmit.append('file', app.report_upload[0])
      dataSubmit.append('caption', app.caption)
      dataSubmit.append('description', app.description)

      let updateData = {
        data: dataSubmit,
      }

      try {
        // First API call to create report
        await app.$store.dispatch('reports/uploadReportAsync', updateData)

        // Adding attachment to created Report
        if (app.cover) {
          this.submitting = true
          let dataCover = new FormData()
          dataCover.append('attachmentable_id', Number(app.report.id))
          dataCover.append('attachmentable_type', 'posts')
          dataCover.append('attachment_role', 'cover')
          dataCover.append('file', app.cover[0])

          await app.$store.dispatch('externalRequestAsync', {
            path: '/attachment',
            method: 'post',
            data: dataCover,
          })
        }

        // Publish report
        await app.$store.dispatch('reports/updateReport', {
          id: app.report.id,
          data: {
            status: 'published',
            scope: app.scope.value,
          },
        })

        // Load reports to show new report
        await app.$store.dispatch('reports/loadAllReports', {
          data: {},
          reset: true,
        })

        // Hide creation modal & reset data
        app.$modal.hide('uploadReport')

        app.report_upload = ''
        app.caption = ''
        app.description = ''
        app.cover = ''
        app.scope = {
          name: 'Global',
          value: 'global',
        }
      } catch (e) {
        this.error = getUserErrorMessage(e, true)
        if (this.$bugsnag) this.$bugsnag.notify(e)
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 0 0 20px;
  font-size: 24px;
}

hr {
  border: 1px solid #d8d8d8;
}

.report {
  background-color: #f6f6f6;
  padding: 20px;
  margin-bottom: 20px;
}

.report /deep/ .input {
  margin-bottom: 20px;
}

#report_upload {
  margin: 20px 0;
}

#report_upload /deep/ .form--error .input__elem {
  margin-bottom: 0;
}

#report_upload /deep/ .form__error {
  margin-top: 5px;
}

#report_upload /deep/ .input__elem {
  background-color: $white;
}

.report_upload-status {
  margin-top: 5px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div
    class="dropdown"
    :class="{
      'form--error': error,
    }"
  >
    <label v-if="label" class="label" :class="labelModifiers">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
    </label>

    <multiselect
      ref="multiselectRef"
      v-model="curValue"
      class="legacy-multiselect multiselect--dropdown"
      :options="options"
      :placeholder="placeholder"
      :searchable="false"
      :show-labels="false"
      :label="optionLabel"
      :track-by="trackBy"
      :allow-empty="allowEmpty"
      open-direction="bottom"
      :data-value="curValue"
      @input="updateValue"
    />

    <div v-if="error" class="form__error">
      {{ error }}
    </div>

    <!-- CURRENT VALUE
      <pre>
        {{ curValue }}
      </pre> -->

    <!-- OPTIONS
      <pre>
        {{ options }}
      </pre> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Multiselect,
  },
  props: {
    placeholder: String,
    value: [String, Boolean, Number, Object],
    options: Array,
    optionLabel: String,
    trackBy: String,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
    },
    error: {
      type: String,
      required: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  computed: {
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        // console.log("DROPDOWN GET")
        // console.log(this.value)
        return this.value
      },
      set(newValue) {
        // console.log("DROPDOWN SET")
        // console.log(newValue)
        // this.value = newValue
        // this.curValue = newValue
        // this.curChoice = newValue
      },
    },
  },
  beforeDestroy() {
    if (this.$refs && this.$refs.multiselectRef) {
      this.$refs.multiselectRef.deactivate()
    }
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    updateValue(value) {
      // console.log("UPDATE VALUE")
      // console.log(value)
      this.$emit('input', value)
      // this.$emit('blur', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.multiselect--dropdown /deep/ .multiselect__select {
  background-image: url('/icons/round-unfold_more-24px.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;

  &:before {
    border: none;
  }
}

.multiselect--dropdown.multiselect--active /deep/ .multiselect__select {
  transform: none;
}

.form--error {
  margin: 0 0 20px;

  .multiselect {
    margin: 0 0 10px;

    /deep/ .multiselect__tags {
      border-color: #e33a00;
    }
  }
}
</style>

import { values } from 'lodash-es'

export const supportEmailLink =
  '<a href="mailto:support@the-atlas.com">support@the-atlas.com</a>'

export default function getUserErrorMessage(res, showDetails = false) {
  let errorMessage = `There was a problem with your submission. Please contact ${supportEmailLink} for assistance.`

  if (res.statusCode >= 500) {
    errorMessage = `We're experiencing issues. Please contact ${supportEmailLink} and we'll get it resolved right away.`
  }

  if (res.statusCode === 401) {
    errorMessage = `<strong>Your login token has expired.</strong> Please reload and try again.<br>If the issue persists, contact ${supportEmailLink} for assistance.`
  }

  if (showDetails && res.errors) {
    errorMessage += '<p></p>Details:'
    errorMessage += '<ul>'
    values(res.errors).forEach((error) => {
      errorMessage += `<li>${error}</li>`
    })
    errorMessage += '</ul>'
  }

  return errorMessage
}

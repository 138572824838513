<template>
  <div class="textarea" :class="modifiersBuilt">
    <label v-if="label" class="label" :class="labelModifiers" :for="id">
      {{ label.text
      }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
      <span v-if="label.required" class="optional">&nbsp;- Required</span>
    </label>

    <div class="textarea__wrap">
      <client-only>
        <vue-tribute
          v-if="tributeOptions"
          data-test="textarea-vue-tribute"
          :options="tributeOptions"
        >
          <textarea
            :id="id"
            v-model="curValue"
            :name="name"
            class="textarea__elem"
            :class="{
              'textarea__elem--error': error,
            }"
            :data-test="dataTest"
            :placeholder="placeholder"
            @keydown="charCount($event)"
            @keyup="textAreaAdjust"
            @input="updateValue"
            @change="updateValue"
            @blur="$emit('blur', $event)"
            @focus="focus"
            @tribute-replaced="replaced"
          />
        </vue-tribute>
      </client-only>

      <textarea
        v-if="!tributeOptions"
        :id="id"
        v-model="curValue"
        class="textarea__elem"
        :class="{
          'textarea__elem--error': error,
        }"
        :name="name"
        :placeholder="placeholder"
        :data-test="dataTest"
        @keydown="charCount($event)"
        @keyup="textAreaAdjust"
        @input="updateValue"
        @change="updateValue"
        @blur="$emit('blur', $event)"
        @focus="focus"
      />

      <div
        v-if="maxlength"
        class="textarea__count"
        :class="{
          'textarea__count--error': (value ? value.length : 0) >= maxlength,
        }"
      >
        <span class="count-value">{{ value ? value.length : 0 }}</span
        >/{{ maxlength }}
      </div>
    </div>

    <div v-if="error" data-error-message class="form__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import utils from '~/plugins/globals.js'

export default {
  components: {
    'vue-tribute': () => import('vue-tribute'),
  },
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    id: String,
    name: String,
    placeholder: String,
    dataTest: String,
    value: String,
    maxlength: {
      type: Number,
      required: false,
    },
    modifiers: Array,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
    focus: {
      type: Function,
      default: function () {},
    },
    tributeOptions: Object,
    mentionId: String,
    // cb: Function,
  },
  emits: ['blur', 'input'],
  computed: {
    modifiersBuilt() {
      if (!this.modifiers && !this.maxlength) return

      let mods = []

      if (this.modifiers) {
        this.modifiers.forEach(function (modifier) {
          mods.push('textarea--' + modifier)
        })
      }

      if (this.maxlength) {
        mods.push('textarea--count')
      }

      if (this.error) {
        mods.push('form--error')
      }

      return mods
    },
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curValue: {
      get() {
        return this.value
      },
      set(newValue) {
        // console.log("TEXTAREA SET")
        // return
        // console.log(newValue)
        // this.curChoice = newValue
      },
    },
  },
  watch: {
    value: function () {
      if (!this.value.length) {
        let elem = document.getElementById(this.id)

        if (elem) elem.style.height = '38px'
      }
    },
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    charCount($event) {
      if (this.maxlength) {
        utils.charCount($event, this.maxlength)
      }
    },
    textAreaAdjust($event) {
      let elem = $event.target

      elem.style.height = '1px'
      elem.style.height = 5 + elem.scrollHeight + 'px'

      this.charCount($event)
    },
    updateValue($event) {
      this.$emit('input', $event.target.value)

      // if (this.cb) this.cb($event)
    },
    replaced($event) {
      // this.$root.$emit('mention', {
      //   id: this.mentionId,
      //   item: $event.detail.item.original,
      // })

      this.$store.dispatch('posts/addKeyed', {
        name: 'mentions',
        id: this.mentionId,
        value: $event.detail.item.original,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.textarea__wrap {
  position: relative;
}

.textarea__elem {
  height: auto;
  min-height: 100px;
  margin: 0;
  padding: 5px;
  background: none;
  line-height: 1.5;
  outline: none;
  resize: none;
}

.textarea--count {
  .textarea__elem {
    padding-bottom: 20px;
  }
}

.textarea--long {
  .textarea__elem {
    min-height: 200px;
  }
}

.textarea--inline {
  .textarea__elem {
    border: 1px dashed #ababab;

    &:focus {
      border: 1px solid #50505d;
    }
  }
}

.textarea__count {
  position: absolute;
  right: 5px;
  bottom: 10px;
  height: 16px;
  color: #ababab;
}

.form--error .textarea__count {
  bottom: 20px;
}

.textarea__count--error {
  color: $error-1;

  /deep/ .count-value {
    font-weight: bold;
  }
}

.textarea__elem--error {
  border: 1px solid $error;
}

.label--bold {
  font-weight: bold;
}

.label--uppercase {
  text-transform: uppercase;
}

.label--large {
  font-size: 16px;
  word-spacing: 1px;
  letter-spacing: 0.2px;
}

.label--small {
  font-size: 14px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{
    'form--error': _vm.error,
  }},[(_vm.label)?_c('label',{staticClass:"label",class:_vm.labelModifiers},[_vm._v("\n    "+_vm._s(_vm.label.text)),(_vm.label.optional)?_c('span',{staticClass:"optional"},[_vm._v(" - Optional")]):_vm._e(),_vm._v(" "),(_vm.label.required)?_c('span',{staticClass:"optional"},[_vm._v(" - Required")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('multiselect',{ref:"multiselectRef",staticClass:"legacy-multiselect multiselect--dropdown",class:{
      'multiselect--small': _vm.variant && _vm.variant === 'small',
      'multiselect--rotated': _vm.dropdownExpanded,
      'multiselect--outlined': _vm.variant && _vm.variant === 'outlined',
      'multiselect--error': _vm.error,
    },attrs:{"data-test":"dropdown","options":_vm.options,"placeholder":_vm.placeholder,"searchable":false,"show-labels":false,"label":_vm.optionLabel,"track-by":_vm.trackBy,"allow-empty":_vm.allowEmpty,"open-direction":"bottom","data-value":_vm.curValue,"role":"listbox"},on:{"input":_vm.updateValue,"open":_vm.toggleOpen,"close":_vm.toggleOpen},model:{value:(_vm.curValue),callback:function ($$v) {_vm.curValue=$$v},expression:"curValue"}}),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"form__error",attrs:{"data-error-message":""}},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { get } from 'lodash-es'

export const actions = {
  async nuxtServerInit({ dispatch }, { req }) {
    // console.log("SERVER INIT")

    // If we have the x-forwarded-for header grab the first entry. That is the original IP
    let ip = req.headers['x-forwarded-for']
      ? req.headers['x-forwarded-for'].split(',')[0]
      : req.connection.remoteAddress

    if (ip) {
      ip = ip.trim()
    }

    // Get impersonating cookie if it exists
    const impersonating = this.$cookies.get('impersonating')

    // Pass cookie value if true
    if (impersonating)
      await dispatch('authHelpers/setImpersonating', impersonating)

    // const expiration = this.$auth.$storage.getUniversal('_refresh_token_expiration.local', false)
    // console.log(expiration)

    // this.$auth.options.cookie.options.maxAge = expiration ? (expiration - new Date()) / 1000 : 60 * 60 * 24

    await dispatch('users/getLocation', {
      ip: ip,
    })
  },
  /**
   * This function should be used for any new API calls we need to make.
   * It returns a promise so you can use async/await.
   *
   * @param {*} app
   * @param {*} data
   *
   * @returns Promise
   */
  async externalRequestAsync(app, data) {
    try {
      const headers = data.headers
        ? data.headers
        : { 'Content-Type': 'application/json' }

      const response = await this.$axios({
        method: data.method ? data.method : 'post',
        headers: headers,
        data: data.data ? data.data : {},
        url: data.path,
      })

      return Promise.resolve(response.data)
    } catch (e) {
      // Show any potentially swallowed errors
      // eslint-disable-next-line no-console
      console.error(e)

      // This will trigger a bugsnag for any failed requests
      if (this.$bugsnag)
        this.$bugsnag.notify(e, function (event) {
          const postBody = get(e, 'response.config.data')

          if (postBody && typeof postBody === 'string') {
            try {
              const parsedPostBody = JSON.parse(postBody)
              e.response.config.data = parsedPostBody
            } catch (e) {
              // something happened while parsing. Abandon ship
            }
          }

          event.addMetadata('axios', e.response)
        })

      if (e.response) {
        return Promise.reject({
          statusCode: e.response.status,
          ...e.response.data,
        })
      } else {
        return Promise.reject({
          success: false,
          error: e,
          authenticated: false,
        })
      }
    }
  },
  async externalRequest(app, data) {
    try {
      const headers = data.headers
        ? data.headers
        : { 'Content-Type': 'application/json' }

      const response = await this.$axios({
        method: data.method ? data.method : 'post',
        headers: headers,
        data: data.data ? data.data : {},
        url: data.path,
      })

      data.cb(response.data)
    } catch (e) {
      // Show any potentially swallowed errors
      // eslint-disable-next-line no-console
      console.error(e)

      // This will trigger a bugsnag for any failed requests
      if (this.$bugsnag)
        this.$bugsnag.notify(e, function (event) {
          const postBody = get(e, 'response.config.data')

          if (postBody && typeof postBody === 'string') {
            try {
              const parsedPostBody = JSON.parse(postBody)
              e.response.config.data = parsedPostBody
            } catch (e) {
              // something happened while parsing. Abandon ship
            }
          }

          event.addMetadata('axios', e.response)
        })

      if (e.response) {
        data.cb({
          statusCode: e.response.status,
          ...e.response.data,
        })
      } else {
        data.cb({ success: false, error: e, authenticated: false })
      }
    }
  },
}

import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"2.14.2","apiKey":"6f9fb2297d6fe167a4b11f0a8b1365d9","appType":"client","enabledReleaseStages":["production","development","staging","local"],"releaseStage":"production","redactedKeys":["access_token",new RegExp("password", "i"),new RegExp("^cc_", ""),new RegExp("Authorization", "i")]}
options.plugins = [new BugsnagPluginVue()]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}

import Vue from 'vue'

const state = () => ({
  reports: {
    items: [],
    pagination: [],
  },
  uploadStatus: '',
  report: {},
})

const getters = {
  reports: ({ reports }) => reports,
  report: ({ report }) => report,
  uploadStatus: ({ uploadStatus }) => uploadStatus,
}

const mutations = {
  appendPaginated(state, data) {
    let name = data.handle ? data.handle : data.name

    state[name].pagination = data.value[data.type].pagination

    let allItems = data.value[data.type][data.name]

    if (state[name].items && state[name].items.length && !data.reset) {
      allItems = state[name].items.concat(data.value[data.type][data.name])
    }

    state[name].items = Object.assign(
      [],
      data.reset ? [] : state[name].items,
      allItems
    )
  },
  updatePaginated(state, data) {
    if (
      !state[data.name] ||
      !state[data.name].items ||
      !state[data.name].items.length
    )
      return

    let matched = false

    state[data.name].items.forEach(function (curItem) {
      if (
        curItem.public_id
          ? curItem.public_id === data.value.public_id
          : curItem.postable_id === data.value.postable_id
      ) {
        let index = state[data.name].items.indexOf(curItem)
        matched = true

        if (data.fields && data.fields.length) {
          data.fields.forEach(function (field) {
            state[data.name].items[index][field] = data.value[field]
          })

          return
        }

        Vue.set(state[data.name].items, index, data.value)
      }
    })

    if (!matched) {
      Vue.set(state[data.name].items, state[data.name].items.length, data.value)
    }
  },
  deletePaginated(state, data) {
    state[data.name].items.forEach(function (curItem) {
      if (curItem.public_id == data.id) {
        const index = state[data.name].items.indexOf(curItem)

        state[data.name].items.splice(index, 1)
      }
    })
  },
  setValue(state, data) {
    if (data.path) {
      let target = state[data.name]

      data.path.forEach(function (step) {
        target = target[step]
      })

      target = data.value

      return
    }

    // Set keyed value
    if (data.id) {
      Vue.set(state[data.name], data.id, data.value)
      return
    }

    // Set standard value
    state[data.name] = data.value
  },
}

const actions = {
  async loadReports({ commit }, data) {
    let requestData = {
      method: 'get',
      data: {},
      path:
        '/group/' +
        data.id +
        '/feed/reports' +
        (data.public_id ? `/${data.public_id}` : '') +
        (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'feed',
            value: res,
            reset: data.reset ? true : false,
            type: 'feed_paginated',
            handle: 'reports',
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async updateReport({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('updatePaginated', {
            name: 'reports',
            value: res.post,
            fields: ['text_formatted', 'text_plain', 'pinned'],
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async deleteReport({ commit }, data) {
    let requestData = {
      method: 'delete',
      data: data.data,
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('deletePaginated', {
            name: 'reports',
            id: res.post_id,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async loadAllReports({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/groups/reports' + (data.page ? '?page=' + data.page : ''),
      cb: async function (res) {
        if (res.success) {
          commit('appendPaginated', {
            name: 'feed',
            value: res,
            reset: data.reset ? true : false,
            type: 'feed_paginated',
            handle: 'reports',
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async createReport({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {
        scope: 'report-partners',
        made_from: 'insights',
        status: 'draft',
      },
      path: '/post',
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'report',
            value: res.post,
          })

          if (data.cb) data.cb()
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async uploadReportAsync({ commit }, data) {
    let requestData = {
      method: 'post',
      data: data.data,
      path: '/attachment',
    }

    return this.dispatch('externalRequestAsync', requestData)
  },
  async updateReport({ commit }, data) {
    let requestData = {
      method: 'patch',
      data: data.data,
      path: '/post/' + data.id,
      cb: async function (res) {
        if (res.success) {
          commit('setValue', {
            name: 'report',
            value: res.post,
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  async sendNotifications({ commit }, data) {
    let requestData = {
      method: 'post',
      data: {},
      path: '/post/' + data.id + '/send-notifications',
      cb: async function (res) {
        if (res.success) {
          commit('updatePaginated', {
            name: 'reports',
            value: {
              public_id: data.id,
              notifications_sent: 1,
            },
            fields: ['notifications_sent'],
          })

          if (data.cb) data.cb(res)
        } else {
          if (data.error) data.error(res)
        }
      },
    }

    await this.dispatch('externalRequest', requestData)
  },
  setValue({ commit }, data) {
    commit('setValue', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <button
    class="favorite btn--unstyled"
    :class="{ 'favorite--hero': hero, 'favorite--active': curActive }"
    @click="toggleFavorite"
  >
    <svgStarOutline v-if="type == 'star'" class="favorite__outline" />

    <svgStar v-if="type == 'star'" class="favorite__star" />

    <svgAddPerson v-if="type == 'follow'" class="favorite__follow" />

    <svgBookmark v-if="type == 'bookmark' && !curActive" />
    <svgBookmarkFilled v-if="type == 'bookmark' && curActive" />

    <svgAddedPerson
      v-if="type == 'follow'"
      class="favorite__follow favorite__follow--unfollow"
    />

    {{ text ? text : '' }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

import svgAddPerson from '~/static/icons/round-person_add-24px.svg'
import svgAddedPerson from '~/static/icons/round-how_to_reg-24px.svg'
import svgStar from '~/static/icons/icons-star-filled.svg'
import svgStarOutline from '~/static/icons/round-star_border-24px.svg'
import svgBookmark from '~/static/icons/bookmark-outlined.svg'
import svgBookmarkFilled from '~/static/icons/bookmark-filled.svg'

export default {
  components: {
    svgAddPerson,
    svgAddedPerson,
    svgStar,
    svgStarOutline,
    svgBookmark,
    svgBookmarkFilled,
  },
  props: ['active', 'data', 'type', 'text', 'hero'],
  data() {
    return {
      curActive: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  created() {
    this.curActive = this.active
  },
  methods: {
    toggleFavorite(e) {
      e.preventDefault()

      let data = {
        id: this.data.data.favoriteable_id,
      }

      if (this.$auth.loggedIn) {
        if (this.data.data.favoriteable_type === 'users' && this.user)
          data.role_id = this.user.role_id

        if (!this.curActive) {
          if (this.$track) {
            this.$track.event({
              segment: {
                action:
                  this.data.data.favoriteable_type.charAt(0).toUpperCase() +
                  this.data.data.favoriteable_type.slice(
                    1,
                    this.data.data.favoriteable_type.length - 1
                  ) +
                  ' Favorited',
                data: data,
              },
            })
          }

          this.curActive = true

          this.$store.dispatch('favorites/addFavorite', this.data)
        } else {
          if (this.$track) {
            this.$track.event({
              segment: {
                action:
                  this.data.data.favoriteable_type.charAt(0).toUpperCase() +
                  this.data.data.favoriteable_type.slice(
                    1,
                    this.data.data.favoriteable_type.length - 1
                  ) +
                  ' Unfavorited',
                data: data,
              },
            })
          }

          this.curActive = false

          this.$store.dispatch('favorites/deleteFavorite', this.data)
        }
      } else {
        if (this.$track) {
          this.$track.event({
            segment: {
              action:
                this.data.data.favoriteable_type.charAt(0).toUpperCase() +
                this.data.data.favoriteable_type.slice(
                  1,
                  this.data.data.favoriteable_type.length - 1
                ) +
                ' Favorited',
              data: data,
            },
          })
        }

        this.$modal.show('joinPrompt', {
          action: 'favorite',
          type: this.data.data.favoriteable_type,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.favorite:not(.favorite--hero) {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0 0 0 0;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #ababab;
  cursor: pointer;
  justify-content: center;
  z-index: 1;

  &:focus,
  &:hover,
  &.favorite--active {
    color: #e39600;

    .favorite__outline {
      display: none;
    }

    .favorite__star {
      display: block;
    }

    .favorite__follow {
      fill: #e39600;
    }
  }

  .favorite__outline {
    width: 24px;
    height: 24px;
    fill: #ababab;
  }

  .favorite__star {
    display: none;
    width: 18px;
    height: 18px;
    fill: #e39600;
  }

  .favorite__follow {
    width: 18px;
    height: 18px;
    fill: #ababab;
  }

  .favorite__follow--unfollow {
    display: none;
    fill: #e39600;
  }
}

.favorite--hero {
  .favorite__star {
    width: 24px;
    height: 24px;
  }

  .favorite__outline {
    display: none;
    width: 32px;
    height: 32px;
  }

  .favorite__follow,
  .favorite__follow--unfollow {
    width: 32px;
    height: 32px;
  }

  .favorite__star {
    display: block;
  }

  &.favorite--active {
    .favorite__outline {
      display: block;
    }

    .favorite__star {
      display: none;
    }
  }
}

.favorite {
  .favorite__follow {
    display: block;
  }

  .favorite__follow--unfollow {
    display: none;
  }

  &.favorite--active {
    .favorite__follow {
      display: none;
    }

    .favorite__follow--unfollow {
      display: block;
    }
  }
}
</style>

<template>
  <modal
    name="userApprove"
    :clickToClose="false"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form>
      <div class="modal__title">Approve User</div>

      <div class="modal__content">
        <p>Select user role:</p>

        <Dropdown
          v-if="affiliationRoles && affiliationRoles.items.length"
          v-model="curUserRole"
          :options="affiliationRoles.items"
          trackBy="name"
          optionLabel="name"
          placeholder="Select Role"
        />

        <p v-if="!affiliationRoles || !affiliationRoles.items.length">
          Error loading roles.
        </p>
      </div>

      <div class="modal__buttons">
        <div
          class="btn"
          :class="{ 'btn--disabled': !userRole }"
          @click="approve"
        >
          OK
        </div>

        <div class="btn" @click="$modal.hide('userApprove')">Cancel</div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import Dropdown from '~/components/form/Dropdown'

export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      userId: null,
      affiliationId: null,
      userRole: null,
    }
  },
  computed: {
    ...mapGetters({
      affiliationRoles: 'affiliations/roles',
    }),
    curUserRole: {
      get() {
        return this.userRole
      },
      set(value) {
        this.userRole = value
      },
    },
  },
  methods: {
    beforeOpen(event) {
      this.userId = +event.params.userId
      this.affiliationId = +event.params.affiliationId
      this.userRole = null

      this.$store.dispatch('affiliations/loadRoles', {
        data: {
          affiliation_id: this.affiliationId,
        },
      })
    },
    async approve() {
      if (!this.userRole) return

      let app = this

      this.$modal.hide('userApprove')

      let data = {
        user_id: this.userId,
        from: 'requesting',
        to: 'belonging',
        affiliation_role_id: this.userRole.id,
      }

      this.$store.dispatch('affiliations/updateRole', {
        id: this.affiliationId,
        data: {
          user_id: this.userId,
          from: 'requesting',
          to: 'belonging',
          affiliation_role_id: this.userRole.id,
        },
        cb: function () {
          app.$store.dispatch('users/loadRequesting', {
            filters: {
              membership_types: ['requesting'],
            },
            headers: {
              Format: 'admin',
            },
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}
</style>

<template>
  <modal
    class="project-seo"
    name="pageSeo"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form>
      <div class="modal__title">SEO Settings</div>

      <div class="modal__content">
        <p>
          You can fill out your page's metadata here. This will improve your
          project's search engine visibility.
        </p>

        <TextInput
          id="page_title"
          v-model="pageData.page_title_seo"
          v-validate="'max:60'"
          name="page_title"
          :label="{
            text: 'Page Title',
            modifiers: ['header'],
          }"
          data-vv-as="page title"
          data-vv-scope="pageSeo"
          :maxlength="60"
          :error="
            errors.first('pageSeo.page_title') ||
            (error && error.page_title_seo && error.page_title_seo[0])
          "
        />

        <TextArea
          id="page_description"
          v-model="pageData.description_seo"
          v-validate="'max:150'"
          name="page_description"
          data-vv-as="page description"
          data-vv-scope="pageSeo"
          :maxlength="150"
          :error="
            errors.first('pageSeo.page_description') ||
            (error && error.description_seo && error.description_seo[0])
          "
          :label="{
            text: 'Page Description',
            modifiers: ['header'],
          }"
        />

        <TextInput
          v-if="user.is_internal"
          id="page_slug"
          v-model="pageData.slug"
          v-validate="'max:75'"
          name="page_slug"
          :label="{
            text: 'Slug',
            modifiers: ['header'],
          }"
          data-vv-scope="pageSeo"
          data-vv-as="page slug"
          :maxlength="75"
          :error="
            errors.first('pageSeo.page_slug') ||
            (error && error.slug && error.slug[0])
          "
        />
      </div>

      <div class="modal__buttons">
        <div class="btn btn--clear btn--spaced" @click="$modal.hide('pageSeo')">
          Cancel
        </div>

        <SubmitButton
          :isDisabled="!formIsDirty"
          :loading="loading"
          :onClick="updateSeo"
          variant="solid"
        >
          Update
        </SubmitButton>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import TextInput from '~/components/form/Input'
import TextArea from '~/components/form/TextArea'
import SubmitButton from '~/components/ButtonV2'

export default {
  components: {
    TextInput,
    TextArea,
    SubmitButton,
  },
  inject: ['$validator'],
  data() {
    return {
      user: this.$auth.user,
      pageData: {
        page_title_seo: '',
        description_seo: '',
        slug: '',
      },
      type: 'project',
      error: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      project: 'projects/project',
      affiliation: 'affiliations/affiliation',
    }),
    formIsDirty() {
      if (
        this.pageData &&
        this[this.type] &&
        this.pageData.page_title_seo == this[this.type].page_title_seo &&
        this.pageData.description_seo == this[this.type].description_seo &&
        this.pageData.slug == this[this.type].slug
      ) {
        return false
      }
      return true
    },
  },
  watch: {
    $route: function () {
      this.$modal.hide('pageSeo')
    },
  },
  methods: {
    beforeOpen(event) {
      this.type = event.params.type
      this.pageData.page_title_seo = this[this.type].page_title_seo
      this.pageData.description_seo = this[this.type].description_seo
      this.pageData.slug = this[this.type].slug
      this.error = {}
    },
    submitHandler(e) {
      e.preventDefault()
    },
    updateSeo() {
      let app = this
      let submitData = {}

      this.error = {}

      app.loading = true

      if (this.formIsDirty) {
        if (this.pageData.page_title_seo != this[this.type].page_title_seo)
          submitData.page_title_seo = this.pageData.page_title_seo

        if (this.pageData.description_seo != this[this.type].description_seo)
          submitData.description_seo = this.pageData.description_seo

        if (
          this.pageData.slug != this[this.type].slug &&
          this.pageData.slug !== ''
        )
          submitData.slug = this.pageData.slug
      }

      this.$validator.validateAll('pageSeo').then((passedValidation) => {
        if (passedValidation) {
          if (app.type === 'project') {
            // Build url and data
            let updateData = {
              id: app.$route.params.id || app.$route.params.slug,
              data: submitData,
              cb: app.handleSubmitSuccess,
              error: app.handleSubmitError,
            }

            // Dispatch update project
            app.$store.dispatch('projects/updateProject', updateData)
          }

          if (app.type === 'affiliation') {
            let updateData = {
              id: app.$route.params.slug,
              data: submitData,
              cb: app.handleSubmitSuccess,
              error: app.handleSubmitError,
            }

            // Dispatch update affiliation
            app.$store.dispatch('affiliations/updateAffiliation', updateData)
          }
        } else {
          app.loading = false
        }
      })
    },
    handleSubmitSuccess() {
      this.loading = false
      this.$modal.hide('pageSeo')
    },
    handleSubmitError(res) {
      this.error = res.errors
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .label--header {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
}

.input,
.textarea {
  margin-bottom: 20px;
}

.project-seo__error {
  color: $error-1;
}

.btn--spaced {
  margin-right: 10px;
  padding: 16px 47px;
  border-radius: 6px;
}
</style>

<template>
  <button
    v-loading="loading"
    class="btn"
    :type="action ? 'button' : 'submit'"
    @click="clickHandler"
  >
    {{ text ? text : 'Save' }}
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    loading: Boolean,
    action: {
      type: Function,
      default: null,
    },
  },
  methods: {
    clickHandler() {
      if (this.action) this.action()
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  // Added styles
  position: relative;

  // Loader backdrop
  &::before {
    opacity: 0;
    content: '';
    display: block;

    position: absolute;

    width: 100%;
    height: 100%;

    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.2);
  }

  // Loader spinner
  &::after {
    opacity: 0;
    content: '';
    display: block;

    width: 40px;
    height: 40px;
    border-radius: 100%;

    position: absolute;
    top: 50%;
    left: 50%;

    transition: 0.2s all;

    transform: translate(-50%, -50%);

    transform-origin: center;

    position: absolute;
    width: 20px;
    height: 20px;
    border: 4px solid #fff;
    border-left-color: transparent;
    border-radius: 50%;
  }
}

button.btn--loading {
  background-color: grey;

  pointer-events: none;

  &:focus,
  &:hover {
    background-color: grey;
  }

  &::before {
    opacity: 1;
  }

  &::after {
    opacity: 1;
    animation: rotate 0.5s infinite;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>

<template>
  <div class="input-file" :class="inputModifiers">
    <label>
      <input
        :id="id"
        type="file"
        :name="name"
        :placeholder="placeholder"
        @input="updateValue"
        @blur="$emit('blur')"
      />

      <span v-if="label" class="label" :class="labelModifiers">
        {{ label.text
        }}<span v-if="label.optional" class="optional">&nbsp;- Optional</span>
      </span>

      <span class="input__wrap">
        <span
          class="input__elem"
          :class="{ 'input__elem--placeholder': !curName }"
          >{{ curName ? curName : placeholder }}</span
        >
        <span class="btn btn--inverse">Choose File</span>
      </span>
    </label>

    <div v-if="error" class="form__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    id: String,
    name: String,
    placeholder: String,
    value: null,
    modifiers: Array,
    label: {
      text: String,
      optional: {
        type: Boolean,
        default: false,
      },
      modifiers: Array,
    },
    error: {
      type: String,
      required: false,
    },
    fileName: String,
  },
  emits: ['input', 'blur'],
  data() {
    return {
      files: [],
    }
  },
  computed: {
    inputModifiers() {
      let mods = []

      if (this.modifiers) {
        mods = this.buildMods('input--', this.modifiers)
      }

      if (this.maxlength) {
        mods.push('input--count')
      }

      if (this.error) {
        mods.push('form--error')
      }

      return mods
    },
    labelModifiers() {
      if (!this.label || !this.label.modifiers) return

      let mods = this.buildMods('label--', this.label.modifiers)

      return mods
    },
    curName() {
      if (this.value && this.value.length) {
        return this.value[0].name
      }
      return this.fileName
    },
    displayValue() {
      return this.value && this.value.file
        ? this.value.file[0].name
        : this.value
    },
  },
  mounted() {
    let app = this

    document
      .getElementById(this.id)
      .addEventListener('change', function ($event) {
        app.files = Array.from($event.target.files)

        app.$emit('change', app.files)
      })
  },
  methods: {
    buildMods(base, modifiers) {
      let mods = []

      modifiers.forEach(function (modifier) {
        mods.push(base + modifier)
      })

      return mods
    },
    updateValue($event) {
      this.files = Array.from($event.target.files)
      this.$emit('input', this.files)
    },
  },
}
</script>

<style lang="scss" scoped>
.input-file {
  max-width: 100%;
  position: relative;
}

.form--error {
  .input__elem {
    margin-bottom: 5px;
  }
}

.input__wrap {
  display: flex;
}

input {
  display: none;
  visibility: hidden;
  padding: 0;
  width: 0;
  height: 0;
}

span {
  display: block;
}

.btn {
  padding: 14px 13px;
  flex-grow: 0;
  flex-shrink: 0;
}

.input__elem {
  position: relative;
  width: 100%;
  padding: 13px 12px;
  border: 1px solid #000;
  border-right: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }
}

.input__elem--placeholder {
  color: #ababab;
}
</style>

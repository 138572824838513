<template>
  <div class="ama">
    <div class="content">
      <div class="ama__mobile">
        <div class="ama__icon">
          <svgQa />
        </div>

        <div
          v-if="
            user &&
            user.current_event_participant &&
            $route.params.id !== user.current_event_participant &&
            banner &&
            banner.event_banner_text_global
          "
          class="ama__text"
          v-html="banner.event_banner_text_global"
        />

        <div
          v-if="
            user &&
            user.current_event_participant &&
            banner &&
            banner.event_banner_text &&
            $route.params.id === user.current_event_participant
          "
          class="ama__text"
          v-html="banner.event_banner_text"
        />
      </div>

      <a
        v-if="
          user &&
          user.current_event_participant &&
          $route.params.id !== user.current_event_participant
        "
        :href="'/posts/' + user.current_event_participant"
        class="btn btn--orange ama__button"
      >
        Go There Now
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import svgQa from '~/static/icons/question_answer-24px.svg'

export default {
  components: {
    svgQa,
  },
  data() {
    return {
      user: this.$auth.user,
    }
  },
  computed: {
    ...mapGetters({
      banner: 'events/banner',
    }),
  },
  created() {
    if (this.user && this.user.current_event_participant) {
      this.$store.dispatch('events/loadBanner', {
        id: this.user.current_event_participant,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.ama {
  padding: 10px 0;
  background: #ffd585;
  font-size: 18px;
}

.content {
  @include bp('sm') {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ama__mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;

  @include bp('sm') {
    margin: 0;
  }
}

.ama__icon {
  display: flex;
  width: 78px;
  height: 78px;
  margin: 0 15px 0 0;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  background: #e39600;
  background: rgba(#e39600, 0.7);
  border-radius: 50%;

  svg {
    width: 60px;
    height: 60px;
    fill: #ffffff;
  }

  @include bp('sm') {
    margin: 0 25px 0 0;
  }
}

.ama__text {
  /deep/ p:last-child {
    margin: 0;
  }

  @include bp('sm') {
    margin: 0 20px 0 0;
  }
}

.ama__button {
  flex-grow: 0;
  flex-shrink: 0;
  background: #e39600;
}
</style>

/* eslint-disable */

export default ({ app }) => {
  const { HOTJAR_SITE_ID } = app.context.env
  /*
  ** Only run on client-side (plugin set to ssr:false) and only if env variable is set
  */
  if (!HOTJAR_SITE_ID) return
  /*
  ** Include Hotjar Script
  */
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:HOTJAR_SITE_ID,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

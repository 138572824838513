export default function openPopup(url) {
  var width = Math.max(window.innerWidth * 0.5, 300)
  var left = (window.innerWidth - width) / 2
  var height = Math.max(window.innerHeight * 0.5, 500)
  var top = (window.innerHeight - height) / 2

  return window.open(
    url,
    '',
    'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left
  )
}

import { debounce, snakeCase } from 'lodash-es'

const trackPlugin = (ctx, inject) => {
  const addGeoAndUserDataToPayload = (data) => {
    const location = ctx.store.getters['users/location']
    const user = ctx.$auth.user

    if (user) {
      data.segment.data.role_id = user.role_id
      data.segment.data.role_value = user.role_value
    }

    if (location) {
      if (location.country) data.segment.data.country = location.country
      if (location.countryCode)
        data.segment.data.countryCode = location.countryCode
      if (location.region) data.segment.data.region = location.region
      if (location.regionName)
        data.segment.data.regionName = location.regionName
      if (location.city) data.segment.data.city = location.city
      if (location.zip) data.segment.data.zip = location.zip
      if (location.isp) data.segment.data.isp = location.isp
      if (location.as) data.segment.data.as = location.as
      if (location.org) data.segment.data.org = location.org
    }
  }

  const trackSelect = debounce(function () {
    const selection = document.getSelection()

    if (!selection) return

    const text = selection.toString()

    // Should not send segment call when highlighting an input value
    let isSelectionValidNonInput = true
    if (selection.anchorNode) {
      for (let entry of selection.anchorNode.childNodes.entries()) {
        entry.forEach((el) => {
          if (el.tagName === 'INPUT') {
            isSelectionValidNonInput = false
          }
        })
      }
    }

    if (!isSelectionValidNonInput) return

    if (!text.length) return

    const data = {
      segment: {
        data: {
          text: text,
        },
      },
    }

    // segment
    if (window.analytics) {
      const project = ctx.store.getters['projects/project']

      if (project) {
        data.segment.data.project_id = project.id
        if (project.affiliation)
          data.segment.data.affiliation_id = project.affiliation.id
      }

      addGeoAndUserDataToPayload(data)

      analytics.track('Highlight', data.segment.data)
    }
  }, 500)

  document.addEventListener('selectionchange', trackSelect)

  const track = {
    async login(data) {
      if (window.analytics) {
        analytics.identify(data.user.id, {
          role_id: data.user.role_id,
        })
      }

      if (ctx.app.$bugsnag) ctx.app.$bugsnag.setUser(ctx.$auth.user.id)
    },
    event(data) {
      // Send Segment Event
      if (window.analytics && data.segment) {
        if (!data.segment.data) data.segment.data = {}

        addGeoAndUserDataToPayload(data)

        analytics.track(data.segment.action, data.segment.data)
      }

      // Send Hotjar Event
      // https://help.hotjar.com/hc/en-us/articles/4405109971095
      // To confirm that this works, enable debug logs with ?hjDebug=1 in the URL
      if (window.hj && data.segment) {
        // Hotjar events can only have a name, and it cannot contain spaces.
        // We can take the event name we have for Segment and easily make it HJ friendly.
        // E.g. 'Projects Searched' => 'projects_searched'

        const eventName = snakeCase(data.segment.action)
        hj('event', eventName)
      }
    },
    page() {
      if (window.analytics) {
        const data = {
          segment: {
            data: {},
          },
        }

        addGeoAndUserDataToPayload(data)

        analytics.page(null, data.segment.data)
      }
    },
    logout() {
      if (window.analytics) {
        analytics.reset()
      }

      if (ctx.$bugsnag) ctx.$bugsnag.setUser(null)
    },
  }

  async function externalRequest(app, data, url, cb, headers, method) {
    headers = headers ? headers : { 'Content-Type': 'application/json' }
    method = method ? method : 'post'

    try {
      const response = await app.$axios({
        method: method,
        headers: headers,
        data: data,
        url: url,
      })

      const responseData = response.data

      responseData.success = true

      cb(responseData)
    } catch (e) {
      if (e.response) {
        cb(e.response.data)
      } else {
        cb({ success: false, error: e, authenticated: false })
      }
    }
  }

  ctx.$track = track
  inject('track', track)
}

export default trackPlugin

<template>
  <ProjectEditModalGeneric v-bind="modalProps" data-test="project-delete-modal">
    <form>
      <div>
        <DeleteInput
          id="delete_confirm"
          v-model="deleteConfirm"
          v-validate="{ required: true, regex: /\bDELETE\b/ }"
          name="delete_confirm"
          data-vv-as="delete"
          data-test="project-delete-input"
          placeholder="Type DELETE"
          :error="errors.first('delete_confirm')"
        />
      </div>
    </form>
    <template #footer>
      <div
        v-if="showApiError"
        data-test="api-modal-error"
        class="modal__errors modal__errors--api"
      >
        <InfoSvg />
        <span v-html="errorMessage"></span>
      </div>
      <div class="modal__buttons">
        <div class="btn btn--clear" @click="$modal.hide('projectDelete')">
          Cancel
        </div>
        <button
          class="btn btn--alert"
          type="button"
          data-test="project-delete-confirm"
          @click.prevent="validateBeforeSubmit($event)"
        >
          Delete
        </button>
      </div>
    </template>
  </ProjectEditModalGeneric>
</template>

<script>
import DeleteInput from '~/components/form/Input'

import projectEditModalMixin, {
  MODAL_NAMES,
} from '~/mixins/projectEditModalMixin'
import InfoSvg from '~/static/icons/info-24px.svg'

import getUserErrorMessage from '~/utils/getUserErrorMessage'
import ProjectEditModalGeneric from '~/components/project/modals/ProjectEditModalGeneric'

export default projectEditModalMixin.extend({
  components: {
    DeleteInput,
    ProjectEditModalGeneric,
    InfoSvg,
  },
  inject: ['$validator'],
  data() {
    return {
      id: null,
      deleteConfirm: '',
      cb: null,
      errorMessage: '',
      modalName: MODAL_NAMES.DELETE,
    }
  },
  computed: {
    modalProps() {
      return {
        modalName: this.modalName,
        cb: this.cb,
        id: this.id,
        beforeOpen: this.beforeOpen,
        beforeClose: this.beforeClose,
        title: 'Delete Case Study',
        subtitle:
          'This is permanent. Please confirm by typing "DELETE" in the input below',
      }
    },
  },
  methods: {
    getUserErrorMessage,
    beforeOpen(event) {
      this.id = event.params.id
      this.cb = event.params.cb
    },
    beforeClose(event) {
      this.deleteConfirm = ''
    },
    validateBeforeSubmit(e) {
      e.preventDefault()

      let app = this

      this.$validator.validate('delete_confirm').then((x) => {
        if (x) {
          this.$store.dispatch('affiliations/deleteProject', {
            id: app.id,
            error: function (res) {
              app.apiErrors = res.errors
              app.apiErrorCode = res.statusCode
              app.handleDisplayErrors()
              app.errorMessage = getUserErrorMessage(res)
              app.$emit('setLoading', false)
            },
            cb: async function (res) {
              if (app.cb) app.cb()
              app.$modal.hide(app.modalName)
            },
          })

          this.deleteConfirm = ''
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.modal__errors {
  color: $error;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;

  svg {
    transform: rotate(180deg);
    fill: $error;
    width: 18px;
    margin-right: 3px;
  }
}

.modal__errors--api {
  font-size: 10px;
  text-decoration: none;
  cursor: auto;

  /deep/ a {
    color: $error;
    text-decoration: underline;
  }
}
</style>

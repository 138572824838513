<template>
  <modal
    name="newConversation"
    :clickToClose="false"
    :width="550"
    height="auto"
    @before-open="beforeOpen"
  >
    <form @submit="createConversation">
      <div class="modal__title">New Conversation</div>

      <div class="modal__content">
        <p>
          Start a conversation here. You can select more than one person, but no
          more than 10.
        </p>

        <TagGroup
          v-model="contacts"
          v-validate="'required|max:10'"
          className="multiselect--tags-green"
          name="contacts"
          :max="10"
          :error="errors.first('contacts')"
          data-vv-as="contacts"
          :options="contactables"
          placeholder="Select Users"
          :customLabel="customLabel"
          :label="{
            text: 'Find Users',
            modifiers: ['header'],
          }"
          :cb="updateContacts"
        />
      </div>

      <div class="modal__buttons">
        <button
          type="submit"
          class="btn"
          :class="{ 'btn--disabled': !contacts.length }"
        >
          OK
        </button>

        <div class="btn" @click="$modal.hide('newConversation')">Cancel</div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

import TagGroup from '~/components/form/TagGroup'

export default {
  components: {
    TagGroup,
  },
  inject: ['$validator'],
  data() {
    return {
      user: this.$auth.user,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      contactables: 'chats/contactables',
      conversation: 'chats/conversation',
    }),
  },
  methods: {
    async beforeOpen(event) {
      // this.userId = event.params.userId
      // this.affiliationId = event.params.affiliationId

      this.contacts = []

      if (this.user && !this.contactables.length) {
        await this.$store.dispatch('chats/loadContactables', {
          id: this.user.id,
        })
      }
    },
    customLabel({ name, job_title }) {
      return job_title ? `${name} – ${job_title}` : `${name}`
    },
    updateContacts(contacts) {
      this.contacts = contacts.tags
    },
    createConversation($event) {
      $event.preventDefault()

      let app = this

      this.$store.dispatch('chats/createConversation', {
        data: {
          chat_type: 'inbox',
          ownerable_id: this.user.id,
          ownerable_type: 'users',
          users: this.contacts,
        },
        cb: function () {
          app.$root.$emit('newConversation')

          app.$modal.hide('newConversation')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px;
}

.v--modal-overlay /deep/ .v--modal-box {
  overflow: visible;
}

/deep/ .multiselect__content {
  max-width: 100%;
}

/deep/ .multiselect__option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

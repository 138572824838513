import https from 'https'

function getClientUrl(app) {
  const base = process.env.BASE_PATH
  const path = app?.context?.route?.fullPath || ''
  const url = base + path

  return url.substr(0, 255)
}

export default function ({ $axios, app, $cookies }) {
  const { NODE_ENV } = app.context.env
  if (NODE_ENV !== 'production') {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
  }

  $axios.defaults.headers.common['X-Client-Url'] = getClientUrl(app)

  $axios.onRequest((config) => {
    const authHeader = config.headers['Authorization']
    // Only do this if we have a token & are making a call to our API (path doesn't start with http)
    if (authHeader && !authHeader.includes('Bearer')) {
      if (config.url.startsWith('http')) {
        // Prevent the auth token from getting included when making non-Atlas API calls
        // Ex: fetching tweets from JSON, calling external API, etc.
        if (config.headers.common['Authorization'])
          delete config.headers.common['Authorization']

        config.headers['Authorization'] = null
      } else {
        config.headers['Authorization'] = `Bearer ${authHeader}`
      }
    }
  })
}

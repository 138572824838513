<template>
  <span class="delete-item" @click="deleteItem($event)"><SvgDelete /></span>
</template>

<script>
import SvgDelete from '~/static/icons/delete_outline_black_24dp.svg'

export default {
  components: {
    SvgDelete,
  },
  props: ['array', 'cb', 'index', 'tagName', 'cancelRemove'],
  methods: {
    deleteItem($event) {
      // console.log("DELETE ITEM LINK")
      let app = this
      let elem = $event.target
      // let listItem = null
      // let removeItem = !this.remove ? true : false
      // Needs check for li
      // console.log(elem.nodeName)
      // console.log(elem)
      while (elem.nodeName != 'LI') {
        elem = elem.parentElement
      }

      // console.log(elem)
      // console.log(elem.nodeName)

      // elem.parentElement.removeChild(elem)

      // console.log(this.array)
      // console.log(this.index)

      if (!this.cancelRemove) elem.remove()

      setTimeout(function () {
        app.cb(app.array, app.index, app.tagName)
      }, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-item {
  display: inline-block;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: 0;

  svg {
    pointer-events: none;
    fill: $black;
    width: 24px;
  }
}
</style>

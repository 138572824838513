<template>
  <div>
    <Unsupported />

    <PageHeader modifier="fullwidth" />

    <Ama v-if="user && user.current_event_participant" />

    <div class="fullwidth">
      <Sidebar />

      <div class="fullwidth__content">
        <nuxt />
      </div>
    </div>

    <PageFooter modifier="fullwidth" />

    <ProjectAlert />
    <ProjectDelete />
    <DeleteConfirm />
    <ProjectPublish />
    <ProjectImage />
    <PageSeo />
    <Cohort />
    <ImageUpload />
    <Participants />

    <UserApprove />

    <NewConversation />
    <GroupInfo />

    <ReportUpload />

    <div v-if="impersonating && user" class="impersonating">
      <div class="impersonating__close" @click="removeImpersonating">
        <svgClose />
      </div>

      You are logged in as <b>{{ user.name }}</b
      >, a {{ user.role_value }} user - please log in again to return to admin.
      Use caution, all activity is recorded as this user.
    </div>
    <OldTabWarning />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PageHeader from '~/components/Header.vue'
import PageFooter from '~/components/Footer.vue'
import Sidebar from '~/components/Sidebar.vue'
import Unsupported from '~/components/banners/Unsupported.vue'
import Ama from '~/components/banners/Ama.vue'

import ProjectAlert from '~/components/modals/ProjectAlert'
import ProjectDelete from '~/components/modals/ProjectDelete'
import DeleteConfirm from '~/components/modals/DeleteConfirm'
import ProjectPublish from '~/components/project/modals/ProjectPublishedModal'
import ProjectImage from '~/components/modals/ProjectImage'
import ImageUpload from '~/components/modals/ImageUpload'
import UserApprove from '~/components/modals/UserApprove'
import NewConversation from '~/components/modals/NewConversation'
import GroupInfo from '~/components/modals/GroupInfo'
import ReportUpload from '~/components/modals/ReportUpload'
import PageSeo from '~/components/modals/PageSeo'
import Cohort from '~/components/modals/Cohort'
import Participants from '~/components/modals/Participants'
import OldTabWarning from '~/components/modals/OldTabWarning'

import svgClose from '~/static/icons/round-close-24px.svg'

export default {
  components: {
    PageHeader,
    PageFooter,
    Sidebar,
    Unsupported,
    Ama,
    ProjectAlert,
    ProjectDelete,
    DeleteConfirm,
    ProjectPublish,
    ProjectImage,
    ImageUpload,
    UserApprove,
    NewConversation,
    GroupInfo,
    ReportUpload,
    PageSeo,
    Cohort,
    Participants,
    svgClose,
    OldTabWarning,
  },
  computed: {
    ...mapGetters({
      impersonating: 'authHelpers/impersonating',
    }),
    user() {
      return this.$auth.user
    },
  },
  methods: {
    removeImpersonating() {
      this.$store.dispatch('authHelpers/setImpersonating', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.fullwidth {
  position: relative;
  display: flex;
  border-bottom: 1px solid #50505d;
}

.banner {
  padding: 15px 40px 15px 15px;
  background: #e0f5ff;
  text-align: center;
}

.fullwidth__sidebar {
  display: flex;
  min-height: 100%;
  background: #50505d;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1;

  @include bp('sm') {
    position: relative;
  }
}

.fullwidth__content {
  position: relative;
  width: 100%;
  padding: 60px 0 0 0;
  background: #ffffff;
  transition: margin;

  &.fullwidth__content--active {
    margin-left: 5px;
  }

  @include bp('sm') {
    padding: 0;
  }

  @include bp('md') {
    display: flex;
  }
}

.fullwidth__content-header {
  position: relative;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  background: #fbfbfb;
  color: #50505d;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  z-index: 1;

  @include bp('md') {
    display: block;
    background: #50505d;
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    flex-grow: 0;
    flex-shrink: 0;
    transition: width;
  }
}

.fullwidth__toggle-info {
  display: flex;
  align-items: center;
}

.fullwidth__toggle-image {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 1px solid #ababab;
  flex-grow: 0;
  flex-shrink: 0;

  @include bp('md') {
    display: none;
  }
}

.fullwidth__toggle-text {
  @include bp('md') {
    display: none;
  }
}

.fullwidth__toggle-button {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > * {
    pointer-events: none;
  }

  @include bp('md') {
    position: absolute;
    width: 45px;
    height: 45px;
    top: 18px;
    right: -22px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    // transition: left;

    // &.fullwidth__toggle-button--active {
    //   left: -27px;
    // }
  }
}

.fullwidth__toggle-line {
  width: 18px;
  height: 2px;
  background: #50505d;
  border-radius: 2px;

  @include bp('md') {
    margin: 0 auto;
  }

  + .fullwidth__toggle-line {
    margin-top: 3px;
  }
}

#footer.footer--fullwidth {
  position: relative;
  padding-top: 30px;
  background: #ffffff;
}

.impersonating {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 40px 15px 15px;
  background: #000000;
  color: #ffffff;
  text-align: center;
  z-index: 100;
}

.impersonating__close {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -12px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }
}
</style>
